import React from 'react';
import { Link } from 'react-router-dom';
import nologo from '../../assets/img/logo/nologo.png';
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  FormFeedback,
} from 'reactstrap';
import Page from '../../../src/components/Page';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment'
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import axios from 'axios';
import { BootstrapTable, TableHeaderColumn, InsertButton } from 'react-bootstrap-table';
import AppConstants from '../../AppConstants';
import {ReactTitle} from 'react-meta-tags';


class ViewvendorDetails extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      _id:this.props.auth.user._id,
      "vendor_id": this.props.match.params.id,
      "vendor_company_name" : '',
      "vendor_company_email" : '',
      "vendor_company_password": '',
      "vendor_company_type": '',
      "vendor_otp" : '',
      "vendor_otp_status": '',
      "vendor_company_industrey": '',
      "vendor_company_address": '',
      "vendor_company_phoneno": '',
      "vendor_company_website": '',
      "vendor_primary_contact_firstname": '',
      "vendor_primary_contact_lastname": '',
      "vendor_primary_contact_middlename": '',
      "vendor_primary_contact_title": '',
      "vendor_primary_contact_phoneno": '',
      "vendor_primary_email": '',
      "vendor_primary_cellno": '',
      "vendor_primary_officeno": '',
      "vendor_primary_department": '',
      "certificationsandmembership": '',
      "naic_codes": [],
      "descriptionofproductsandservices": '',
      "companyleadership": '',
      "department_max_revenue": '',
      "employees": '',
      "locationsOfOffices": [],
      "zipcode":'',
      "companyLogo": '',
      "uploadVideo": '',
      "instagramLink": '',
      "facebookLink": '',
      "linkedInLink": '',
      "youTubeLik": '',
      "twitterLink": '',    
      "businessStartedDate": '',
      "accolades": [],
      "media": [],
      "created_by": this.props.auth.user._id,
      "revanuerange":'',
      //"country": [],
      "state": '',
      "city": '',     
      "companyname":[],
      "industryname":[],
      "nologo":'',
      "vendorfeedbacksgivenbycustomers":[],
      "vendor_company_address2":'',
      "certifications":[],
      "membership":[],
      "leadershipemail":'',
      "vendor_business_model":"",
      "certification_documents":[],
      "muslim_owned_business":'',
      "memphis_chamber_mmember":'',
      "newcertifications":[],
      "subindustries":[],
      "subindustriesdata":[],
      "vendor_company_address1":'',
     "years_in_business":'',
      "no_of_jobs_currently_ongoing": '',
      "order_size_capable": '',
      "size_of_largest_order": '',
      "avg_fulfillment_time": '',
      "exp_in_category": '',
      "mwbe_certification_category": '',
      "desc_for_wrk_exp": '',
      "desc_for_wrk_exp_in_category": '',
      "references": '',
      "vendor_product_needs":""
      
      
    }    
    this.handleIndustryChange = this.handleIndustryChange.bind(this);
  }

  handleIndustryChange(vendor_company_industrey) {
    //console.log(event.target.value);
    if(vendor_company_industrey !=''){
      fetch(AppConstants.API+'/industries/getsubindustries/' + vendor_company_industrey).then(response => response.json())
      .then(data => {
       // console.log(" sub industries ", data.subindustriesdata);
        if(data.Status == "Success")
        {
          this.setState({ 
            subindustriesdata: data.subindustriesdata,
            vendor_company_industrey: vendor_company_industrey,
          });
        }
        else{
          this.setState({
            vendor_company_industrey: vendor_company_industrey
          });
        }
      });
    }
    else
    {
      this.setState({
        vendor_company_industrey: vendor_company_industrey        
      });
    }
}
  getImage(cell, row){
    if(row.company_logo)
    return <div><img style={{ height: 100 ,width: 150 }} src={row.company_logo} alt={row.company_logo} className="img-responsive"/></div>;
    else
    return <div><img style={{ height: 100 ,width: 150 }} src={nologo} alt={row.company_logo} className="img-responsive"/></div>;
    }

  componentDidMount()
  {
    var element = document.getElementById("navItem-Vendors-2");
    element.classList.add("active");

    //Vedor Details  
      fetch(AppConstants.API+'/vendors/getcompanysidevendordetails/'+this.props.match.params.id).then(response => response.json())
      .then(data => {

       console.log("dataaaaaaaaaaaaaaaaaaaaa",data);
          if(data.Status == "Success")
          {
              let today;
              //let today2;
              let certdata;
              let website;
              if(data.getvendorsdata.businessStartedDate == null && data.getvendorsdata.businessStartedDate == undefined){

                 today = 'N/A';
              }else
              {
                //const today = moment(data.getvendorsdata.businessStartedDate).format('MM/DD/YYYY');
                 //today = data.getvendorsdata.businessStartedDate;
                 today = data.getvendorsdata.businessStartedDate;
                 var today1= new Date(today);             
                 var today2 =  ("0" + (today1.getMonth() + 1)).slice(-2) + "-" + (today1.getDate() < 10 ? '0' : '')+ today1.getDate() + "-" +  today1.getFullYear();
              }

              if(data.getvendorsdata.certifications == undefined){
                certdata = 0;
              }
              else
              {
                certdata = data.getvendorsdata.certifications;
              }

              if(data.getvendorsdata.vendor_company_website != 'undefined' && data.getvendorsdata.vendor_company_website != 'null'){
                website = data.getvendorsdata.vendor_company_website;
              }


              var subcat = [];
                if(data.getvendorsdata.subindustries && data.getvendorsdata.subindustries.length >0){
                    subcat = data.getvendorsdata.subindustries;
                }
              
           
            this.setState({ 
                id:this.props.auth.user._id,
                vendor_id: data.getvendorsdata._id,
                vendor_company_name : data.getvendorsdata.vendor_company_name,
                vendor_company_email : data.getvendorsdata.vendor_company_email,
                companyLogo : data.getvendorsdata.companyLogo || nologo,
                vendor_company_address : data.getvendorsdata.vendor_company_address,
                vendor_company_address1 : data.getvendorsdata.vendor_company_address2,
                vendor_company_website : website,
                vendor_company_phoneno : data.getvendorsdata.vendor_company_phoneno || 'N/A',
                vendor_primary_contact_firstname : data.getvendorsdata.vendor_primary_contact_firstname || 'N/A',
                vendor_primary_contact_lastname : data.getvendorsdata.vendor_primary_contact_lastname || 'N/A',
                vendor_primary_contact_middlename: data.getvendorsdata.vendor_primary_contact_middlename || 'N/A',
                vendor_primary_contact_title : data.getvendorsdata.vendor_primary_contact_title || 'N/A',
                vendor_primary_contact_phoneno : data.getvendorsdata.vendor_primary_contact_phoneno || 'N/A',
                vendor_primary_email : data.getvendorsdata.vendor_primary_email || 'N/A',
                vendor_primary_cellno : data.getvendorsdata.vendor_primary_cellno || 'N/A',
                vendor_primary_officeno : data.getvendorsdata.vendor_primary_officeno || 'N/A',
                zipcode : data.getvendorsdata.zipcode || 'N/A',
                uploadVideo : data.getvendorsdata.uploadVideo,
                country : data.getvendorsdata.country || 'N/A',
                state : data.getvendorsdata.state || 'N/A',
                city : data.getvendorsdata.city || 'N/A',
                instagramLink : data.getvendorsdata.instagramLink,
                facebookLink : data.getvendorsdata.facebookLink,
                linkedInLink : data.getvendorsdata.linkedInLink,
                youTubeLik : data.getvendorsdata.youTubeLik,
                twitterLink : data.getvendorsdata.twitterLink,
                companyname : data.getvendorsdata.company,
                industryname : data.getvendorsdata.industry,
                vendor_primary_department: data.getvendorsdata.vendor_primary_department || 'N/A',
                certificationsandmembership : data.getvendorsdata.certificationsandmembership || 'N/A',
                descriptionofproductsandservices : data.getvendorsdata.descriptionofproductsandservices || 'N/A',
                companyleadership : data.getvendorsdata.companyleadership || 'N/A',
                department_max_revenue : data.getvendorsdata.department_max_revenue || 'N/A',
                employees : data.getvendorsdata.employees || 'N/A',
                 naic_codes :  data.getvendorsdata.naiccodesdes,
                businessStartedDate : today2,
                locationsOfOffices : data.getvendorsdata.locationsOfOffices || 'N/A',
                media : data.getvendorsdata.media,
                accolades : data.getvendorsdata.accolades,
                vendor_primary_department: data.getvendorsdata.vendor_primary_department || 'N/A',
                leadershiptitle: data.getvendorsdata.leadershiptitle || 'N/A',
                vendor_primary_contact_phoneno: data.getvendorsdata.vendor_primary_contact_phoneno || 'N/A',
                leadershipemail: data.getvendorsdata.leadershipemail || 'N/A',
                certifications : certdata,
                membership: data.getvendorsdata.membership,
                vendor_business_model:data.getvendorsdata.vendor_business_model || 'N/A',
                certification_documents:data.getvendorsdata.certification_documents,
                muslim_owned_business:data.getvendorsdata.muslim_owned_business || 'N/A',
                memphis_chamber_mmember:data.getvendorsdata.memphis_chamber_mmember || 'N/A',
                newcertifications:data.getvendorsdata.newcertifications,
                subindustries:subcat,
                vendor_company_industrey:data.getvendorsdata.vendor_company_industrey || 'N/A',
                years_in_business: data.getvendorsdata.years_in_business || 'N/A',
                no_of_jobs_currently_ongoing: data.getvendorsdata.no_of_jobs_currently_ongoing || 'N/A',
                order_size_capable: data.getvendorsdata.order_size_capable || 'N/A',
                size_of_largest_order: data.getvendorsdata.size_of_largest_order || 'N/A',
                avg_fulfillment_time: data.getvendorsdata.avg_fulfillment_time || 'N/A',
                exp_in_category: data.getvendorsdata.exp_in_category || 'N/A',
                mwbe_certification_category: data.getvendorsdata.mwbe_certification_category || 'N/A',
                desc_for_wrk_exp: data.getvendorsdata.desc_for_wrk_exp || 'N/A',
                desc_for_wrk_exp_in_category: data.getvendorsdata.desc_for_wrk_exp_in_category || 'N/A',
                references: data.getvendorsdata.references || 'N/A',
                vendor_product_needs: data.getvendorsdata.vendor_product_needs || 'N/A',
            })
            this.handleIndustryChange(this.state.vendor_company_industrey);
          }
          else
        {
            this.setState({ getvendorsdata: "" });
        }
       });

      //feedbacks data
      fetch(AppConstants.API+'/cutomers/getallvendorfeedbacksgivenbycustomer/'+this.props.match.params.id).then(response => response.json())
      .then(data => {
     //console.log(data);
      if(data.Status == "Success")
      {
        this.setState({ vendorfeedbacksgivenbycustomers: data.vendorfeedbacksgivenbycustomers });
      }
      else
      {
        this.setState({ vendorfeedbacksgivenbycustomers: []});
      }    
    });

  }
  render() {
    var k = 1;
  const {user} = this.props.auth;
  const options = {
    exportCSVText: 'Export CSV',    
    insertText: "New",    
    deleteText: 'Delete',    
    saveText: 'Save',    
    closeText: 'Close',
    insertBtn: this.createCustomInsertButton,
    page: 1,  // which page you want to show as default
    sizePerPageList: [ {
      text: '5', value: 5
    }, {
      text: '10', value: 10
    }, {
      text: '20', value: 20
    },
    {
      text: '50', value: 50
    }
  ], // you can change the dropdown list for size per page
    sizePerPage: 10,  // which size per page you want to locate as default
    pageStartIndex: 1, // where to start counting the pages
    paginationSize: 3,  // the pagination bar size.
   // prePage: 'Prev', // Previous page button text
    //nextPage: 'Next', // Next page button text
    //firstPage: 'First', // First page button text
    //lastPage: 'Last', // Last page button text
    paginationShowsTotal: this.state.vendorfeedbacksgivenbycustomers.length,  // Accept bool or function
    paginationPosition: 'bottom',  // default is bottom, top and both is all available
    // hideSizePerPage: true > You can hide the dropdown for sizePerPage
    // alwaysShowAllBtns: true // Always show next and previous button
    // withFirstAndLast: false > Hide the going to First and Last page button
    insertModal: this.createCustomModal    
  };
  let businessmodel;
    if(this.state.vendor_business_model == 'Both'){
       businessmodel = 'B2B & B2C'
    }
    else if(this.state.vendor_business_model == 'B2B')
    {
        businessmodel = 'B2B'
    }
    else if(this.state.vendor_business_model == 'B2C')
    {
        businessmodel = 'B2C'
    }
    else
    {
        businessmodel = 'N/A'
    }

  return (
    <Page title="Vendor Details">
    <ReactTitle title = "View Vendor"/>
    <Link to={'/dashboard'}>
    Home
    </Link>
    &nbsp; | &nbsp;&nbsp;
    <Link to={AppConstants.STAGADMIN+'/vendors'}>
    Vendors
    </Link>
    <div style={{float:'right'}}>
  <Link to={AppConstants.STAGADMIN+"/vendors"} class="btn btn-primary redbtn" title="Back to Vendors ">Back to Vendors</Link>
  
  </div>
  {/*<Link to=AppConstants.STAGADMIN+'/vendors'><Button style={{float:"right"}}>cancel</Button></Link>*/}
      <Row   className="form-block">
        <Col xl={6} lg={6} md={6}>
            <Card>
            <CardHeader>Basic info</CardHeader>
                <CardBody className="viewrfp">                    
                                       
                    <p>
                        {this.state.companyLogo &&
                            <Label className="basic-info">
                                <p>
                                {this.state.companyLogo &&
                                    <div  style={{'text-align':'center'}}>
                                        <img src={this.state.companyLogo} alt={this.state.vendor_company_name} style={{width:100}} className="img-responsive company-logo"/>
                                    </div>
                                }
                                </p>
                                <p>
                                    <Label style={{width:140}}>Company Name</Label>
                                    <Label> : &nbsp;&nbsp; </Label>
                                        <span> 
                                            {this.state.vendor_company_name ? this.state.vendor_company_name : 'N/A'}
                                        </span>
                                </p>
                                <p>
                                    <Label style={{width:140}}>Company Email</Label>
                                    <Label> : &nbsp;&nbsp; </Label>
                                        <span> 
                                            {this.state.vendor_company_email ? this.state.vendor_company_email : 'N/A'}
                                        </span>
                                </p>
                                <p>
                                    <Label style={{width:140}}>Company Type</Label>
                                    <Label> : &nbsp;&nbsp; </Label>
                                        <span> 
                                            {this.state.companyname ? this.state.companyname : 'N/A'}
                                        </span>
                                </p>
                                <p>
                                    <Label style={{width:140}}>Industry Type</Label>
                                    <Label> : &nbsp;&nbsp; </Label>
                                        <span> 
                                            {this.state.industryname ? this.state.industryname : 'N/A'}
                                        </span>
                                </p>
                                <p>
                                    <Label style={{width:140}}>Address1</Label>
                                    <Label> : &nbsp;&nbsp; </Label>
                                        <span> 
                                            {this.state.vendor_company_address ? this.state.vendor_company_address : 'N/A'}
                                        </span>
                                </p>
                                <p>
                                    <Label style={{width:140}}>Head quarters Address</Label>
                                    <Label> : &nbsp;&nbsp; </Label>
                                        <span> 
                                            {this.state.vendor_company_address1 ? this.state.vendor_company_address1 : 'N/A'}
                                        </span>
                                </p>
                                <p>
                                    <Label style={{width:140}}>Phone No</Label>
                                    <Label> : &nbsp;&nbsp; </Label>
                                        <span> 
                                            {this.state.vendor_company_phoneno}
                                        </span>
                                </p>   
                                <p>
                                    <Label style={{width:140}}>City</Label>
                                    <Label> : &nbsp;&nbsp; </Label>
                                        <span> 
                                            {this.state.city}
                                        </span>
                                </p>                                                           
                                <p>
                                    <Label style={{width:140}}>State</Label>
                                    <Label> : &nbsp;&nbsp; </Label>
                                        <span> 
                                            {this.state.state}
                                        </span>
                                </p>
                                
                                <p>
                                    <Label style={{width:140}}>Zip Code</Label>
                                    <Label> : &nbsp;&nbsp; </Label>
                                    <span> {this.state.zipcode}</span>
                                </p>
                                {/* <p>
                                <Label style={{width:140}}>
                                    Business Model</Label>
                                    <Label> :&nbsp;&nbsp;</Label>
                                    <span>
                                    {businessmodel}</span>
                                </p>                                */}
                                <p>
                                    <Label style={{width:140}}>Business Start Date</Label>
                                    <Label> : &nbsp;&nbsp; </Label>
                                        <span> 
                                            {this.state.businessStartedDate}
                                        </span>
                                </p>
                                {/*<p>
                                    <Label style={{width:140}}>Website</Label>
                                    <Label> : &nbsp;&nbsp; </Label>
                                        <span> 
                                            {this.state.vendor_company_website}
                                        </span>
                                </p>*/}  
                                <p>
                                    <Label style={{width:140}}>Website</Label>
                                        <Label> : &nbsp;&nbsp; </Label>                                         
                                        {(this.state.vendor_company_website) ? (<span><a href={this.state.vendor_company_website} target="_blank">{this.state.vendor_company_website}</a></span>) : (<span>N/A</span>)}
                                </p>
                                <p>
                                    <Label style={{width:140}}>years In Business</Label>
                                    <Label> : &nbsp;&nbsp; </Label>
                                    <span> {this.state.years_in_business}</span>
                                </p>
                                <p>
                                    <Label style={{width:140}}>Product Needs</Label>
                                    <Label> : &nbsp;&nbsp; </Label>
                                    <span> {this.state.vendor_product_needs}</span>
                                </p>
                                <p>
                                    <Label style={{width:140}}>No of jobs currently ongoing</Label>
                                    <Label> : &nbsp;&nbsp; </Label>
                                    <span> {this.state.no_of_jobs_currently_ongoing}</span>
                                </p>
                                <p>
                                    <Label style={{width:140}}>Max job/ order size capable</Label>
                                    <Label> : &nbsp;&nbsp; </Label>
                                    <span> {this.state.order_size_capable}</span>
                                </p>
                                <p>
                                    <Label style={{width:140}}>Size of largest order</Label>
                                    <Label> : &nbsp;&nbsp; </Label>
                                    <span> {this.state.size_of_largest_order}</span>
                                </p>
                                <p>
                                    <Label style={{width:140}}>Average fulfillment Time</Label>
                                    <Label> : &nbsp;&nbsp; </Label>
                                    <span> {this.state.avg_fulfillment_time}</span>
                                </p>
                                <p>
                                    <Label style={{width:140}}>Experience in category</Label>
                                    <Label> : &nbsp;&nbsp; </Label>
                                    <span> {this.state.exp_in_category}</span>
                                </p>
                                <p>
                                    <Label style={{width:140}}>MWBE certification category</Label>
                                    <Label> : &nbsp;&nbsp; </Label>
                                    <span> {this.state.mwbe_certification_category}</span>
                                </p>
                                <p>
                                    <Label style={{width:140}}>References</Label>
                                    <Label> : &nbsp;&nbsp; </Label>
                                    <span> {this.state.references}</span>
                                </p>
                                <p>
                                    <Label style={{width:140}}>Have you ever been found deficient in work experience or schedule completion</Label>
                                    <Label> : &nbsp;&nbsp; </Label>
                                    <span> {this.state.desc_for_wrk_exp}</span>
                                </p>
                                <p>
                                    <Label style={{width:140}}>Do you have stayed experience in category, would expand for number of jobs completed in each category</Label>
                                    <Label> : &nbsp;&nbsp; </Label>
                                    <span> {this.state.desc_for_wrk_exp_in_category}</span>
                                </p>
                                <p>
                        <Label style={{width:140}}>Description</Label>
                        <Label> : &nbsp;&nbsp; </Label>
                        <span> {this.state.descriptionofproductsandservices}</span>
                    </p>
                    <p>
                        <Label style={{width:140}}>No.of Employees</Label>
                        <Label> : &nbsp;&nbsp; </Label>
                        <span> {this.state.employees}</span>
                    </p>                  
                    <p>
                        <Label style={{width:140}}>Revenue Range</Label>
                        <Label> : &nbsp;&nbsp; </Label>
                        <span> {this.state.department_max_revenue}</span>
                    </p>
                                {/* <p>
                                <Label style={{width:140}}>
                                Are you a Minority Owned Business?</Label>
                                    <Label> :&nbsp;&nbsp;</Label>
                                    <span>
                                    {this.state.muslim_owned_business}</span>
                                </p>  */}
                                {/* <p>
                                <Label style={{width:140}}>
                                Are you a Greater Memphis Chamber Member?</Label>
                                    <Label> :&nbsp;&nbsp;</Label>
                                    <span>
                                    {this.state.memphis_chamber_mmember}</span>
                                </p>   */}
                            </Label>
                        }
                    </p>                     
                </CardBody>
            </Card>
            <p></p>
            <Card>
            <CardHeader>Contact Info</CardHeader>
                
                <CardBody>
                    <p>
                        <Label style={{width:140}}>First Name</Label>
                        <Label> : &nbsp;&nbsp; </Label>
                        <span> {this.state.vendor_primary_contact_firstname}</span>
                    </p>
                    <p>
                        <Label style={{width:140}}>Last Name</Label>
                        <Label> : &nbsp;&nbsp; </Label>
                        <span> {this.state.vendor_primary_contact_lastname}</span>
                    </p>
                    <p>
                        <Label style={{width:140}}>Middle Name</Label>
                        <Label> : &nbsp;&nbsp; </Label>
                        <span> {this.state.vendor_primary_contact_middlename}</span>
                    </p>                  
                    <p>
                        <Label style={{width:140}}>Email</Label>
                        <Label> : &nbsp;&nbsp; </Label>
                        <span> {this.state.vendor_primary_email}</span>
                    </p>
                    <p>
                        <Label style={{width:140}}>Department</Label>
                        <Label> : &nbsp;&nbsp; </Label>
                        <span> {this.state.vendor_primary_department}</span>
                    </p>                   
                </CardBody>
            </Card>
            <p></p>
            {/* <Card>
            <CardHeader>MEMBERSHIP</CardHeader>
                
                <CardBody>
                    <p>
                        <Label style={{width:140}}>Membership</Label>
                        <Label> : &nbsp;&nbsp; </Label>
                        <span> {this.state.certificationsandmembership}</span>
                    </p>
                   
                   
                                    
                </CardBody>
            </Card> */}
            <p></p>
           
        </Col>
        <Col xl={6} lg={6} md={6}>
            {/* <Card>
            <CardHeader>Video</CardHeader>
            
                <CardBody>
                    <p>                                             
                        {(this.state.uploadVideo) ? (<div><embed src={this.state.uploadVideo}/><br /><br /></div>) : (<div>NO Video</div>)}
                    </p>
                </CardBody>
            </Card> */}
            {/* <p></p> */}
            <Card>
            <CardHeader>Social Links</CardHeader>
            
                <CardBody className="viewrfp">
                    <p>
                    <Label style={{width:140}}>Instagram Link</Label>
                        <Label> : &nbsp;&nbsp; </Label>                                         
                        {(this.state.instagramLink && this.state.instagramLink !='undefined') ? (<span><a href={this.state.instagramLink} target="_blank">{this.state.instagramLink}</a></span>) : (<span>N/A</span>)}
                    </p>
                    <p>
                    <Label style={{width:140}}>Facebook Link</Label>
                        <Label> : &nbsp;&nbsp; </Label>                                         
                        {(this.state.facebookLink && this.state.facebookLink !='undefined') ? (<span><a href={this.state.facebookLink} target="_blank">{this.state.facebookLink}</a></span>) : (<span>N/A</span>)}
                    </p>
                    <p>
                    <Label style={{width:140}}>LinkedIn Link</Label>
                        <Label> : &nbsp;&nbsp; </Label>                                         
                        {(this.state.linkedInLink && this.state.linkedInLink !='undefined') ? (<span><a href={this.state.linkedInLink} target="_blank">{this.state.linkedInLink}</a></span>) : (<span>N/A</span>)}
                    </p>
                    <p>
                    <Label style={{width:140}}>YouTube Link</Label>
                        <Label> : &nbsp;&nbsp; </Label>                                         
                        {(this.state.youTubeLik && this.state.youTubeLik !='undefined') ? (<span><a href={this.state.youTubeLik} target="_blank">{this.state.youTubeLik}</a></span>) : (<span>N/A</span>)}
                    </p>
                    <p>
                    <Label style={{width:140}}>Twitter Link</Label>
                        <Label> : &nbsp;&nbsp; </Label>                                         
                        {(this.state.twitterLink && this.state.twitterLink !='undefined') ? (<span><a href={this.state.twitterLink} target="_blank">{this.state.twitterLink}</a></span>) : (<span>N/A</span>)}
                    </p>
                </CardBody>
            </Card>
            <p></p>
            <Card>
            <CardHeader>NAICS Codes</CardHeader>
                
                <CardBody>
                   {/* <table style={{border:1}}>
                        <tr><th style={{width:5}}>NAIC</th><th>NIGP</th><th >Description</th></tr>
                        {
                            this.state.naic_codes.map((fblist,index)=>{
                                return <tr>                                
                                <td><span>{fblist.NAICS && <span>{fblist.NAICS}</span>}</span></td>
                                <td><span>{fblist.NIGP_CODE && <span>{fblist.NIGP_CODE}</span>}</span></td>
                                <td><span>{fblist.NAICS_Description && <span>{fblist.NAICS_Description}</span>}</span></td>
                                </tr>   
                            })
                        }
                    </table>*/}
                     
                       <div className="nic-codes"> {/*{this.state.naic_codes}*/}
                       <ul style={{listStyle:'none', padding:'0'}}>
                        {this.state.naic_codes !=undefined && this.state.naic_codes.map(recipe => {
                            if(recipe != null)
                            {
                                return (
                                    <li>{recipe}<span>,</span></li>
                                   
                                    )
                            }
                            else
                            {
                                return (
                                    <span>No NAICS Codes</span>
                                )
                            }
                                
                                })
                            } 
                          {this.state.naic_codes !=undefined && this.state.naic_codes.length == 0 && <span>No NAICS Codes</span>} 
                        </ul>
                       </div>
                       
                   
                </CardBody>
            </Card>
            <p></p>
            <Card>
            <CardHeader>Media Links</CardHeader>
            <CardBody>
            
                       <div className="nic-codes">
                <ul style={{listStyle:'none', padding:'0'}}>
                    {
                       this.state.media != undefined &&  this.state.media.map((test,index)=>{
                            return <li>                            
                            <a href={test} target="_blank">{test}</a>
                            </li> 
                        })
                    }
                    {this.state.media != undefined && this.state.media.length == 0 && <span>No Media Links</span>}               
                </ul>
                </div>
                </CardBody>
            </Card>
            <p></p>
            <Card>
            <CardHeader>Accolades</CardHeader>
            <CardBody>
            <div className="nic-codes">
                <ul style={{listStyle:'none', padding:'0'}}>
                    {
                       this.state.accolades != undefined &&  this.state.accolades.map((accolade,index)=>{
                            return <li>                            
                            {accolade}
                            </li> 
                        })
                    }
                    {this.state.accolades != undefined && this.state.accolades.length == 0 && <span>No Accolades</span>}                 
                </ul>
                </div>
                </CardBody>
            </Card>
            <p></p>
            <Card>
            <CardHeader>Company Leadership</CardHeader>
            
                <CardBody>
                    <p>
                    <Label style={{width:140}}>Name</Label>
                        <Label> : &nbsp;&nbsp; </Label>                                         
                        {this.state.companyleadership}
                    </p>
                    <p>
                    <Label style={{width:140}}>Title</Label>
                        <Label> : &nbsp;&nbsp; </Label>                                         
                        {this.state.leadershiptitle}
                    </p>
                    <p>
                    <Label style={{width:140}}>Phone</Label>
                        <Label> : &nbsp;&nbsp; </Label>                                         
                        {this.state.vendor_primary_contact_phoneno}
                    </p>
                    <p>
                    <Label style={{width:140}}>Email</Label>
                        <Label> : &nbsp;&nbsp; </Label>                                         
                        {this.state.leadershipemail}
                    </p>                   
                </CardBody>
            </Card>
            <p></p>
            {/* <Card>
            <CardHeader>Memberships</CardHeader>
            <CardBody>
            <div className="nic-codes">
                <ul style={{listStyle:'none', padding:'0'}}>
                    {
                        this.state.membership != undefined &&  this.state.membership.map((member,index)=>{
                            return <li>                            
                            {member}
                            </li> 
                        })
                    }
                    {this.state.membership != undefined && this.state.membership.length == 0 && <span>No Memberships</span>}                 
                </ul>
                </div>
                </CardBody>
            </Card> */}
            <p></p>
            <Card>
                <CardHeader>Sub Industries</CardHeader>
                    <CardBody  className="viewrfp">                                      
                        
                        <CardBody  className="viewrfp">
                            <div className="nic-codes"> {/*{this.state.naic_codes}*/}
                                <ul style={{listStyle:'none', padding:'0'}}>
                                    {
                                        this.state.subindustries !=undefined && this.state.subindustries.length > 0 &&  this.state.subindustries.map(recipe => 
                                        {
                                            if(this.state.subindustries.length > 0){
                                                if(this.state.subindustriesdata && this.state.subindustriesdata.length > 0){
                                                    return (
                                                        <span style={{float: 'left'}}>
                                                        {this.state.subindustriesdata.map((value, index) => {
                                                            return (
                                                                <div>
                                                                {value.subcatid ==  recipe ? <li>{value.subindustryname}<br />
                                                                </li>:''}
                                                                </div>
                                                                )
                                                            })}
                                                        </span>
                                                    )
                                                }
                                            }
                                            else
                                            {
                                                return (
                                                    <span style={{float: 'left', margin: "-19px 0px 0px 0px"}}>No Sub Industries</span>
                                                )
                                            }
                                            

                                        })
                                    } 
                                    {(this.state.subindustries && this.state.subindustries.length == 0) && <span style={{float: 'left', margin: "-19px 0px 0px 0px"}}>No Sub Industries</span>} 
                                </ul>
                            </div>
                        </CardBody>
                    </CardBody>
                </Card>

        </Col>
      </Row>
      <p></p>
       
    </Page>
  );
};
}

ViewvendorDetails.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors
});

//export default ChangepasswordPage;
export  default connect(mapStateToProps, {})(ViewvendorDetails);