import GAListener from "../src/components/GAListener";
import {EmptyLayout, LayoutRoute, MainLayout} from "../src/components/Layout";
import {Nigpnaics} from "../src/components/Nigpnaics";
import {Companies, AddCompany, EditCompany} from "../src/components/Companies";
import {
  Certifications,
  AddCertificate,
  EditCertificate,
  Agencies,
  Addagency,
  Editagency,
} from "../src/components/Certifications";
import {
  Customers,
  AddCustomer,
  EditCustomer,
  ViewCustomer,
} from "../src/components/Customers";
import {
  Vendors,
  AddVendor,
  EditVendor,
  ViewvendorDetails,
} from "../src/components/Vendors";
import {
  Industries,
  AddIndustry,
  EditIndustry,
  Editsubindustry,
  Subindustries,
  Addsubindustry,
  UploadIndustries,
} from "../src/components/Industries";
import {Cms, AddCms, EditCms} from "../src/components/Cms";
import {
  Rfprfiprofiles,
  Addrfprfi,
  Editrfprfi,
  Viewrfprfi,
  UploadOpportunities,
} from "../src/components/Rfprfiprofiles";
import Login from "../src/components/Login";
import ForgotPassword from "../src/components/ForgotPassword";
import SetadminPassword from "../src/components/SetadminPassword";
import DashboardPage from "../src/pages/DashboardPage";
//import ReportsPage from 'pages/ReportsPage';
import {
  Reports,
  Companiesreport,
  Rfprfireport,
  Vendorreport,
  Likedrfpreport,
  Appliedrfpreport,
  Wonrfpreport,
  Lostrfpreport,
  Likedrfpvendors,
  Postedopportunities,
  Favvendors,
  Rfplikedvendors,
  Vendorappliedrfps,
  Feedbackrfpvendors,
} from "../src/components/Reports";
import NavigationPages from "../src/pages/NavigationPages";
import ViewadminProfile from "../src/pages/ViewadminProfile";
import Editprofile from "../src/pages/Editprofile";
import UploadNigpNaic from "../src/pages/UploadNigpNaic";
import ChangepasswordPage from "../src/pages/ChangepasswordPage";
import {UploadVendors} from "../src/components/Uploadvendors";

import ChartPage from "../src/pages/ChartPage";
import AppConstants from "../src/AppConstants";

import React from "react";
import componentQueries from "react-component-queries";
import {BrowserRouter, Redirect, Switch} from "react-router-dom";
import "./styles/reduction.css";

/** Redux process */
import {BrowserRouter as Router, Route} from "react-router-dom";
import {Provider} from "react-redux";
import store from "./store";
import jwt_decode from "jwt-decode";
import setAuthToken from "./setAuthToken";
import {setCurrentUser, logoutUser} from "./actions/authentication";

import {Partners, AddPartner, EditPartner} from "../src/components/Partners";
import {Banners, AddBanner, EditBanner} from "../src/components/Banners";

import {Howitworks, AddWork, EditWork} from "../src/components/Howitworks";
import AddNigpNaic from "components/Nigpnaics/AddNigpNaic";
import EditNigpNaic from "components/Nigpnaics/EditNigpNaic";
if (localStorage.jwtTokenAdmin) {
  setAuthToken(localStorage.jwtTokenAdmin);
  const decoded = jwt_decode(localStorage.jwtTokenAdmin);
  store.dispatch(setCurrentUser(decoded));

  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    store.dispatch(logoutUser());
    window.location.href = AppConstants.STAGADMIN;
  }
}

const getBasename = () => {
  //return `/${process.env.PUBLIC_URL.split('/').pop()}`;
};

class App extends React.Component {
  render() {
    return (
      <Provider store={store}>
        <Router>
          <BrowserRouter basename={getBasename()}>
            <GAListener>
              <Switch>
                <LayoutRoute
                  exact
                  path={`${AppConstants.STAGADMIN}`}
                  layout={EmptyLayout}
                  component={Login}
                />
                <LayoutRoute
                  exact
                  path={`${AppConstants.STAGADMIN}${"/forgotpassword"}`}
                  layout={EmptyLayout}
                  component={ForgotPassword}
                />
                <LayoutRoute
                  exact
                  path={`${AppConstants.STAGADMIN}${"/setadminpassword/:id"}`}
                  layout={EmptyLayout}
                  component={SetadminPassword}
                />
                <LayoutRoute
                  exact
                  path={`${AppConstants.STAGADMIN}${"/dashboard"}`}
                  layout={MainLayout}
                  component={DashboardPage}
                />
                <LayoutRoute
                  exact
                  path={`${AppConstants.STAGADMIN}${"/reports"}`}
                  layout={MainLayout}
                  component={Reports}
                />
                <LayoutRoute
                  exact
                  path={`${AppConstants.STAGADMIN}${"/Navigation"}`}
                  layout={MainLayout}
                  component={NavigationPages}
                />
                <LayoutRoute
                  exact
                  path={`${AppConstants.STAGADMIN}${"/changeadminpassword"}`}
                  layout={MainLayout}
                  component={ChangepasswordPage}
                />
                <LayoutRoute
                  exact
                  path={`${AppConstants.STAGADMIN}${"/viewadminprofile"}`}
                  layout={MainLayout}
                  component={ViewadminProfile}
                />
                <LayoutRoute
                  exact
                  path={`${AppConstants.STAGADMIN}${"/editadminprofile"}`}
                  layout={MainLayout}
                  component={Editprofile}
                />
                <LayoutRoute
                  exact
                  path={`${AppConstants.STAGADMIN}${"/uploadnigpnaic"}`}
                  layout={MainLayout}
                  component={UploadNigpNaic}
                />
                 <LayoutRoute
                  exact
                  path={`${AppConstants.STAGADMIN}${"/AddNigaNaic"}`}
                  layout={MainLayout}
                  component={AddNigpNaic}
                />
                <LayoutRoute
                  exact
                  path={`${AppConstants.STAGADMIN}${"/EditNigaNaic/:id"}`}
                  layout={MainLayout}
                  component={EditNigpNaic}
                />
                <LayoutRoute
                  exact
                  path={`${AppConstants.STAGADMIN}${"/nigpnaics"}`}
                  layout={MainLayout}
                  component={Nigpnaics}
                />
                <LayoutRoute
                  exact
                  path={`${AppConstants.STAGADMIN}${"/companies"}`}
                  layout={MainLayout}
                  component={Companies}
                />
                <LayoutRoute
                  exact
                  path={`${AppConstants.STAGADMIN}${"/addcompany"}`}
                  layout={MainLayout}
                  component={AddCompany}
                />
                <LayoutRoute
                  exact
                  path={`${AppConstants.STAGADMIN}${"/editcompany/:id"}`}
                  layout={MainLayout}
                  component={EditCompany}
                />
                <LayoutRoute
                  exact
                  path={`${AppConstants.STAGADMIN}${"/certifications"}`}
                  layout={MainLayout}
                  component={Certifications}
                />
                <LayoutRoute
                  exact
                  path={`${AppConstants.STAGADMIN}${"/addcertificate"}`}
                  layout={MainLayout}
                  component={AddCertificate}
                />
                <LayoutRoute
                  exact
                  path={`${AppConstants.STAGADMIN}${"/editcertificate/:id"}`}
                  layout={MainLayout}
                  component={EditCertificate}
                />
                <LayoutRoute
                  exact
                  path={`${AppConstants.STAGADMIN}${"/agencies/:id"}`}
                  layout={MainLayout}
                  component={Agencies}
                />
                <LayoutRoute
                  exact
                  path={`${AppConstants.STAGADMIN}${"/addagency/:id"}`}
                  layout={MainLayout}
                  component={Addagency}
                />
                <LayoutRoute
                  exact
                  path={`${AppConstants.STAGADMIN}${"/editagency/:id"}`}
                  layout={MainLayout}
                  component={Editagency}
                />
                <LayoutRoute
                  exact
                  path={`${AppConstants.STAGADMIN}${"/customers"}`}
                  layout={MainLayout}
                  component={Customers}
                />
                <LayoutRoute
                  exact
                  path={`${AppConstants.STAGADMIN}${"/addcustomer"}`}
                  layout={MainLayout}
                  component={AddCustomer}
                />
                <LayoutRoute
                  exact
                  path={`${AppConstants.STAGADMIN}${"/editcustomer/:id"}`}
                  layout={MainLayout}
                  component={EditCustomer}
                />

                <LayoutRoute
                  exact
                  path={`${AppConstants.STAGADMIN}${"/viewcustomer/:id"}`}
                  layout={MainLayout}
                  component={ViewCustomer}
                />

                <LayoutRoute
                  exact
                  path={`${AppConstants.STAGADMIN}${"/vendors"}`}
                  layout={MainLayout}
                  component={Vendors}
                />
                <LayoutRoute
                  exact
                  path={`${AppConstants.STAGADMIN}${"/addvendor"}`}
                  layout={MainLayout}
                  component={AddVendor}
                />
                <LayoutRoute
                  exact
                  path={`${AppConstants.STAGADMIN}${"/editvendor/:id"}`}
                  layout={MainLayout}
                  component={EditVendor}
                />

                <LayoutRoute
                  exact
                  path={`${AppConstants.STAGADMIN}${"/vendordetails/:id"}`}
                  layout={MainLayout}
                  component={ViewvendorDetails}
                />

                <LayoutRoute
                  exact
                  path={`${AppConstants.STAGADMIN}${"/industries"}`}
                  layout={MainLayout}
                  component={Industries}
                />
                <LayoutRoute
                  exact
                  path={`${AppConstants.STAGADMIN}${"/addindustry"}`}
                  layout={MainLayout}
                  component={AddIndustry}
                />
                <LayoutRoute
                  exact
                  path={`${AppConstants.STAGADMIN}${"/editindustry/:id"}`}
                  layout={MainLayout}
                  component={EditIndustry}
                />

                <LayoutRoute
                  exact
                  path={`${AppConstants.STAGADMIN}${"/subindustries/:id"}`}
                  layout={MainLayout}
                  component={Subindustries}
                />
                <LayoutRoute
                  exact
                  path={`${AppConstants.STAGADMIN}${"/addsubindustry/:id"}`}
                  layout={MainLayout}
                  component={Addsubindustry}
                />
                <LayoutRoute
                  exact
                  path={`${AppConstants.STAGADMIN}${"/editsubindustry/:id"}`}
                  layout={MainLayout}
                  component={Editsubindustry}
                />

                <LayoutRoute
                  exact
                  path={`${AppConstants.STAGADMIN}${"/uploadindustries"}`}
                  layout={MainLayout}
                  component={UploadIndustries}
                />

                <LayoutRoute
                  exact
                  path={`${AppConstants.STAGADMIN}${"/uploadvendors"}`}
                  layout={MainLayout}
                  component={UploadVendors}
                />

                <LayoutRoute
                  exact
                  path={`${AppConstants.STAGADMIN}${"/importopportunities"}`}
                  layout={MainLayout}
                  component={UploadOpportunities}
                />

                <LayoutRoute
                  exact
                  path={`${AppConstants.STAGADMIN}${"/rfprfiprofiles"}`}
                  layout={MainLayout}
                  component={Rfprfiprofiles}
                />
                <LayoutRoute
                  exact
                  path={`${AppConstants.STAGADMIN}${"/addrfprfi"}`}
                  layout={MainLayout}
                  component={Addrfprfi}
                />
                <LayoutRoute
                  exact
                  path={`${AppConstants.STAGADMIN}${"/editrfprfi/:id"}`}
                  layout={MainLayout}
                  component={Editrfprfi}
                />
                <LayoutRoute
                  exact
                  path={`${AppConstants.STAGADMIN}${"/viewrfprfi/:id"}`}
                  layout={MainLayout}
                  component={Viewrfprfi}
                />

                <LayoutRoute
                  exact
                  path={`${AppConstants.STAGADMIN}${"/likedrfpreport/:id"}`}
                  layout={MainLayout}
                  component={Likedrfpreport}
                />
                <LayoutRoute
                  exact
                  path={`${AppConstants.STAGADMIN}${"/appliedrfpreport/:id"}`}
                  layout={MainLayout}
                  component={Appliedrfpreport}
                />
                <LayoutRoute
                  exact
                  path={`${AppConstants.STAGADMIN}${"/wonrfpreport/:id"}`}
                  layout={MainLayout}
                  component={Wonrfpreport}
                />

                <LayoutRoute
                  exact
                  path={`${AppConstants.STAGADMIN}${"/lostrfpreport/:id"}`}
                  layout={MainLayout}
                  component={Lostrfpreport}
                />

                <LayoutRoute
                  exact
                  path={`${AppConstants.STAGADMIN}${"/likedrfpvendors/:id"}`}
                  layout={MainLayout}
                  component={Likedrfpvendors}
                />

                <LayoutRoute
                  exact
                  path={`${AppConstants.STAGADMIN}${"/feedbackrfpvendors/:id"}`}
                  layout={MainLayout}
                  component={Feedbackrfpvendors}
                />

                <LayoutRoute
                  exact
                  path={`${
                    AppConstants.STAGADMIN
                  }${"/postedopportunities/:id"}`}
                  layout={MainLayout}
                  component={Postedopportunities}
                />
                <LayoutRoute
                  exact
                  path={`${AppConstants.STAGADMIN}${"/favvendors/:id"}`}
                  layout={MainLayout}
                  component={Favvendors}
                />

                <LayoutRoute
                  exact
                  path={`${AppConstants.STAGADMIN}${"/rfplikedvendors/:id"}`}
                  layout={MainLayout}
                  component={Rfplikedvendors}
                />

                <LayoutRoute
                  exact
                  path={`${AppConstants.STAGADMIN}${"/vendorappliedrfps/:id"}`}
                  layout={MainLayout}
                  component={Vendorappliedrfps}
                />

                <LayoutRoute
                  exact
                  path={`${AppConstants.STAGADMIN}${"/companiesreport"}`}
                  layout={MainLayout}
                  component={Companiesreport}
                />
                <LayoutRoute
                  exact
                  path={`${AppConstants.STAGADMIN}${"/rfprfireport"}`}
                  layout={MainLayout}
                  component={Rfprfireport}
                />
                <LayoutRoute
                  exact
                  path={`${AppConstants.STAGADMIN}${"/vendorreport"}`}
                  layout={MainLayout}
                  component={Vendorreport}
                />
                <LayoutRoute
                  exact
                  path={`${AppConstants.STAGADMIN}${"/charts"}`}
                  layout={MainLayout}
                  component={ChartPage}
                />

                <LayoutRoute
                  exact
                  path={`${AppConstants.STAGADMIN}${"/cms"}`}
                  layout={MainLayout}
                  component={Cms}
                />
                <LayoutRoute
                  exact
                  path={`${AppConstants.STAGADMIN}${"/addcms"}`}
                  layout={MainLayout}
                  component={AddCms}
                />
                <LayoutRoute
                  exact
                  path={`${AppConstants.STAGADMIN}${"/editcms/:id"}`}
                  layout={MainLayout}
                  component={EditCms}
                />

                <LayoutRoute
                  exact
                  path={`${AppConstants.STAGADMIN}${"/partners"}`}
                  layout={MainLayout}
                  component={Partners}
                />
                <LayoutRoute
                  exact
                  path={`${AppConstants.STAGADMIN}${"/addpartner"}`}
                  layout={MainLayout}
                  component={AddPartner}
                />
                <LayoutRoute
                  exact
                  path={`${AppConstants.STAGADMIN}${"/editpartner/:id"}`}
                  layout={MainLayout}
                  component={EditPartner}
                />

<LayoutRoute
                  exact
                  path={`${AppConstants.STAGADMIN}${"/banners"}`}
                  layout={MainLayout}
                  component={Banners}
                />
                <LayoutRoute
                  exact
                  path={`${AppConstants.STAGADMIN}${"/addbanner"}`}
                  layout={MainLayout}
                  component={AddBanner}
                />
                <LayoutRoute
                  exact
                  path={`${AppConstants.STAGADMIN}${"/editbanner/:id"}`}
                  layout={MainLayout}
                  component={EditBanner}
                />

<LayoutRoute
                  exact
                  path={`${AppConstants.STAGADMIN}${"/howitworks"}`}
                  layout={MainLayout}
                  component={Howitworks}
                />
                <LayoutRoute
                  exact
                  path={`${AppConstants.STAGADMIN}${"/addWork"}`}
                  layout={MainLayout}
                  component={AddWork}
                />
                <LayoutRoute
                  exact
                  path={`${AppConstants.STAGADMIN}${"/editWork/:id"}`}
                  layout={MainLayout}
                  component={EditWork}
                />

                <Redirect to={`${AppConstants.STAGADMIN}${"/"}`} />
              </Switch>
            </GAListener>
          </BrowserRouter>
        </Router>
      </Provider>
    );
  }
}

const query = ({width}) => {
  if (width < 575) {
    return {breakpoint: "xs"};
  }

  if (576 < width && width < 767) {
    return {breakpoint: "sm"};
  }

  if (768 < width && width < 991) {
    return {breakpoint: "md"};
  }

  if (992 < width && width < 1199) {
    return {breakpoint: "lg"};
  }

  if (width > 1200) {
    return {breakpoint: "xl"};
  }

  return {breakpoint: "xs"};
};

export default componentQueries(query)(App);
