import React from 'react';
import { Link } from 'react-router-dom';
import nologo from '../../assets/img/logo/nologo.png';
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  FormFeedback,
} from 'reactstrap';
import Page from '../../../src/components/Page';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment'
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import axios from 'axios';
import { BootstrapTable, TableHeaderColumn, InsertButton } from 'react-bootstrap-table';
import AppConstants from '../../AppConstants';
import {ReactTitle} from 'react-meta-tags';


class ViewCustomer extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            _id:this.props.auth.user._id,
            "id": this.props.match.params.id,           
            "naiccodesdes":[],
            "subindustries":[],
            "subindustriesdata":[],
            "company_industrey":''
        }
        this.handleIndustryChange = this.handleIndustryChange.bind(this);
    }

    handleIndustryChange(company_industrey) {
        //console.log(event.target.value);
        if(company_industrey !=''){
          fetch(AppConstants.API+'/industries/getsubindustries/' + company_industrey).then(response => response.json())
          .then(data => {
            console.log(" sub industries ", data.subindustriesdata);
            if(data.Status == "Success")
            {
              this.setState({ 
                subindustriesdata: data.subindustriesdata,
                company_industrey: company_industrey,
              });
            }
            else{
              this.setState({
                company_industrey: company_industrey
              });
            }
          });
        }
        else
        {
          this.setState({
            company_industrey: company_industrey        
          });
        }
    }
    
componentDidMount()
  {
    fetch(AppConstants.API+'/cutomers/viewcompanydetails/'+this.props.match.params.id).then(response => response.json())
    .then(data => {
        
     // console.log(data.companydashboarddata.subindustries);
           /* if(data.companydashboarddata.naiccodesdes.length >0){
                var naiccodesdes = data.companydashboarddata.naiccodesdes.toString();
            }
            else
            {
                var naiccodesdes = "No Naic Codes";
            }*/
        if(data.Status == "Success")
        {
                var subcat = [];
                if(data.companydashboarddata.subindustries && data.companydashboarddata.subindustries.length >0){
                    subcat = data.companydashboarddata.subindustries;
                }
             this.setState({ 
                //id:this.props.auth.user._id,
                company_name: data.companydashboarddata.company_name,
                company_email: data.companydashboarddata.company_email,
                company_logo: data.companydashboarddata.company_logo || nologo,
                company: data.companydashboarddata.company,
                company_address: data.companydashboarddata.company_address,
                company_phoneno:data.companydashboarddata.company_phoneno,
                company_primary_email: data.companydashboarddata.company_primary_email,
                city: data.companydashboarddata.city,
                state: data.companydashboarddata.state,
                zipcode: data.companydashboarddata.zipcode,
                //industry: data.companydashboarddata.company,
                company: data.companydashboarddata.company,
                industry: data.companydashboarddata.industry,
                naiccodesdes:data.companydashboarddata.naiccodesdes,
                company_primary_contact_firstname: data.companydashboarddata.company_primary_contact_firstname,
                company_primary_contact_lastname: data.companydashboarddata.company_primary_contact_lastname,
                company_primary_contact_middlename: data.companydashboarddata.company_primary_contact_middlename,
                company_primary_email: data.companydashboarddata.company_primary_email,
                company_primary_contact_title: data.companydashboarddata.company_primary_contact_title,
                company_primary_department: data.companydashboarddata.company_primary_department,
                company_primary_contact_phoneno: data.companydashboarddata.company_primary_contact_phoneno,
                company_website: data.companydashboarddata.company_website,
                company_primary_contact_phoneno: data.companydashboarddata.company_primary_contact_phoneno,
                company_business_model:data.companydashboarddata.company_business_model,
                subindustries:subcat,
                company_industrey:data.companydashboarddata.company_industrey
             });
             this.handleIndustryChange(this.state.company_industrey);
        }else{
            this.setState({companydashboarddata :""});
        }
    })
    var element = document.getElementById("navItem-Companies-1");
    element.classList.add("active");

  };
  render() {  const {user} = this.props.auth;
 
    return (<Page title="View Company">
     <ReactTitle title="View Company"/>
    <Link to={'/dashboard'}>
    Home
    </Link>
    &nbsp; | &nbsp;&nbsp;
    <Link to={AppConstants.STAGADMIN+'/customers'}>
   View Company
    </Link>
    <div style={{float:'right'}}>
  <Link to={AppConstants.STAGADMIN+'/customers'} class="btn btn-primary redbtn" title="Back to Companies ">Back to Companies</Link>
  </div>
    <Row  className="form-block">
        <Col xl={6} lg={6} md={6}>
            <Card>
            <CardHeader>Basic info</CardHeader>
                <CardBody> 
                
                    <p>
                               <Label>
                                <p>
                                {this.state.company_logo &&
                                    <div style={{'text-align':'center'}}>
                                        <img src={this.state.company_logo} alt={this.state.company_name} style={{width:100}} className="img-responsive company-logo"/>
                                    </div>
                                }
                                </p>
                                <p>
                                    <Label style={{width:140}}>Company Name</Label>
                                    <Label> : &nbsp;&nbsp; </Label>
                                        <span> 
                                            {this.state.company_name}
                                        </span>
                                </p>
                                <p>
                                    <Label style={{width:140}}>Email</Label>
                                    <Label> : &nbsp;&nbsp; </Label>
                                        <span> 
                                            {this.state.company_email ? this.state.company_email : 'N/A'}
                                        </span>
                                </p>
                                <p>
                                    <Label style={{width:140}}>Company Type</Label>
                                    <Label> : &nbsp;&nbsp; </Label>
                                        <span> 
                                            {this.state.company ? this.state.company : 'N/A'}
                                        </span>
                                </p>
                                <p>
                                    <Label style={{width:140}}>Industry</Label>
                                    <Label> : &nbsp;&nbsp; </Label>
                                        <span> 
                                            {this.state.industry ? this.state.industry : 'N/A'}
                                        </span>
                                </p>
                             
                                <p>
                                    <Label style={{width:140}}>Address</Label>
                                    <Label> : &nbsp;&nbsp; </Label>
                                        <span> 
                                            {this.state.company_address ? this.state.company_address : 'N/A'}
                                        </span>
                                </p>
                                <p>
                                    <Label style={{width:140}}>City</Label>
                                    <Label> : &nbsp;&nbsp; </Label>
                                        <span> 
                                            {this.state.city ? this.state.city : 'N/A'}
                                        </span>
                                </p>
                                <p>
                                    <Label style={{width:140}}>State</Label>
                                    <Label> : &nbsp;&nbsp; </Label>
                                        <span> 
                                            {this.state.state ? this.state.state : 'N/A'}
                                        </span>
                                </p>
                                <p>
                                    <Label style={{width:140}}>Zip Code</Label>
                                    <Label> : &nbsp;&nbsp; </Label>
                                        <span> 
                                            {this.state.zipcode ? this.state.zipcode : 'N/A'}
                                        </span>
                                </p>
                                <p>
                                    <Label style={{width:140}}>
                                        Business Model
                                    </Label>
                                    <Label> : &nbsp;&nbsp; </Label>
                                    <span>{this.state.company_business_model?this.state.company_business_model:"N/A"}</span>
                                </p>
                               {/* <p>
                                    <Label style={{width:140}}>NAICS Code</Label>
                                    <Label> : &nbsp;&nbsp; </Label>
                                        <span> 
                                        {this.state.naiccodesdes.map(recipe => {
                                                return (
                                                    <span>
                                                    <dd>{recipe},</dd>
                                                    </span>
                                                    )
                                                })
                                        } 
                                        {this.state.naiccodesdes.length == 0 && <span>No Naic Codes</span>} 
                     
                                        </span>
                                    </p>*/}
                              
                            </Label>
                      
                    </p>
                    
                </CardBody>
            </Card>
            <p></p>
            <Card>
                <CardHeader>NAICS Code</CardHeader>
                    
                    <CardBody  className="viewrfp">
                    <div className="nic-codes"> {/*{this.state.naic_codes}*/}
                                    <ul style={{listStyle:'none', padding:'0'}}>
                                        {
                                            this.state.naiccodesdes !=undefined &&  this.state.naiccodesdes.map(recipe => 
                                            {
                                                if(recipe != null)
                                                {
                                                    return (
                                                        <li>{recipe}<span>,</span></li>
                                                    )
                                                }else{
                                                    return (
                                                        <span style={{float: 'left'}}>No NAICS Codes</span>
                                                    )
                                                }
                                                    
                                            })
                                        } 
                                        {this.state.naiccodesdes !=undefined && this.state.naiccodesdes.length == 0 && <span>No NAICS Codes</span>} 
                                        </ul>
                                    </div>
                                    </CardBody>
                    </Card>       
            </Col>
             
            
             <Col xl={6} lg={6} md={6}>
                <Card>
                <CardHeader>Contact Info</CardHeader>
                    
                    <CardBody  className="viewrfp">
                        <p>
                            <Label style={{width:140}}>First Name</Label>
                            <Label> : &nbsp;&nbsp; </Label>
                            <span> {this.state.company_primary_contact_firstname ? this.state.company_primary_contact_firstname : 'N/A'}</span>
                        </p>
                        <p>
                            <Label style={{width:140}}>Last Name</Label>
                            <Label> : &nbsp;&nbsp; </Label>
                            <span> {this.state.company_primary_contact_lastname ? this.state.company_primary_contact_lastname : 'N/A'}</span>
                        </p>
                        <p>
                            <Label style={{width:140}}>Middle Name</Label>
                            <Label> : &nbsp;&nbsp; </Label>
                            <span> {this.state.company_primary_contact_middlename ? this.state.company_primary_contact_middlename : 'N/A'}</span>
                        </p>                  
                        <p>
                            <Label style={{width:140}}>Email</Label>
                            <Label> : &nbsp;&nbsp; </Label>
                            <span> {this.state.company_primary_email ? this.state.company_primary_email : 'N/A'}</span>
                        </p>
                        <p>
                            <Label style={{width:140}}>Title</Label>
                            <Label> : &nbsp;&nbsp; </Label>
                            <span> {this.state.company_primary_contact_title ? this.state.company_primary_contact_title : 'N/A'}</span>
                        </p>   
                        <p>
                            <Label style={{width:140}}>Phone Number</Label>
                            <Label> : &nbsp;&nbsp; </Label>
                            <span> {this.state.company_primary_contact_phoneno ? this.state.company_primary_contact_phoneno : 'N/A'}</span>
                        </p>   
                        <p>
                            <Label style={{width:140}}>Department</Label>
                            <Label> : &nbsp;&nbsp; </Label>
                            <span> {this.state.company_primary_department ? this.state.company_primary_department : 'N/A'}</span>
                        </p>   
                        {/*<p>
                            <Label style={{width:160}}>Website</Label>
                            <Label> : &nbsp;&nbsp; </Label>
                            <span> {this.state.company_website ? this.state.company_website : 'N/A'}</span>
                        </p>*/} 
                        <p>
                            <Label style={{width:140}}>Website</Label>
                                <Label> : &nbsp;&nbsp; </Label>                                         
                                {(this.state.company_website) ? (<span><a href={this.state.company_website} target="_blank">{this.state.company_website}</a></span>) : (<span>N/A</span>)}
                        </p>  
                                          
                    </CardBody>
                </Card><br/>

                <Card>
                <CardHeader>Sub Industries</CardHeader>
                    <CardBody  className="viewrfp">                                      
                        
                        <CardBody  className="viewrfp">
                            <div className="nic-codes"> {/*{this.state.naic_codes}*/}
                                <ul style={{listStyle:'none', padding:'0'}}>
                                    {
                                        this.state.subindustries !=undefined && this.state.subindustries.length > 0 &&  this.state.subindustries.map(recipe => 
                                        {
                                            if(this.state.subindustries.length > 0){
                                                if(this.state.subindustriesdata && this.state.subindustriesdata.length > 0){
                                                    return (
                                                        <span style={{float: 'left'}}>
                                                        {this.state.subindustriesdata.map((value, index) => {
                                                            return (
                                                                <div>
                                                                {value.subcatid ==  recipe ? <li>{value.subindustryname}<br />
                                                                </li>:''}
                                                                </div>
                                                                )
                                                            })}
                                                        </span>
                                                    )
                                                }
                                            }
                                            else
                                            {
                                                return (
                                                    <span style={{float: 'left', margin: "-19px 0px 0px 0px"}}>No Sub Industries</span>
                                                )
                                            }
                                            

                                        })
                                    } 
                                    {(this.state.subindustries && this.state.subindustries.length == 0) && <span style={{float: 'left', margin: "-19px 0px 0px 0px"}}>No Sub Industries</span>} 
                                </ul>
                            </div>
                        </CardBody>
                    </CardBody>
                </Card>
           
        </Col> 
    </Row>  
    </Page>

    )
  }
}


ViewCustomer.propTypes = {
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};
  const mapStateToProps = (state) => ({
    auth: state.auth,
    errors: state.errors
});
  
  //export default ChangepasswordPage;
  export  default connect(mapStateToProps, {})(ViewCustomer);