import React from 'react';
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  FormFeedback,
} from 'reactstrap';
import Page from '../../../src/components/Page';
import axios from 'axios';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import AppConstants from '../../AppConstants';
import Loading from '../../assets/img/loader.gif';
import {ReactTitle} from 'react-meta-tags';
import ReactDOM from 'react-dom';



class AddCertificate extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      _id:this.props.auth.user._id,
      certification_name:'',
      alias_name:'',
      status:'Active',       
      errors: {},
      errMsg:'',
      loading: '',
      "badge": '',
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    //this.CancelSubmit = this.CancelSubmit.bind(this);  
    this.handleInputChange = this.handleInputChange.bind(this); 
    this.handleInputFileChange=this.handleInputFileChange.bind(this);
  }
  
  handleInputChange(e) {
    this.setState({
        [e.target.name]: e.target.value
    });
  }
  handleInputFileChange(e) {    
    this.setState({
    [e.target.name]: e.target.files[0]
    });
  }
  componentDidMount() {
    var element = document.getElementById("navItem-Certifications and Agencies-5");
    element.classList.add("active");
  }
  
  /** Validations */
  handleValidation(){

    console.log(this.state.badge);
    let errors = {};
    let formIsValid = true;
    if(!this.state.certification_name){
      formIsValid = false;
      errors["certification_name"] = "Please enter certification name";
      ReactDOM.findDOMNode(this.refs.certification_name).focus();
    }

    if(!this.state.alias_name){
      formIsValid = false;
      errors["alias_name"] = "Please enter badge name";
      ReactDOM.findDOMNode(this.refs.alias_name).focus();
    }

    if(!this.state.badge){
      formIsValid = false;
      errors["badge_error"] = "Please upload valid badge";
    }
    if(this.state.badge && this.state.badge.name && !this.state.badge.name.match(/.(jpg|jpeg|png)$/i)){
      formIsValid = false;
      errors["badge_error"] = "Please select valid badge (Ex: jpg | jpeg | png )";
    }

    this.setState({errors: errors});
    return formIsValid;
  }

  handleSubmit = event => {
    event.preventDefault();
    //alert('form submitted');    
     if(this.handleValidation()){
      this.setState({loading:1});   
      // const certificationsdata = {
      //   id:this.props.auth.user._id,
      //   certification_name: this.state.certification_name,
      //   status: this.state.status
      // }

      let formData = new FormData();
          formData.append('id', this.state.id);
          formData.append('certification_name', this.state.certification_name);
          formData.append('alias_name', this.state.alias_name);
          formData.append('badge', this.state.badge);
          formData.append('status', this.state.status);

      axios.post(AppConstants.API+'/certifications/addcertificate',formData)
        .then(res => {   
          console.log(res);   
         if(res.data.Status == "Success"){
            setTimeout((e) => {           
            this.props.history.push(AppConstants.STAGADMIN+'/certifications');
			}, 2000);         
                   
          this.setState({
            //errMsg: res.data.msg
            errMsg: (<div className="alert alert-success fade show mt-3">{res.data.msg}</div>)
          });
        }
        else{
          this.setState({loading:''});
          setTimeout((e) => {
            this.setState({
              errMsg: ''
            });
           },2000);
          this.setState({
            //errMsg: res.data.msg
            errMsg: (<div className="alert alert-danger fade show mt-3">{res.data.msg}</div>)
          });
        }
      });
    } 
  };
  /* CancelSubmit = e => {
    e.preventDefault();
    window.location.href = '/certifications';
  } */
  
  render() {
    const {user} = this.props.auth;
  return (
    <Page title="Add Certificate">
     <ReactTitle title="Add Certification"/>
        <Link to={AppConstants.STAGADMIN+'/dashboard'}>
          Home
        </Link>
        &nbsp; | &nbsp;&nbsp;
        <Link to={AppConstants.STAGADMIN+'/certifications'}>
        Certifications and Agencies
        </Link>
        <Form className="form-block"  name="changeadminpassword" onSubmit= { this.handleSubmit }>
      <Row>
        <Col xl={12} lg={12} md={12}>
          <Card>
            <CardBody>

            <Row>
              <Col xl={6} lg={6} md={6}>
              <FormGroup>
                  <Label for="examplePassword" >
                    Certification Name <span className="error" style={{color: 'red'}}>*</span>
                  </Label>
                    <Input
                      type="text"
                      name="certification_name"
                      placeholder="Certificate Name"
                      onChange={ this.handleInputChange } ref ="certification_name"                 
                    />
                    <span className="error" style={{color: 'red'}}>{this.state.errors["certification_name"]}</span>
                </FormGroup>

                <FormGroup>
                  <Label for="examplePassword" >
                    Badge Name <span className="error" style={{color: 'red'}}>*</span>
                  </Label>
                    <Input
                      type="text"
                      name="alias_name"
                      placeholder="Badge Name"
                      onChange={ this.handleInputChange } ref ="alias_name"                 
                    />
                    <span className="error" style={{color: 'red'}}>{this.state.errors["alias_name"]}</span>
                </FormGroup>

                <FormGroup>
                  <Label for="exampleFile" style={{display: 'block'}}>
                    Badge <span className="error" style={{color: 'red'}}>*</span>
                  </Label>
                  
                    <div class = "file-field input-field fileupload">
                    <div class = "filebtn">
                        <span><i class="fa fa-upload" aria-hidden="true"></i></span>
                        <input type = "file" name="badge" onChange={ this.handleInputFileChange} />
                    </div>
                    <div class = "file-path-wrapper">
                        <input class = "file-path validate" type = "text" placeholder = " Please upload badge." />
                    </div>
                </div> 
                    <span className="error" style={{color: 'red'}}>{this.state.errors["badge_error"]}</span>
                
                </FormGroup> 
              </Col>
            </Row>
                

                <FormGroup>
                  <Label for="exampleSelect" >
                    Status
                  </Label>
                  
                  <Input type="select" name="status" onChange={ this.handleInputChange }  className="col-md-6">
                    <option value="Active">Active</option>
                    <option value="In-Active">In Active</option>                    
                  </Input>
                  
                </FormGroup>
                
              
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
      <Col md={12} className="form-btn">
          <Button className="btn btn-primary addbtn"  onClick={ this.handleSubmit } title="Add Certificate">Add
            <div style={this.state.loading ? {} : { display: 'none' }} class="image-fill w-25 loader-login-div "><img src={Loading} alt="No Image" className="can-click " /></div>
          </Button> &nbsp;&nbsp;
          {/* <Button class="btn btn-primary" color="danger" onClick={ this.CancelSubmit } title="Cancel Certificate">Cancel</Button> */}
          <Link to={AppConstants.STAGADMIN+"/certifications"} class="btn btn-primary redbtn" title="Cancel Certificate">Cancel</Link>
      </Col>
               
      </Row>
      {this.state.errMsg}
      </Form>
    </Page>
  );
};
}

AddCertificate.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors
});

//export default ChangepasswordPage;
export  default connect(mapStateToProps, {})(AddCertificate);
