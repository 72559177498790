import React from "react";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  FormFeedback,
} from "reactstrap";
import Page from "../Page";
import axios from "axios";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import AppConstants from "../../AppConstants";
import Loading from "../../assets/img/loader.gif";
import {ReactTitle} from "react-meta-tags";
import ReactDOM from "react-dom";
import CKEditor from "react-ckeditor-component";

class AddBanner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      _id: this.props.auth.user._id,
      status: "Active",
      errors: {},
      errMsg: "",
      loading: "",
      partnerImage: "",
      title: "",
      content: ""
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputFileChange = this.handleInputFileChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this); 
    this.onChange = this.onChange.bind(this); 
  }

  onChange(evt){
    console.log("onChange fired with event info: ", evt);
    var newContent = evt.editor.getData();
    this.setState({
      content: newContent
    })
  }

  handleInputChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }
  handleInputFileChange(e) {
    this.setState({
      [e.target.name]: e.target.files[0],
    });
  }
  componentDidMount() {
    var element = document.getElementById("navItem-Banners-3");
    element.classList.add("active");
  }

  /** Validations */
  handleValidation() {
    let errors = {};
    let formIsValid = true;

    if(!this.state.title){
      formIsValid = false;
      errors["title"] = "Please enter title";
      ReactDOM.findDOMNode(this.refs.title).focus();
    }

    

    

    if (!this.state.partnerImage) {
      formIsValid = false;
      errors["badge_error"] = "Please upload valid image";
    }
    if (
      this.state.partnerImage &&
      this.state.partnerImage.name &&
      !this.state.partnerImage.name.match(/.(jpg|jpeg|png)$/i)
    ) {
      formIsValid = false;
      errors["badge_error"] =
        "Please select valid image (Ex: jpg | jpeg | png )";
    }

    if(!this.state.content){
      formIsValid = false;
      errors["content"] = "Please enter description";
    }

    this.setState({errors: errors});
    return formIsValid;
  }

  handleSubmit = (event) => {
    event.preventDefault();
    //alert('form submitted');
    if (this.handleValidation()) {
      this.setState({loading: 1});

      let formData = new FormData();
      formData.append("partnerImage", this.state.partnerImage);
      formData.append("status", this.state.status);
      formData.append("title", this.state.title);
      formData.append("description", this.state.content);
     
      axios
        .post(AppConstants.API + "/certifications/addBanner", formData)
        .then((res) => {
          console.log(res);
          if (res.data.Status == "Success") {
            setTimeout((e) => {
              this.props.history.push(AppConstants.STAGADMIN + "/banners");
            }, 2000);

            this.setState({
              //errMsg: res.data.msg
              errMsg: (
                <div className="alert alert-success fade show mt-3">
                  {res.data.msg}
                </div>
              ),
            });
          } else {
            this.setState({loading: ""});
            setTimeout((e) => {
              this.setState({
                errMsg: "",
              });
            }, 2000);
            this.setState({
              //errMsg: res.data.msg
              errMsg: (
                <div className="alert alert-danger fade show mt-3">
                  {res.data.msg}
                </div>
              ),
            });
          }
        });
    }
  };
  /* CancelSubmit = e => {
    e.preventDefault();
    window.location.href = '/certifications';
  } */

  render() {
    const {user} = this.props.auth;
    return (
      <Page title="Add Banner">
        <ReactTitle title="Add Banner" />
        <Link to={AppConstants.STAGADMIN + "/dashboard"}>Home</Link>
        &nbsp; | &nbsp;&nbsp;
        <Link to={AppConstants.STAGADMIN + "/banners"}>Banners</Link>
        <Form
          className="form-block"
          name="changeadminpassword"
          onSubmit={this.handleSubmit}
        >
          <Row>
            <Col xl={12} lg={12} md={12}>
              <Card>
                <CardBody>
                  <FormGroup>
                    <Label for="examplePassword">
                      Title{" "}
                      <span className="error" style={{color: "red"}}>
                        *
                      </span>
                    </Label>

                    <Input
                      type="text"
                      name="title"
                      placeholder="Title"
                      onChange={this.handleInputChange}
                      ref="title"
                      className="col-md-6"
                    />
                    <span className="error" style={{color: "red"}}>
                      {this.state.errors["title"]}
                    </span>
                  </FormGroup>

                  <Row>
                    <Col xl={6} lg={6} md={6}>
                      <FormGroup>
                        <Label for="exampleFile" style={{display: "block"}}>
                          Image{" "}
                          <span className="error" style={{color: "red"}}>
                            *
                          </span>
                        </Label>

                        <div class="file-field input-field fileupload">
                          <div class="filebtn">
                            <span>
                              <i class="fa fa-upload" aria-hidden="true"></i>
                            </span>
                            <input
                              type="file"
                              name="partnerImage"
                              onChange={this.handleInputFileChange}
                            />
                          </div>
                          <div class="file-path-wrapper">
                            <input
                              class="file-path validate"
                              type="text"
                              placeholder=" Please upload image."
                            />
                          </div>
                        </div>
                        <span className="error" style={{color: "red"}}>
                          {this.state.errors["badge_error"]}
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>

                  <FormGroup>
                  <Label for="examplePassword" >
                  Description <span className="error" style={{color: 'red'}}>*</span>
                  </Label>
                    <CKEditor 
                      activeClass="p10" 
                      // editor={ ClassicEditor }
                      content={this.state.content}                      
                      events={{                        
                        "change": this.onChange
                      }} className="col-md-6"
                    />
                    <span className="error" style={{color: 'red'}}>{this.state.errors["content"]}</span>
                  
                </FormGroup>               
                

                  <FormGroup>
                    <Label for="exampleSelect">Status</Label>

                    <Input
                      type="select"
                      name="status"
                      onChange={this.handleInputChange}
                      className="col-md-6"
                    >
                      <option value="Active">Active</option>
                      <option value="In-Active">In Active</option>
                    </Input>
                  </FormGroup>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md={12} className="form-btn">
              <Button
                className="btn btn-primary addbtn"
                onClick={this.handleSubmit}
                title="Add Banner"
              >
                Add
                <div
                  style={this.state.loading ? {} : {display: "none"}}
                  class="image-fill w-25 loader-login-div "
                >
                  <img src={Loading} alt="No Image" className="can-click " />
                </div>
              </Button>{" "}
              &nbsp;&nbsp;
              {/* <Button class="btn btn-primary" color="danger" onClick={ this.CancelSubmit } title="Cancel Certificate">Cancel</Button> */}
              <Link
                to={AppConstants.STAGADMIN + "/banners"}
                class="btn btn-primary redbtn"
                title="Cancel Banner"
              >
                Cancel
              </Link>
            </Col>
          </Row>
          {this.state.errMsg}
        </Form>
      </Page>
    );
  }
}

AddBanner.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

//export default ChangepasswordPage;
export default connect(mapStateToProps, {})(AddBanner);
