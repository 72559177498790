import React from 'react';
import { Link } from 'react-router-dom';
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  FormFeedback,
} from 'reactstrap';
import Page from '../../../src/components/Page';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment'
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import axios from 'axios';
import { BootstrapTable, TableHeaderColumn, InsertButton } from 'react-bootstrap-table';
import AppConstants from '../../AppConstants';
import {ReactTitle} from 'react-meta-tags';

class Viewrfprfi extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            "id":this.props.match.params.id,
            "naic_code": [],
            "certifications":[],
            "certificationsdata":[],
            "viewedrfprfp_vendorids": [],
            "likedrfprfp_vendorids": [],
            "interestedrfprfp_vendorids": [],
            naiccodes:[],
            customersdata:[],
            getfprfiprofiledata:[],
            allcerificationdata:[],
            "certification_documents":[]
        }
    }

    componentDidMount()
    {
        fetch(AppConstants.API+'/rfprfiprofiles/viewrfprfiprofile/'+this.props.match.params.id).then(response => response.json())
            .then(data => {
          /*  if(data.rfprfiprofilesdata.certifications.length >0){
				var certifications = data.rfprfiprofilesdata.certifications.toString();
			}
			else
			{
				var certifications = "No Certifications";
            }*/
            
            //console.log(data.rfprfiprofilesdata.certification_documents);
            if(data.Status == "Success")
            {

                //posted date
                var posteddate = data.rfprfiprofilesdata.rfp_post_date;
                var posteddate= new Date(posteddate);             
                var postdate =  ("0" + (posteddate.getMonth() + 1)).slice(-2) + "-" + (posteddate.getDate() < 10 ? '0' : '')+ posteddate.getDate() + "-" +  posteddate.getFullYear();

                //Due date
                var duedate = data.rfprfiprofilesdata.due_dateandtime;
                var duedate= new Date(duedate);
                var hours = duedate.getHours();
                var minutes = duedate.getMinutes();
                var ampm = hours >= 12 ? 'PM' : 'AM';
                hours = hours % 12;
                hours = hours ? hours : 12; // the hour '0' should be '12'
                minutes = minutes < 10 ? '0'+minutes : minutes;
                var strTime = hours + ':' + minutes + ' ' + ampm;
                var duedt=  ("0" + (duedate.getMonth() + 1)).slice(-2) + "-" + (duedate.getDate() < 10 ? '0' : '')+ duedate.getDate() + "-" + duedate.getFullYear() + " " + strTime;

                //Questions date
                var deadlinedate = data.rfprfiprofilesdata.deadline_for_questions;
                var deadlinedate= new Date(deadlinedate);             
                var deaddate =  ("0" + (deadlinedate.getMonth() + 1)).slice(-2) + "-" + (deadlinedate.getDate() < 10 ? '0' : '')+ deadlinedate.getDate() + "-" +  deadlinedate.getFullYear();
                  

                this.setState({
                    bid_link:data.rfprfiprofilesdata.bid_link,
                    bid_rfp_reference_number:data.rfprfiprofilesdata.bid_rfp_reference_number,
                    contact_email:data.rfprfiprofilesdata.contact_email,
                    company_name:data.rfprfiprofilesdata.company_name,
                    contact_first_name:data.rfprfiprofilesdata.contact_first_name,
                    contact_last_name:data.rfprfiprofilesdata.contact_last_name,
                    contact_phonenumber:data.rfprfiprofilesdata.contact_phonenumber,
                    deadline_for_questions:deaddate,
                    department:data.rfprfiprofilesdata.department,
                    description:data.rfprfiprofilesdata.description,
                    documents:data.rfprfiprofilesdata.documents,
                    due_dateandtime:duedt,
                    minority_business:data.rfprfiprofilesdata.minority_business,
                    name_ofrfp:data.rfprfiprofilesdata.name_ofrfp,
                    need_certifications:data.rfprfiprofilesdata.need_certifications,
                    rfp_post_date:postdate,
                    women_owned_business:data.rfprfiprofilesdata.women_owned_business,
                    certifications:data.rfprfiprofilesdata.certifications,
                    naiccodesdes:data.rfprfiprofilesdata.naiccodesdes,
                    allcerificationdata:data.rfprfiprofilesdata.certification_documents,
                    certification_documents:data.rfprfiprofilesdata.certification_documents
                })
            }
            else{
                this.setState({rfprfiprofilesdata:""});
            }
        })
        var element = document.getElementById("navItem-Opportunities-3");
    element.classList.add("active");

    }
    
  render() {  
  var k = 1;
  const {user} = this.props.auth;
  let minority ='';
    if(this.state.minority_business == true){
        minority = 'Yes'
    }
    else{
        minority = 'No'
    }

    let Business ='';
    if(this.state.women_owned_business == true){
        Business = 'Yes'
    }
    else{
        Business = 'No'
    }
 var k = 1;
  return (<Page title="View Opportunity">
  <ReactTitle title="View Opportunity"/>
  <Link to={'/dashboard'}>
  Home
  </Link>
  &nbsp; | &nbsp;&nbsp;
  <Link to={AppConstants.STAGADMIN+'/rfprfiprofiles'}>
  Opportunities List
  </Link>
  <div style={{float:'right'}}>
  <Link to={AppConstants.STAGADMIN+"/rfprfiprofiles"} class="btn btn-primary redbtn" title="Back to  Opportunities">Back to Opportunities</Link>
  </div>
  
  <Row  className="form-block">
      <Col xl={6} lg={6} md={6}>
          <Card>
          <CardHeader>Opportunity INFO</CardHeader>
              <CardBody className="viewrfp"> 
              
                  <p>
                             <Label>
                             
                              <p>
                                  <Label style={{width:140}}>Name Of Opportunity</Label>
                                  <Label> : &nbsp;&nbsp; </Label>
                                      <span> 
                                          {this.state.name_ofrfp ? this.state.name_ofrfp : 'N/A'}
                                      </span>
                              </p>
                              <p>
                                  <Label style={{width:140}}>Description</Label>
                                  <Label> : &nbsp;&nbsp; </Label>
                                      <span> 
                                          {this.state.description ? this.state.description : 'N/A'}
                                      </span>
                              </p>
                              <p>
                                  <Label style={{width:140}}>Companies </Label>
                                  <Label> : &nbsp;&nbsp; </Label>
                                      <span> 
                                          {this.state.company_name ? this.state.company_name : 'N/A'}
                                      </span>
                              </p>
                              <p>
                                  <Label style={{width:140}}>Department </Label>
                                  <Label> : &nbsp;&nbsp; </Label>
                                      <span> 
                                          {this.state.department ? this.state.department : 'N/A'}
                                      </span>
                              </p>
                           
                              
                              
                              <p>
								<Label style={{width:140}}>Biz Link</Label>
								<Label> : &nbsp;&nbsp; </Label>
								{(this.state.bid_link) ? (<span><a href={this.state.bid_link} target="_blank" className="document-link">{this.state.bid_link}</a></span>) : (<span>NO Biz Link</span>)}
							</p>

                              <p>
                                  <Label style={{width:140}}>Supporting Document</Label>
                                  <Label> : &nbsp;&nbsp; </Label>
                                      
                                          {/* <span  className="document-link"> {this.state.documents ? this.state.documents : 'N/A'}   </span>*/}                                         
                                      {(this.state.documents) ? (<span><a href={this.state.documents} target="_blank" className="document-link">Document</a></span>) : (<span>NO Document</span>)}
                              </p>
                            
                              <p>
                                  <Label style={{width:140}}>Minority Business</Label>
                                  <Label> : &nbsp;&nbsp; </Label>
                                      <span> 
                                      <h6>{minority}</h6>
                                      </span>
                              </p>
                              <p>
                                  <Label style={{width:140}}>Women Owned Business</Label>
                                  <Label> : &nbsp;&nbsp; </Label>
                                      <span> 
                                      <h6>{Business}</h6>
                                      </span>
                              </p>
                              <p>
                                  <Label style={{width:140}}>Posted Date</Label>
                                  <Label> : &nbsp;&nbsp; </Label>
                                      <span> 
                                          {this.state.rfp_post_date ? this.state.rfp_post_date : 'N/A'}
                                      </span>
                              </p>
                              <p>
                                  <Label style={{width:140}}>Due Date & Time</Label>
                                  <Label> : &nbsp;&nbsp; </Label>
                                      <span> 
                                          {this.state.due_dateandtime ? this.state.due_dateandtime : 'N/A'}
                                      </span>
                              </p>
                              
                              <p>
                                  <Label style={{width:140}}>Deadline For Questions</Label>
                                  <Label> : &nbsp;&nbsp; </Label>
                                      <span> 
                                          {this.state.deadline_for_questions ? this.state.deadline_for_questions : 'N/A'}
                                      </span>
                              </p>
                             {/* <p>
                                  <Label style={{width:140}}>NAICS Code</Label>
                                  <Label> : &nbsp;&nbsp; </Label>
                                      <span> 
                                          {this.state.naiccodesdes ? this.state.naiccodesdes : 'N/A'}
                                      </span>
                             </p> */}
                            
                                
                          </Label>
                    
                  </p>
                  
              </CardBody>
          </Card>
          </Col>
           <p></p>
           
           <Col xl={6} lg={6} md={6}>
           
              <Card>
              <CardHeader>Opportunity CONTACT INFO</CardHeader>
                  
                  <CardBody>
                      <p>
                          <Label style={{width:160}}>First Name</Label>
                          <Label> : &nbsp;&nbsp; </Label>
                          <span> {this.state.contact_first_name ? this.state.contact_first_name : 'N/A'}</span>
                      </p>
                      <p>
                          <Label style={{width:160}}>Last Name</Label>
                          <Label> : &nbsp;&nbsp; </Label>
                          <span> {this.state.contact_last_name ? this.state.contact_last_name : 'N/A'}</span>
                      </p>
                      <p>
                          <Label style={{width:160}}>Phone Number</Label>
                          <Label> : &nbsp;&nbsp; </Label>
                          <span> {this.state.contact_phonenumber ? this.state.contact_phonenumber : 'N/A'}</span>
                      </p>  
                      <p>
                          <Label style={{width:160}}>Email</Label>
                          <Label> : &nbsp;&nbsp; </Label>
                          <span> {this.state.contact_email ? this.state.contact_email : 'N/A'}</span>
                      </p>
                      <p>
                          <Label style={{width:160}}>Reference Number</Label>
                          <Label> : &nbsp;&nbsp; </Label>
                          <span> {this.state.bid_rfp_reference_number ? this.state.bid_rfp_reference_number : 'N/A'}</span>
                      </p>   
                     
                      
                  </CardBody>
              </Card>
              <p></p>
              <Card>
              <CardHeader>CERTIFICATIONS</CardHeader>
              
                  <CardBody>
                     {/*<p>
                          <span>  {this.state.certifications.map(recipe => {
                            return (
                                <span>
                                <dd>{recipe}</dd>
                                </span>
                                )
                            })
                        } 
                          {this.state.certifications.length == 0 && <span>No Certifications</span>}</span>
                    </p>  */}
                    <p>                  
                        <div className="nic-codes">
                        <ul style={{listStyle:'none', padding:'0'}}>
                            {this.state.certifications.map(recipe => {
                                    return (
                                        
                                        <li>{recipe}<span>,</span></li>
                                    
                                        )
                                    })
                                }
                            {this.state.certifications.length == 0 && <span>No Certifications</span>} 
                            </ul>
                            {/* <ul style={{listStyle:'none', padding:'0'}}>
                                {this.state.certifications.map(recipe => {
                                    return (
                                    <span>{this.state.certification_documents.map((value, index) => {
                                    return (
                                        <div key={index}>
                                        {value.certificationid ==  recipe._id? <span>{recipe.certification_name}<br />
                                        
                                        <a href={value.certificationurl} target="_blank"><i class="fa fa-file-pdf-o" aria-hidden="true"></i> Document {k++} </a>
                                        </span> : ''}
                                        </div>
                                        )
                                    })}
                                    </span>
                                    )
                                    })
                                }
                                {this.state.certifications.length == 0 && <span>No Certifications</span>} 
                            </ul> */}
                        </div>
                    </p>
                    </CardBody>
                </Card>

                <p></p>
              <Card>
              <CardHeader>NAICS Codes</CardHeader>
                  <CardBody>
                    <p>                  
                        <div className="nic-codes">
                            <ul style={{listStyle:'none', padding:'0'}}>
                                {this.state.naiccodesdes !=undefined && this.state.naiccodesdes.map(recipe => {
                                    if(recipe != null)
                                    {
                                        return (
                                            <li>{recipe}<span>,</span></li>
                                        
                                            )
                                    }
                                    else
                                    {
                                        return (
                                            <span>No NAICS Codes</span>
                                        )
                                    }
                                        
                                        })
                                    } 
                                {this.state.naic_codes !=undefined && this.state.naic_codes.length == 0 && <span>No NAICS Codes</span>} 
                            </ul>
                        </div>
                    </p>
                    </CardBody>
                </Card>
      </Col> 
  </Row>  
  </Page>

  )
}
}

Viewrfprfi.propTypes = {
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};
  const mapStateToProps = (state) => ({
    auth: state.auth,
    errors: state.errors
});
  
  //export default ChangepasswordPage;
export  default connect(mapStateToProps, {})(Viewrfprfi);
