import React from 'react';
import { Link } from 'react-router-dom';
import {
  Row,
  Col,
} from 'reactstrap';

import Page from '../../../src/components/Page';

import { NumberWidget, IconWidget } from '../../../src/components/Widget';
import AppConstants from '../../AppConstants';
import { Redirect } from 'react-router';
import {ReactTitle} from 'react-meta-tags';


const today = new Date();
const lastWeek = new Date(
  today.getFullYear(),
  today.getMonth(),
  today.getDate() - 7
);

class Reports extends React.Component {
  constructor(props) {
    super(props);
    this.state = {      
      vendors_count: 0,
      customers_count: 0,
      companies_count:0
    };    
  }
  componentDidMount() {
    // this is needed, because InfiniteCalendar forces window scroll
    window.scrollTo(0, 0);
    fetch(AppConstants.API+'/reports/getalldashboardreports').then(response => response.json())
      .then(data => {
     // console.log(data);
      if(data.Status == "Success")
      {
        this.setState({ 
          vendors_count: data.vendors_count,
          customers_count: data.customers_count,
          companies_count: data.companies_count,
          rfprfi_count: data.rfprfi_count
        });
      }         
    }); 
    
    var element = document.getElementById("navItem-Companies-1");
    element.classList.remove("active");
    
    var element = document.getElementById("navItem-Certifications and Agencies-5");
    element.classList.remove("active");    
    var element = document.getElementById("navItem-NAICS/NIGP Codes-4");
    element.classList.remove("active");
    var element = document.getElementById("navItem-Opportunities-3");
    element.classList.remove("active");
    var element = document.getElementById("navItem-Vendors-2");
    element.classList.remove("active");
    var element = document.getElementById("navItem-Company Types-0");
    element.classList.remove("active");
    var element = document.getElementById("navItem-Industry Types-1");
    element.classList.remove("active");
    var element = document.getElementById("navItem-Reports-6");
    element.classList.add("active");  
  }

  render() {
    if(localStorage.jwtTokenAdmin == undefined){
      //window.location.href = '/'
  return (<Redirect to={`${AppConstants.STAGADMIN}${'/'}`} /> );
  }
  else
  {
    return (
      <Page className="DashboardPage" title="Reports">
      <ReactTitle title="Reports"/>
      <Link to={AppConstants.STAGADMIN+'/dashboard'}>
        Home
      </Link>
      &nbsp; | &nbsp;&nbsp;
      <Link to={AppConstants.STAGADMIN+'/reports'}>
        Reports
      </Link>
      <br />
      <br />
      
        <Row>
          <Col lg={4} md={6} sm={6} xs={12} className="dashboard-con">
          <Link to={AppConstants.STAGADMIN+"/vendorreport"} style={{ textDecoration: 'none' }} title="Vendors Report">
            <NumberWidget
              title="Total Vendors"
              subtitle=""
              number={this.state.vendors_count}
              color="secondary"             
            />
            </Link>
          </Col>
          <Col lg={4} md={6} sm={6} xs={12} className="dashboard-con">
          <Link to={AppConstants.STAGADMIN+"/companiesreport"} style={{ textDecoration: 'none' }} title="Companies Report">
            <NumberWidget
              title="Total Companies"
              subtitle=""
              number={this.state.customers_count}
              color="secondary"             
            />
            </Link>
          </Col>
          <Col lg={4} md={6} sm={6} xs={12} className="dashboard-con">
          <Link to={AppConstants.STAGADMIN+"/rfprfireport"} style={{ textDecoration: 'none' }} title="Opportunities Report">
            <NumberWidget
              title="Total Opportunities"
              subtitle=""
              number={this.state.rfprfi_count}
              color="secondary"             
            />
            </Link>
          </Col>      
        </Row>
      </Page>
    );
    }
  }
}
export default Reports;
