import React from 'react';
import {Row,Col,Card,CardHeader,CardBody,Form, FormGroup, Label, Input,  Button, Modal, ModalHeader,} from 'reactstrap';
import { Link } from 'react-router-dom';
import { Line, Pie, Doughnut, Bar, Radar, Polar } from 'react-chartjs-2';
import Page from '../../src/components/Page';
import { NumberWidget, IconWidget } from '../../src/components/Widget';
import AppConstants from '../AppConstants';
import { Redirect } from 'react-router';
import DatePicker from "react-datepicker";
import {ReactTitle} from 'react-meta-tags';

class DashboardPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {      
      vendors_count: 0,
      customers_count: 0,
      companies_count:0,
      data:0,
      genLineDT:{},
      placeholderSD:'MM/DD/YYYY',
      placeholderTD:'MM/DD/YYYY',
      type:'',
      start_date:null,
      end_date:null,
      startDate:'',
      endDate:'',
      type_error:'',
      type_error2:'',
      range_date:'',
      vendor_all_dates:[],
      company_all_dates:[],
      rfps_values:[],
      company_values:[],
      vendor_values:[],
      lineone_values:[],
      lineone_values1:[],
      lineone_values2:[]
      
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleStartdateChange = this.handleStartdateChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this); 
  }
  handleInputChange(e) {
      //console.log(e);
      this.setState({
          [e.target.name]: e.target.value
      });
      var Type = e.target.value;
    console.log(Type);
    console.log(this.state.start_date);
    console.log(this.state.end_date);


    this.getSDate(Type);
    if(Type == "Custom" && this.state.start_date !=null && this.state.end_date!=null) {
        this.getDPReport(Type,this.state.start_date,this.state.end_date);
        //this.getMASReport(Type,this.state.start_date,this.state.end_date);
    }
    else if(Type == "ThisWeek" || Type == "Monthly" ||  Type == "Yearly") {
      this.getDPReport(Type,this.state.start_date,this.state.end_date);
      //this.getMASReport(Type,this.state.start_date,this.state.end_date);
    }
  }
  handleStartdateChange (date) {
    //console.log('dfdsfdsfds');
    this.setState({
      start_date: date
      //end_date :date
    });
    console.log("start_date",date);
    //console.log(this.state.start_date);
    console.log(this.state.end_date);
    if(date && date!=null && this.state.end_date != null){
      this.getDPReport("Custom",date,this.state.end_date);
      //this.getMASReport("Custom",date,this.state.end_date);
      this.state.type_error='';
      this.state.type_error2='';
    }
    if(date && date==null)
    this.state.type_error = "Please select start date";
    else if(date && date!=null) {
    this.state.type_error = "";
    this.state.type = "Custom";
    }
  }
  getSDate(type) {
    let errors = {};
    let formIsValid = true;
  
    console.log(this.state.start_date);
    console.log(this.state.end_date);
        
    if(type == "Custom" && this.state.start_date == null && this.state.end_date == null){
        formIsValid = false;
        this.state.type = "Custom";
        this.state.type_error = "Please select start date";
        this.state.type_error2="Please select end date";
        this.state.placeholderSD = "MM/DD/YYYY";
        this.state.placeholderTD = "MM/DD/YYYY";
        return false;
    } else {
      this.state.type_error='';
      this.state.type_error2='';
      this.state.start_date=null;
      this.state.end_date=null;
    }
  };
  handleEndDateChange(date1) {
    this.setState({
      //start_date: date,
      end_date :date1
      
    });
    console.log("end_date",date1);
    console.log("start_date",this.state.start_date);
    console.log(date1);
    if(date1 && date1!=null && this.state.start_date != null){
      this.getDPReport("Custom",this.state.start_date,date1);
      //this.getMASReport("Custom",this.state.start_date,date1);
      this.state.type_error='';
      this.state.type_error2='';
    }
    
    if(date1 && date1==null)
    this.state.type_error2="Please select end date";
    else if(date1 && date1!=null){
    this.state.type_error2 = "";
    this.state.type = "Custom";
    }
  
    if(new Date(date1).toISOString() >= new Date(this.state.start_date).toISOString()) {
      this.getDPReport("Custom",this.state.start_date,date1);
      //this.getMASReport("Custom",this.state.start_date,date1);
    } else {
      this.state.type_error2="To date should be greater than From date";
    }    
  }
  getDPReport(Type,custom_date,end_date){
    fetch(AppConstants.API+'/reports/allvendors/'+Type+'/'+custom_date+'/'+end_date).then(response => response.json())
        .then(data => {
          if(data.status == "success")
          {
            fetch(AppConstants.API+'/reports/allcompanies/'+Type+'/'+custom_date+'/'+end_date).then(response => response.json())
                .then(data1 => {
                  if(data1.status == "success")
                    {
                      fetch(AppConstants.API+'/reports/allrfps/'+Type+'/'+custom_date+'/'+end_date).then(response => response.json())
                      .then(data2 => {
                        if(data2.status == "success")
                        {
                          var genLineDTv={
                            labels: data.vendor_all_dates,
                            datasets: [
                              {
                                label: 'Vendors',
                                backgroundColor: '#d71b61',
                                borderColor: '#d71b61',
                                borderWidth: 1,
                                data: data.vendor_values,
                                fill:false
                              },
                              {
                                label: 'Companies',
                                backgroundColor: '#8a26a7',
                                borderColor: '#8a26a7',
                                borderWidth: 1,
                                data: data1.company_values,
                                fill:false
                              },
                              {
                                label: 'Opportunities',
                                backgroundColor: '#fc8d00',
                                borderColor: '#fc8d00',
                                borderWidth: 1,
                                data: data2.rfps_values,
                                fill:false
                               }
                            ],
                          };
                          var sd = data.vendor_all_dates[0];
                          var ed = data.vendor_all_dates[data.vendor_all_dates.length-1];          
                          this.setState({ all_dates: data.vendor_all_dates,lineone_values:data.vendor_values,lineone_values1:data1.company_values,lineone_values2:data1.rfps_values,genLineDT:genLineDTv,type:Type,placeholderSD:sd,placeholderTD:ed }); 
                        }
                        else{
                          this.setState({ rfps_all_dates: [] });
                        }
                      });
                    }
                    else{
                      this.setState({ company_all_dates: [] });
                    }
                });
          }
          else
          {
            this.setState({ vendor_all_dates: [] });
          }
         });
  }
  componentDidMount() {
    // this is needed, because InfiniteCalendar forces window scroll
    window.scrollTo(0, 0);
    fetch(AppConstants.API+'/reports/getalldashboardreports').then(response => response.json())
      .then(data => {
      console.log("vendors data ", data.vendors_count);
      if(data.Status == "Success")
      {
        this.setState({ 
          vendors_count: data.vendors_count,
          customers_count: data.customers_count,
          companies_count: data.companies_count,
          rfprfi_count: data.rfprfi_count,
          //data: data
        });
      }         
    }); 
    

    var element = document.getElementById("navItem-Dashboard-0");
    element.classList.add("active");

    var element = document.getElementById("navItem-Companies-1");
    element.classList.remove("active");
    
    var element = document.getElementById("navItem-Certifications and Agencies-5");
    element.classList.remove("active");    
    var element = document.getElementById("navItem-NAICS/NIGP Codes-4");
    element.classList.remove("active");
    var element = document.getElementById("navItem-Opportunities-3");
    element.classList.remove("active");
    var element = document.getElementById("navItem-Vendors-2");
    element.classList.remove("active");
    var element = document.getElementById("navItem-Company Types-0");
    element.classList.remove("active");
    var element = document.getElementById("navItem-Industry Types-1");
    element.classList.remove("active");
    var element = document.getElementById("navItem-Reports-6");
    element.classList.remove("active"); 

    this.getDPReport("ThisWeek",null,null);

  }

  render() {
    if(localStorage.jwtTokenAdmin == undefined){
      //window.location.href = '/'
  return (<Redirect to="/login" /> );
  }
  else
  {
    return (
     
      <Page
        className="DashboardPage"
        title="Dashboard"
        >
          <ReactTitle title="Dashboard"/>
        <Row>
          <Col lg={4} md={6} sm={6} xs={12} className="dashboard-con">
          <Link to={AppConstants.STAGADMIN+"/vendorreport"} style={{ textDecoration: 'none' }} title="Vendors Report">
            <NumberWidget
              title="Total Vendors"
              subtitle=""
              number={this.state.vendors_count}
              color="secondary"              
            />
            </Link>
          </Col>
          <Col lg={4} md={6} sm={6} xs={12} className="dashboard-con">
          <Link to={AppConstants.STAGADMIN+"/companiesreport"} style={{ textDecoration: 'none' }} title="Companies Report">
            <NumberWidget
              title="Total Companies"
              subtitle=""
              number={this.state.customers_count}
              color="secondary"              
            />
            </Link>
          </Col>
          <Col lg={4} md={6} sm={6} xs={12} className="dashboard-con">
          <Link to={AppConstants.STAGADMIN+"/rfprfireport"} style={{ textDecoration: 'none' }} title="Opportunities Report">
            <NumberWidget
              title="Total Opportunities"
              subtitle=""
              number={this.state.rfprfi_count}
              color="secondary"              
            />
            </Link>
          </Col> 
         {/*  <Col lg={3} md={6} sm={6} xs={12}>
            <NumberWidget
              title="Total Company Types"
              subtitle=""
              number={this.state.companies_count}
              color="secondary"              
            />
          </Col>   */}             
        </Row>

       {/*  <Row>
        <Col >
          <Card>
            <CardHeader>All Reports</CardHeader>
            <CardBody className="cst-chart-wd" >
              <Bar  data={genLineData()} />
            </CardBody>
          </Card>
        </Col>        
      </Row> */}<br /><br/>

      <Row>
      <Col  md={12}>
          <Card className="form-block ">
           
            <CardBody>
              <div className="graph-block mt-0">
                                <div className="graph-date">
                                <Row className="justify-content-md-center ">
                                <div className="col-md-4 mb-0">
                                <Input type="select" name = "reports"  onChange={ this.handleInputChange } value={this.state.type}>
                                    <option value="ThisWeek">This Week </option>
                                    <option value="Monthly">This Month</option>
                                    <option value="Yearly">Yearly</option>
                                    <option value="Custom">Custom</option>
                                </Input>
                                </div>
                                <div className="form-field  col-md-4">
                                                            
                                    <div className="graph-field">
                                    <DatePicker
                                        name="start_date"
                                        className="form-control"
                                        //placeholderText="MM-DD-YYYY"
                                        placeholderText={this.state.placeholderSD}
                                        //placeholderText="02/09/2019"
                                        selected={this.state.start_date}
                                        selectsStart
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        startDate={this.state.startDate}
                                        endDate={this.state.endDate}
                                        onChange={this.handleStartdateChange}
                                        autoComplete = "off"
                                        minDate={new Date().setFullYear(new Date().getFullYear() - 5)}   
                                        maxDate={new Date()}
                                    />
                                    <i class="fa fa-calendar" aria-hidden="true"></i>
                                    <span className="error" style={{color: 'red'}}>{this.state.type_error}</span>
                                    </div>
                                </div> 
                                <div className="form-field  col-md-4">
                                    <div className="graph-field">
                                    <DatePicker
                                            name="end_date"
                                            className="form-control"
                                            //placeholderText="MM-DD-YYYY"
                                            placeholderText={this.state.placeholderTD}                    
                                            selected={this.state.end_date}
                                            selectsEnd
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            //startDate={this.state.startDate}
                                            //
                                            endDate={this.state.endDate}
                                            onChange={this.handleEndDateChange}
                                            autoComplete = "off"

                                            minDate={new Date().setFullYear(new Date().getFullYear() - 5)}   
                                            maxDate={new Date()}
                                    />
                                    <i class="fa fa-calendar" aria-hidden="true"></i>
                                    <span className="error" style={{color: 'red'}}>{this.state.type_error2}</span>
                                    </div>
                                </div>
                                {/* <span className="error">{this.state.errors["range_date"]}</span> */}
                                </Row>
                                </div>                               
                            </div>
              <div className="graph-canvas">

              {/* <Line data={genLineData({ fill: false }, { fill: false })} /> */}
              <Line data={this.state.genLineDT} width={100} height={50} />
              
              </div>
            </CardBody>
          </Card>
        </Col>

       
      </Row>

      
      </Page>
    );
    }
  }
}
export default DashboardPage;
