import Page from '../../../src/components/Page';
import React from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import { BootstrapTable, TableHeaderColumn, InsertButton } from 'react-bootstrap-table';
import {Card,CardBody,Input } from 'reactstrap';
import AppConstants from '../../AppConstants';
import {ReactTitle} from 'react-meta-tags';


class Favvendors extends React.Component {
  constructor(props) {
      super(props);
      this.state = {      
        favvendorreportdata: [],
        errors: {},
        errMsg:false,
        companyname:''
    };
  }

  componentDidMount() {
    fetch(AppConstants.API+'/reports/favvendorreport/'+this.props.match.params.id).then(response => response.json())
      .then(data => {
     //console.log(data);
      if(data.Status == "Success")
      {
        this.setState({ favvendorreportdata: data.favvendorreportdata,companyname:data.companyname});
      }
      else
      {
        this.setState({ favvendorreportdata: [],companyname:data.companyname});
      }    
    });


    var element = document.getElementById("navItem-Companies-1");
    element.classList.remove("active");
    var element = document.getElementById("navItem-Certifications and Agencies-5");
    element.classList.remove("active");    
    var element = document.getElementById("navItem-NAICS/NIGP Codes-4");
    element.classList.remove("active");
    var element = document.getElementById("navItem-Opportunities-3");
    element.classList.remove("active");
    var element = document.getElementById("navItem-Vendors-2");
    element.classList.remove("active");
    var element = document.getElementById("navItem-Company Types-0");
    element.classList.remove("active");
    var element = document.getElementById("navItem-Industry Types-1");
    element.classList.remove("active");
    var element = document.getElementById("navItem-Reports-6");
    element.classList.add("active"); 
  }

  companyAddress(cell,row){
    return  <div className="contact-info">
        <span className="location">{row.vendor_company_address}, <br />
        {row.state}, {row.city} {row.zipcode} </span>
        {(row.vendor_company_phoneno && row.vendor_company_phoneno !='') ? (<span className="phone-no"> {row.vendor_company_phoneno}</span>) : ''}
      {(row.vendor_company_website && row.vendor_company_website !='') ? ( <span className="website"><a href={row.vendor_company_website} target="_blank" >{row.vendor_company_website}</a></span>) : ''}
        </div> ; 
    }
  
   render() {
    if(localStorage.jwtTokenAdmin != undefined)
    {
    const options = {
      exportCSVText: 'Export CSV',    
      insertText: "New",    
      deleteText: 'Delete',    
      saveText: 'Save',    
      closeText: 'Close',
      page: 1,  // which page you want to show as default
      sizePerPageList: [ {
        text: '5', value: 5
      }, {
        text: '10', value: 10
      }, {
        text: '20', value: 20
      },
      {
        text: '50', value: 50
      }
    ], // you can change the dropdown list for size per page
      sizePerPage: 10,  // which size per page you want to locate as default
      pageStartIndex: 1, // where to start counting the pages
      paginationSize: 3,  // the pagination bar size.
     // prePage: 'Prev', // Previous page button text
      //nextPage: 'Next', // Next page button text
      //firstPage: 'First', // First page button text
      //lastPage: 'Last', // Last page button text
      paginationShowsTotal: this.state.favvendorreportdata.length,  // Accept bool or function
      paginationPosition: 'bottom',  // default is bottom, top and both is all available
      // hideSizePerPage: true > You can hide the dropdown for sizePerPage
      // alwaysShowAllBtns: true // Always show next and previous button
      // withFirstAndLast: false > Hide the going to First and Last page button
      insertModal: this.createCustomModal    
    };
    
    return (
        <Page title={`${this.state.companyname}` + " - Favorite Vendors"}>
        <ReactTitle title="Favorite Vendors"/>
        <Link to={AppConstants.STAGADMIN+'/dashboard'}>
          Home
          </Link>
          &nbsp; | &nbsp;&nbsp;
          <Link to={AppConstants.STAGADMIN+'/reports'}>
          Reports
          </Link>
          &nbsp; | &nbsp;&nbsp;
          <Link to={AppConstants.STAGADMIN+'/companiesreport'}>
          Company Report
          </Link>
            <row>
              <Card  className="data-table">
                <CardBody>
                  <div>
                  <span className="error" style={{color: 'red'}}>{this.state.errMsg}</span>

                  <BootstrapTable data={ this.state.favvendorreportdata } search={ true } pagination={ true } multiColumnSearch={ true } options={ options }
                  >
                    <TableHeaderColumn dataField='_id' isKey={ true } searchable={ false } dataSort={ true } hidden={true}>ID</TableHeaderColumn> 

                    <TableHeaderColumn dataField='vendor_company_name' dataSort={ true }>Name</TableHeaderColumn>

                    <TableHeaderColumn dataField='vendor_company_email' dataSort={ true }>Email</TableHeaderColumn>
                    
                    <TableHeaderColumn dataField='vendor_company_address' dataFormat={this.companyAddress.bind(this)} dataSort={ true }>Company Address</TableHeaderColumn>
                  </BootstrapTable>
                </div>
                </CardBody>
              </Card>
            </row>
          </Page>
      );
    }
    else 
    {
        window.location.href = AppConstants.STAGADMIN+'/'
    }
  }

};

export default Favvendors;