import React from "react";
import {Link} from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  FormFeedback,
} from "reactstrap";
import Page from "../Page";
import axios from "axios";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import AppConstants from "../../AppConstants";
import Loading from "../../assets/img/loader.gif";
import {ReactTitle} from "react-meta-tags";
import ReactDOM from "react-dom";

class EditCertificate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      _id: this.props.auth.user._id,
      id: this.props.match.params.id,
      status: "",
      errors: {},
      errMsg: "",
      loading: "",
      partnerImage: null,
      view_badge: "",
      prev_badge: "",
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleInputFileChange = this.handleInputFileChange.bind(this);
  }

  handleInputChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
    //console.log(this.state);
  }

  handleInputFileChange(e) {
    const inputName = e.target.name;

    if (!e.target.files || e.target.files.length === 0) {
      console.log("No files selected.");
      this.setState({
        [inputName]: null, // Clear the selected file
      });
      return;
    }

    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      const img = new Image();
      img.src = event.target.result;

      img.onload = () => {
        const width = img.width;
        const height = img.height;
        console.log(`Image width: ${width}px, height: ${height}px`);

        if (width !== 225 || height !== 143) {
          alert("Width must be 225px and Height must be 143px.");
          this.setState({
            [inputName]: null, // Clear the selected file
          });
        } else {
          console.log(file);
          this.setState({
            [inputName]: file,
          });
        }
      };
    };

    reader.readAsDataURL(file);
  }

  /** Validations */
  handleValidation() {
    let errors = {};
    let formIsValid = true;

    if (!this.state.partnerImage && this.state.prev_badge == "") {
      formIsValid = false;
      errors["badge_error"] = "Please upload valid partner image";
    }
    if (
      this.state.badge &&
      this.state.badge.name &&
      !this.state.badge.name.match(/.(jpg|jpeg|png)$/i)
    ) {
      formIsValid = false;
      errors["badge_error"] =
        "Please select valid badge (Ex: jpg | jpeg | png )";
    }

    this.setState({errors: errors});
    return formIsValid;
  }

  handleSubmit = (event) => {
    event.preventDefault();
    if (this.handleValidation()) {
      this.setState({loading: 1});

      let formData = new FormData();
      formData.append("id", this.props.match.params.id);
      formData.append("status", this.state.status);
      formData.append("partnerImage", this.state.partnerImage);
      formData.append("prev_badge", this.state.prev_badge);

      axios
        .post(AppConstants.API + "/certifications/editPartner", formData)
        .then((res) => {
          console.log(res);
          if (res.data.Status == "Success") {
            setTimeout((e) => {
              this.props.history.push(AppConstants.STAGADMIN + "/partners");
            }, 2000);
            this.setState({
              //errMsg: res.data.msg
              errMsg: (
                <div className="alert alert-success fade show mt-3">
                  {res.data.msg}
                </div>
              ),
            });
          } else {
            this.setState({loading: ""});
            setTimeout((e) => {
              this.setState({
                errMsg: "",
              });
            }, 2000);
            this.setState({
              //errMsg: res.data.msg
              errMsg: (
                <div className="alert alert-danger fade show mt-3">
                  {res.data.msg}
                </div>
              ),
            });
          }
        });
    }
  };
  componentDidMount() {
    //console.log(this.props.match.params.id);
    const that = this;
    fetch(
      AppConstants.API +
        "/certifications/getPartnerDetails/" +
        this.props.match.params.id
    )
      .then(function (res) {
        //console.log(res.status);
        if (res.status == 200) {
          return res.json();
        }
      })
      .then(function (data) {
        //console.log(data);
        if (data.Status == "Success") {
          that.setState({
            status: data.data.status,
            partnerImage: data.data.partnerImage,
            prev_badge: data.data.partnerImage,
            view_badge:
              data.data.partnerImage || "/static/media/no-profile-pic.jpg",
          });
        } else {
          console.log("invalid data");
          this.setState({
            errMsg: data.Status,
          });
        }
      });
    var element = document.getElementById("navItem-Partners-2");
    element.classList.add("active");
  }
  render() {
    const {user} = this.props.auth;
    return (
      <Page title="Edit Partner">
        <ReactTitle title="Edit Partner" />
        <Link to={AppConstants.STAGADMIN + "/dashboard"}>Home</Link>
        &nbsp; | &nbsp;&nbsp;
        <Link to={AppConstants.STAGADMIN + "/partners"}>Partners</Link>
        <Form
          className="form-block"
          name="changeadminpassword"
          onSubmit={this.handleSubmit}
        >
          <Row>
            <Col xl={12} lg={12} md={12}>
              <Card>
                <CardBody>
                  <Row>
                    <Col xl={6} lg={6} md={6}>
                      <FormGroup>
                        <Label for="exampleFile" style={{display: "block"}}>
                          Image{" "}
                          <span className="error" style={{color: "red"}}>
                            *
                          </span>
                        </Label>
                        <img src={this.state.view_badge} style={{width: 100}} />
                        <br />
                        <br />

                        <div class="file-field input-field fileupload">
                          <div class="filebtn">
                            <span>
                              <i class="fa fa-upload" aria-hidden="true"></i>
                            </span>
                            <input
                              type="file"
                              name="partnerImage"
                              onChange={this.handleInputFileChange}
                            />
                          </div>

                          <div class="file-path-wrapper">
                            <input
                              class="file-path validate"
                              type="text"
                              placeholder=" Please upload partner image."
                            />
                          </div>
                        </div>
                        <span className="error" style={{color: "red"}}>
                          {this.state.errors["badge_error"]}
                        </span>
                        <br />
                        <span style={{color: "red"}}>Note: &nbsp; </span>
                        Width must be 225px and Height must be 143px.
                      </FormGroup>
                    </Col>
                  </Row>

                  <FormGroup>
                    <Label for="exampleSelect">Status</Label>

                    <Input
                      type="select"
                      name="status"
                      onChange={this.handleInputChange}
                      className="col-md-6"
                      value={this.state.status}
                    >
                      <option
                        value="Active"
                        selected="{{this.state.status === 
                     this.value}}"
                      >
                        Active
                      </option>
                      <option
                        value="In Active"
                        selected="{{this.state.status === 
                      this.value}}"
                      >
                        In Active
                      </option>
                    </Input>
                  </FormGroup>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md={12} className="form-btn">
              <Button
                class="btn btn-primary addbtn"
                className="bg-gradient-theme-left border-0"
                onClick={this.handleSubmit}
                title="Update Partner"
              >
                Update
                <div
                  style={this.state.loading ? {} : {display: "none"}}
                  class="image-fill w-25 loader-login-div "
                >
                  <img src={Loading} alt="No Image" className="can-click " />
                </div>
              </Button>
              &nbsp;&nbsp;
              <Link
                to={AppConstants.STAGADMIN + "/partners"}
                class="btn btn-primary redbtn"
                title="Cancel Partner"
              >
                Cancel
              </Link>
            </Col>
          </Row>
          {this.state.errMsg}
        </Form>
      </Page>
    );
  }
}

EditCertificate.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

//export default ChangepasswordPage;
export default connect(mapStateToProps, {})(EditCertificate);
