import Page from '../../../src/components/Page';
import React from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import { BootstrapTable, TableHeaderColumn, InsertButton } from 'react-bootstrap-table';
import {Card,CardBody,Input } from 'reactstrap';
import AppConstants from '../../AppConstants';
import {ReactTitle} from 'react-meta-tags';


class Postedopportunities extends React.Component {
  constructor(props) {
      super(props);
      this.state = {      
        postedopportunitiesdata: [],
        errors: {},
        errMsg:false,
        companyname:''
    };
  }

  componentDidMount() {
    fetch(AppConstants.API+'/reports/postedopportunities/'+this.props.match.params.id).then(response => response.json())
      .then(data => {
     //console.log(data);
      if(data.Status == "Success")
      {
        this.setState({ postedopportunitiesdata: data.postedopportunitiesdata,companyname:data.companyname});
      }
      else
      {
        this.setState({ postedopportunitiesdata: [],companyname:data.companyname});
      }    
    });


    var element = document.getElementById("navItem-Companies-1");
    element.classList.remove("active");
    var element = document.getElementById("navItem-Certifications and Agencies-5");
    element.classList.remove("active");    
    var element = document.getElementById("navItem-NAICS/NIGP Codes-4");
    element.classList.remove("active");
    var element = document.getElementById("navItem-Opportunities-3");
    element.classList.remove("active");
    var element = document.getElementById("navItem-Vendors-2");
    element.classList.remove("active");
    var element = document.getElementById("navItem-Company Types-0");
    element.classList.remove("active");
    var element = document.getElementById("navItem-Industry Types-1");
    element.classList.remove("active");
    var element = document.getElementById("navItem-Reports-6");
    element.classList.add("active"); 
  }

  dateFormat(cell,row) {
    if(row.rfp_post_date && row.rfp_post_date !=''){
      var posteddate = row.rfp_post_date;
      var posteddate= new Date(posteddate);             
      return   ("0" + (posteddate.getMonth() + 1)).slice(-2) + "-" + (posteddate.getDate() < 10 ? '0' : '')+ posteddate.getDate() + "-" +  posteddate.getFullYear();      
      }
      else
      {
        return   <div>N/A</div>;
        //return date.getFullYear() + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-" + date.getDate() + " " + strTime;
      }
    }
    duedateFormat(cell,row){
        var duedate = row.due_dateandtime;
        var duedate= new Date(duedate);
        var hours = duedate.getHours();
        var minutes = duedate.getMinutes();
        var ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0'+minutes : minutes;
        var strTime = hours + ':' + minutes + ' ' + ampm;
        return  ("0" + (duedate.getMonth() + 1)).slice(-2) + "-" + (duedate.getDate() < 10 ? '0' : '')+ duedate.getDate() + "-" + duedate.getFullYear() + " " + strTime;
    }
  
   render() {
    if(localStorage.jwtTokenAdmin != undefined)
    {
    const options = {
      exportCSVText: 'Export CSV',    
      insertText: "New",    
      deleteText: 'Delete',    
      saveText: 'Save',    
      closeText: 'Close',
      page: 1,  // which page you want to show as default
      sizePerPageList: [ {
        text: '5', value: 5
      }, {
        text: '10', value: 10
      }, {
        text: '20', value: 20
      },
      {
        text: '50', value: 50
      }
    ], // you can change the dropdown list for size per page
      sizePerPage: 10,  // which size per page you want to locate as default
      pageStartIndex: 1, // where to start counting the pages
      paginationSize: 3,  // the pagination bar size.
     // prePage: 'Prev', // Previous page button text
      //nextPage: 'Next', // Next page button text
      //firstPage: 'First', // First page button text
      //lastPage: 'Last', // Last page button text
      paginationShowsTotal: this.state.postedopportunitiesdata.length,  // Accept bool or function
      paginationPosition: 'bottom',  // default is bottom, top and both is all available
      // hideSizePerPage: true > You can hide the dropdown for sizePerPage
      // alwaysShowAllBtns: true // Always show next and previous button
      // withFirstAndLast: false > Hide the going to First and Last page button
      insertModal: this.createCustomModal    
    };
    
    return (
        <Page title={`${this.state.companyname}` + " - Posted Opportunities"}>
        <ReactTitle title="Posted Opportunities"/>
        <Link to={AppConstants.STAGADMIN+'/dashboard'}>
          Home
          </Link>
          &nbsp; | &nbsp;&nbsp;
          <Link to={AppConstants.STAGADMIN+'/reports'}>
          Reports
          </Link>
          &nbsp; | &nbsp;&nbsp;
          <Link to={AppConstants.STAGADMIN+'/companiesreport'}>
          Company Report
          </Link>
            <row>
              <Card  className="data-table">
                <CardBody>
                  <div>
                  <span className="error" style={{color: 'red'}}>{this.state.errMsg}</span>

                  <BootstrapTable data={ this.state.postedopportunitiesdata } search={ true } pagination={ true } multiColumnSearch={ true } options={ options }
                  >
                    <TableHeaderColumn dataField='_id' isKey={ true } searchable={ false } dataSort={ true } hidden={true}>ID</TableHeaderColumn> 

                    <TableHeaderColumn dataField='name_ofrfp' dataSort={ true }>Name Of Opportunity</TableHeaderColumn>

                    <TableHeaderColumn dataField='rfp_post_date' dataFormat={this.dateFormat.bind(this)} dataSort={ true }>Posted Date</TableHeaderColumn>
                    
                    <TableHeaderColumn dataField='due_dateandtime' dataFormat={this.duedateFormat.bind(this)} dataSort={ true }>Due Date & Time</TableHeaderColumn>
                  </BootstrapTable>
                </div>
                </CardBody>
              </Card>
            </row>
          </Page>
      );
    }
    else 
    {
        window.location.href = AppConstants.STAGADMIN+'/'
    }
  }

};

export default Postedopportunities;