import Page from '../../../src/components/Page';
import React from 'react';
import { Link } from 'react-router-dom';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import { BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import { Card, CardBody } from 'reactstrap';
import AppConstants from '../../AppConstants';
import {ReactTitle} from 'react-meta-tags';
import Workbook from 'react-xlsx-workbook';

class Companiesreport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {      
      customersdata: [],
      errors: {},
      errMsg:false
  };
}

// getImage(cell, row){
//   if(row.company_logo)
//   return <div className="vendor-image"><img  src={row.company_logo} alt={row.company_logo} className="img-responsive"/></div>;
//   else
//   return <div className="vendor-image"><img  src={nologo} alt={row.company_logo} className="img-responsive"/></div>;
//   }

  getCompanydetails(cell, row){
    return <div><Link to={AppConstants.STAGADMIN+"/viewcustomer/"+row._id} style={{color:"#212529"}} title="View Company Details">{row.company_name}</Link></div>;
  }

  getPostedrfps(cell, row){
    if(row.rfprficount > 0){
      return <div><Link  to={AppConstants.STAGADMIN+"/postedopportunities/"+row._id}  title="Posted Opportunities">{row.rfprficount}</Link></div>;
    }
    else
    {
      return <div>{row.rfprficount}</div>;
    }
  }

  favvendors(cell, row){
    if(row.vendorfavcoun > 0){
      return <div><Link  to={AppConstants.STAGADMIN+"/favvendors/"+row._id}  title="Favorite Vendors">{row.vendorfavcoun}</Link></div>;
    }
    else
    {
      return <div>{row.vendorfavcoun}</div>;
    }
  }

  companyrfplikedvendors(cell, row){
    if(row.likedrfpscount > 0){
      return <div><Link  to={AppConstants.STAGADMIN+"/rfplikedvendors/"+row._id}  title="Liked Opportunities">{row.likedrfpscount}</Link></div>;
    }
    else
    {
      return <div>{row.likedrfpscount}</div>;
    }
  }

  vendorappliedrfps(cell, row){
    if(row.appliedcompanyrfpscount > 0){
      return <div><Link  to={AppConstants.STAGADMIN+"/vendorappliedrfps/"+row._id}  title="Applied Opportunities">{row.appliedcompanyrfpscount}</Link></div>;
    }
    else
    {
      return <div>{row.appliedcompanyrfpscount}</div>;
    }
  }


  
  
  componentDidMount() {
    fetch(AppConstants.API+'/reports/getallcompanyreport').then(response => response.json())
      .then(data => {
     //console.log(data);
      if(data.Status == "Success")
      {
        this.setState({ customersdata: data.customersdata });
      }
      else
      {
        this.setState({ customersdata: []});
      }    
    });

    var element = document.getElementById("navItem-Companies-1");
    element.classList.remove("active");
    
    var element = document.getElementById("navItem-Certifications and Agencies-5");
    element.classList.remove("active");    
    var element = document.getElementById("navItem-NAICS/NIGP Codes-4");
    element.classList.remove("active");
    var element = document.getElementById("navItem-Opportunities-3");
    element.classList.remove("active");
    var element = document.getElementById("navItem-Vendors-2");
    element.classList.remove("active");
    var element = document.getElementById("navItem-Company Types-0");
    element.classList.remove("active");
    var element = document.getElementById("navItem-Industry Types-1");
    element.classList.remove("active");
    var element = document.getElementById("navItem-Reports-6");
    element.classList.add("active");  
  }

  createCustomInsertButton=(click)=>{
    return(<span>{this.state.customersdata.length > 0 ?
        <Workbook title="Export Company" filename="Export Company.xlsx" element={<span className="btn btn-secondary editbtn" title="Export Company">Export</span>}>
        <Workbook.Sheet data={this.state.customersdata} name="Sheet A">
            <Workbook.Column label="Company Name" value="company_name"/>
            <Workbook.Column label="Posted Opportunities" value="rfprficount"/>
            <Workbook.Column label="Favorite Vendors" value="vendorfavcoun"/>
            <Workbook.Column label="Liked Opportunities" value="likedrfpscount"/>
            <Workbook.Column label="Applied Opportunities" value="appliedcompanyrfpscount"/>
        </Workbook.Sheet>
        </Workbook> :""}</span>)
}
  
   render() {
    if(localStorage.jwtTokenAdmin != undefined)
    {
    const options = {
      exportCSVText: 'Export CSV',    
      insertText: "New",    
      deleteText: 'Delete',    
      saveText: 'Save',    
      closeText: 'Close',
      insertBtn: this.createCustomInsertButton,
      page: 1,  // which page you want to show as default
      sizePerPageList: [ {
        text: '5', value: 5
      }, {
        text: '10', value: 10
      }, {
        text: '20', value: 20
      },
      {
        text: '50', value: 50
      }
    ], // you can change the dropdown list for size per page
      sizePerPage: 10,  // which size per page you want to locate as default
      pageStartIndex: 1, // where to start counting the pages
      paginationSize: 3,  // the pagination bar size.
     // prePage: 'Prev', // Previous page button text
      //nextPage: 'Next', // Next page button text
      //firstPage: 'First', // First page button text
      //lastPage: 'Last', // Last page button text
      paginationShowsTotal: this.state.customersdata.length,  // Accept bool or function
      paginationPosition: 'bottom',  // default is bottom, top and both is all available
      // hideSizePerPage: true > You can hide the dropdown for sizePerPage
      // alwaysShowAllBtns: true // Always show next and previous button
      // withFirstAndLast: false > Hide the going to First and Last page button
      insertModal: this.createCustomModal
    };
    
    return (
        <Page title="Company Report">
        <ReactTitle title="Companies Report"/>
        <Link to={AppConstants.STAGADMIN+'/dashboard'}>
          Home
          </Link>
          &nbsp; | &nbsp;&nbsp;
          <Link to={AppConstants.STAGADMIN+'/reports'}>
          Reports
          </Link>
            <row>
              <Card className="data-table">
                <CardBody>
                  <div>
                  <span className="error" style={{color: 'red'}}>{this.state.errMsg}</span>
                  <BootstrapTable data={ this.state.customersdata } search={ true } pagination={ true } multiColumnSearch={ true } options={ options } insertRow
                  >
                    <TableHeaderColumn dataField='_id' isKey={ true } searchable={ false } dataSort={ true } hidden={true}>ID</TableHeaderColumn>
                    {/* <TableHeaderColumn dataField='company_logo' dataFormat={this.getImage.bind(this)} dataSort={ true }>Company Logo</TableHeaderColumn> */}
                    <TableHeaderColumn dataField='company_name' dataFormat={this.getCompanydetails.bind(this)}  dataSort={ true }>Company Name</TableHeaderColumn>
                    <TableHeaderColumn dataField='rfprficount'  dataFormat={this.getPostedrfps.bind(this)}>Posted Opportunities</TableHeaderColumn>
                    <TableHeaderColumn dataField='vendorfavcoun' dataFormat={this.favvendors.bind(this)}  >Favorite Vendors</TableHeaderColumn>
                    <TableHeaderColumn dataField='likedrfpscount' dataFormat={this.companyrfplikedvendors.bind(this)}>Liked Opportunities</TableHeaderColumn>
                    <TableHeaderColumn dataField='appliedcompanyrfpscount'  dataFormat={this.vendorappliedrfps.bind(this)}>Applied Opportunities</TableHeaderColumn>
                  </BootstrapTable>
                </div>
                </CardBody>
              </Card>
            </row>
          </Page>
      );
    }
    else 
    {
        window.location.href = AppConstants.STAGADMIN+'/'
    }
  }

};

export default Companiesreport;