import React from 'react';
import { Link } from 'react-router-dom';
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  FormFeedback,
} from 'reactstrap';
import Page from '../../../src/components/Page';
import axios from 'axios';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import AppConstants from '../../AppConstants';
import CKEditor from "react-ckeditor-component";
import Loading from '../../assets/img/loader.gif';
import {ReactTitle} from 'react-meta-tags';
import ReactDOM from 'react-dom';

//import CKEditor from 'ckeditor4-react';

class EditCms extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      _id:this.props.auth.user._id,
      id:this.props.match.params.id,
      title:'',
      //description:'',  
      content: '',     
      errors: {},
      errMsg:'',
      loading: '',
      partnerImage:null,
      view_badge:'',
      prev_badge:'',

    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this); 
    this.updateContent = this.updateContent.bind(this); 
    this.onChange = this.onChange.bind(this); 
    this.handleInputFileChange=this.handleInputFileChange.bind(this);
  }

  handleInputFileChange(e) {
    this.setState({
    [e.target.name]: e.target.files[0]
    });
  }
  
  handleInputChange(e) {
    this.setState({
        [e.target.name]: e.target.value
    });
    //console.log(this.state);
  }

  updateContent(newContent) {
    this.setState({
        content: newContent
    })
  }

  onChange(evt){
    console.log("onChange fired with event info: ", evt);
    var newContent = evt.editor.getData();
    this.setState({
      content: newContent
    })
  }
  
  /** Validations */
  handleValidation(){
    let errors = {};
    let formIsValid = true;
    if(!this.state.title){
      formIsValid = false;
      errors["title"] = "Please enter title";
      ReactDOM.findDOMNode(this.refs.title).focus();
    }
    if(!this.state.content){
      formIsValid = false;
      errors["content"] = "Please enter description";
    }

    if(!this.state.partnerImage && this.state.prev_badge==''){
      
      formIsValid = false;
      errors["badge_error"] = "Please upload valid image";
    }
    if(this.state.badge && this.state.badge.name && !this.state.badge.name.match(/.(jpg|jpeg|png)$/i)){
      formIsValid = false;
      errors["badge_error"] = "Please select valid image (Ex: jpg | jpeg | png )";
    }


    this.setState({errors: errors});
    return formIsValid;
  }

  handleSubmit = event => {
    event.preventDefault();
    //alert('form submitted');    
     if(this.handleValidation()){
      this.setState({loading:1});   
      // const companiesdata = {
      //   id:this.props.match.params.id,
      //   title: this.state.title,
      //   description: this.state.content
      // }

      let formData = new FormData();
      formData.append("partnerImage", this.state.partnerImage);
      formData.append('prev_badge', this.state.prev_badge);
      formData.append("id", this.props.match.params.id);
      formData.append("title", this.state.title);
      formData.append("description", this.state.content);


      axios.post(AppConstants.API+'/cms/editcms',formData)
        .then(res => {   
          //console.log(res);   
         if(res.data.Status == "Success"){
          setTimeout((e) => {           
            this.props.history.push(AppConstants.STAGADMIN+'/cms');
          }, 2000)               
          this.setState({
            //errMsg: res.data.msg
            errMsg: (<div className="alert alert-success fade show mt-3">{res.data.msg}</div>)
          });
        }
        else{
          this.setState({loading:''});
          setTimeout((e) => {
            this.setState({
              errMsg: ''
            });
           },2000);   
          this.setState({
            //errMsg: res.data.msg
            errMsg: (<div className="alert alert-danger fade show mt-3">{res.data.msg}</div>)
          });
        }
      });
    } 
  };
  componentDidMount() {
    //console.log(this.props.match.params.id);
    const that = this; 
    fetch(AppConstants.API+'/cms/getcmsdetails/'+this.props.match.params.id)
    .then(function(res) {
      //console.log(res.status);
      if(res.status == 200){
        return res.json();
      }
    })
    .then(function(data) {
      //console.log(data);
      if(data.Status == "Success"){
        that.setState({
          title:data.getcmsdetails.title,
          content:data.getcmsdetails.description,
          partnerImage:data.getcmsdetails.image,
          prev_badge:data.getcmsdetails.image,
          view_badge:data.getcmsdetails.image || '/static/media/no-profile-pic.jpg',
        });
      }else
      {
        console.log('invalid data');
        this.setState({
          errMsg: data.Status
        });
      }
    });
    var element = document.getElementById("navItem-CMS-7");
    element.classList.add("active");
    
  }
  render() {
  const {user} = this.props.auth;
  return (
    <Page title="Edit CMS">
     <ReactTitle title="Edit CMS"/>
      <Link to={AppConstants.STAGADMIN+'/dashboard'}>
      Home
      </Link>
      &nbsp; | &nbsp;&nbsp;
      <Link to={AppConstants.STAGADMIN+'/cms'}>
      CMS
      </Link>
      <Form className="form-block"name="changeadminpassword" onSubmit= { this.handleSubmit }>
      <Row>
        <Col xl={12} lg={12} md={12}>
          <Card>
            <CardBody>
                <FormGroup>
                  <Label for="examplePassword" >
                    Title <span className="error" style={{color: 'red'}}>*</span>
                  </Label>
                  
                  <Input
                      type="text"
                      name="title"
                      placeholder="Title"
                      value={this.state.title}
                      onChange={ this.handleInputChange } ref ="title" className="col-md-6"                 
                    />
                    <span className="error" style={{color: 'red'}}>{this.state.errors["title"]}</span>
                  
                </FormGroup> 
                <FormGroup>
                  <Label for="examplePassword">
                  Description <span className="error" style={{color: 'red'}}>*</span>
                  </Label>
                  
                    {/* <Input
                      type="text"
                      name="description"
                      placeholder="Description"
                      value={this.state.description}
                      onChange={ this.handleInputChange }                  
                    /> */}
                     {/* <CKEditor 
                      activeClass="p10" 
                      content={this.state.content}                      
                      events={{                        
                        "change": this.onChange
                      }}
                    /> */}

                    <CKEditor
                      activeClass="p10" 
                      content={this.state.content}
                      events={{                        
                        "change": this.onChange
                      }}
                  />
                    <span className="error" style={{color: 'red'}}>{this.state.errors["content"]}</span>
                  
                </FormGroup>    


                <Row>
                    <Col xl={6} lg={6} md={6}>

                <FormGroup>
                  <Label for="exampleFile" style={{display: 'block'}}>
                  Image <span className="error" style={{color: 'red'}}>*</span>
                  </Label>
                  <img src={this.state.view_badge} style={{width: 100}} /><br /><br />

                    <div class = "file-field input-field fileupload">
                    <div class = "filebtn">
                        <span><i class="fa fa-upload" aria-hidden="true"></i></span>
                        <input type = "file" name="partnerImage" onChange={ this.handleInputFileChange} />
                    </div>
                                                            
                    <div class = "file-path-wrapper">
                        <input class = "file-path validate" type = "text"
                            placeholder = " Please upload image." />
                    </div>
                </div> 
                    <span className="error" style={{color: 'red'}}>{this.state.errors["badge_error"]}</span>
                
                </FormGroup>
                </Col>
                  </Row>          
                
                
              
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
      <Col md={12} className="form-btn">
        <Button class="btn btn-primary addbtn" className="bg-gradient-theme-left border-0" onClick={ this.handleSubmit } title="Update CMS">Update
          <div style={this.state.loading ? {} : { display: 'none' }} class="image-fill w-25 loader-login-div "><img src={Loading} alt="No Image" className="can-click " /></div>
        </Button>&nbsp;&nbsp;
        <Link to={AppConstants.STAGADMIN+"/cms"} class="btn btn-primary redbtn" title="Cancel CMS">Cancel</Link>
      </Col>
                
      </Row>
      {this.state.errMsg}  
      </Form>
     
    </Page>
  );
};
}

EditCms.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors
});

//export default ChangepasswordPage;
export  default connect(mapStateToProps, {})(EditCms);