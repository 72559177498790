import React from "react";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  FormFeedback,
} from "reactstrap";
import Select from "react-select";
import Page from "../../../src/components/Page";
import axios from "axios";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import MomentLocaleUtils, { formatDate } from "react-day-picker/moment";
import AppConstants from "../../AppConstants";
import NumberFormat from "react-number-format";
import { Typeahead } from "react-bootstrap-typeahead";
import Loading from "../../assets/img/loader.gif";
import { ReactTitle } from "react-meta-tags";
import ReactDOM from "react-dom";

var certification_newids = [];
var filedata = [];

var errors1 = {};
var formIsValid1 = true;
var isFile = "";

class Addvendor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      _id: this.props.auth.user._id,
      vendor_company_name: "",
      vendor_company_email: "",
      vendor_company_password: "",
      vendor_company_type: "",
      vendor_otp: "",
      vendor_otp_status: "",
      vendor_company_industrey: "",
      vendor_company_address: "",
      vendor_company_address2: "",
      vendor_company_phoneno: "",
      vendor_company_website: "",
      vendor_primary_contact_firstname: "",
      vendor_primary_contact_lastname: "",
      vendor_primary_contact_middlename: "",
      vendor_primary_contact_title: "",
      vendor_primary_contact_phoneno: "",
      vendor_primary_email: "",
      vendor_primary_cellno: "",
      vendor_primary_officeno: "",
      vendor_primary_department: "",
      certificationsandmembership: "",
      naic_code: [],
      descriptionofproductsandservices: "",
      companyleadership: "",
      department_min_revenue: "",
      department_max_revenue: "",
      employees: "",
      locationsOfOffices: [],
      latitude: "",
      longitude: "",
      country: "",
      state: "",
      city: "",
      zipcode: "",
      companyLogo: "",
      uploadVideo: "",
      instagramLink: "",
      facebookLink: "",
      linkedInLink: "",
      youTubeLik: "",
      twitterLink: "",
      businessStartedDate: new Date(),
      accolades: "",
      media: "",
      status: "Active",
      deleted: 0,
      created_by: this.props.auth.user._id,
      revanuerange: "",
      errors: {},
      errMsg: "",
      companiesdata: [],
      naiccodes: [],
      countries: [],
      states: [],
      cities: [],
      multiplevalues: [],
      multipleaccolades: [],
      industriesdata: [],
      certifications: [],
      certificationsdata: [],
      leadershiptitle: "",
      leadershipemail: "",
      certificationfiles: [],
      checked: false,
      checked1: false,
      certification_name: false,
      membership: "",
      multiplemembership: [],
      //selectedDay: undefined,,
      loading: "",
      vendor_business_model: "",
      vendorid: null,
      certificationid: [],
      certify: "",
      vendor_company_website: "",
      errors1: {},
      muslim_owned_business: "No",
      memphis_chamber_mmember: "No",
      subindustriesdata: [],
      subindustries: [],
      years_in_business:'',
      no_of_jobs_currently_ongoing: '',
      order_size_capable: '',
      size_of_largest_order: '',
      avg_fulfillment_time: '',
      exp_in_category: '',
      mwbe_certification_category: '',
      desc_for_wrk_exp: '',
      desc_for_wrk_exp_in_category: '',
      references: '',
      vendor_product_needs:''
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleCountryChange = this.handleCountryChange.bind(this);
    this.handleStateChange = this.handleStateChange.bind(this);
    this.handleMultiChange = this.handleMultiChange.bind(this);
    this.handleDayChange = this.handleDayChange.bind(this);
    this.handleInputFileChange = this.handleInputFileChange.bind(this);
    this.handleInputVideoChange = this.handleInputVideoChange.bind(this);
    this.onChangeNumberkey = this.onChangeNumberkey.bind(this);
    this.handleInputChangeFund = this.handleInputChangeFund.bind(this);
    this.handleVendorModelChange = this.handleVendorModelChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleBusinessModelChange = this.handleBusinessModelChange.bind(this);
    this.handleChamberModelChange = this.handleChamberModelChange.bind(this);
    this.handleIndustryChange = this.handleIndustryChange.bind(this);
    this.handleSubCatChange = this.handleSubCatChange.bind(this);
    this.handlePhoneChange = this.handlePhoneChange.bind(this);
  }

  handlePhoneChange(e) {
    const re1 = /^[0-9)\(+ '-]+$/g;
    // var phone_num = e.target.value.replace(/\D/g,'');
    if (e.target.value === "" || re1.test(e.target.value)) {
      // phone_num = this.phoneFormat(phone_num)
      this.setState({
        [e.target.name]: e.target.value,
      });
    }
  }

  phoneFormat(input) {
    // Strip all characters from the input except digits
    // Trim the remaining input to ten characters, to preserve phone number format
    input = input.substring(0, 10);
    // Based upon the length of the string, we add formatting as necessary
    var size = input.length;
    if (size == 0) {
      input = input;
    } else if (size < 4) {
      input = "(" + input;
    } else if (size < 7) {
      input = "(" + input.substring(0, 3) + ") " + input.substring(3, 6);
    } else {
      input = "(" + input.substring(0, 3) + ") " + input.substring(3, 6) + "-" + input.substring(6, 10);
    }
    return input;
  }

  handleSubCatChange(e) {
    const subindustries = this.state.subindustries;
    let index;
    // check if the check box is checked or unchecked
    if (e.target.checked) {
      // add the numerical value of the checkbox to options array
      subindustries.push(e.target.value);
    } else {
      // or remove the value from the unchecked checkbox from the array
      index = subindustries.indexOf(e.target.value);
      subindustries.splice(index, 1);
    }
    //console.log(subindustries);
    // update the state with the new array of options
    this.setState({ subindustries: subindustries });
  }

  handleIndustryChange(event) {
    //console.log(event.target.value);
    let vendor_company_industrey = "";
    if (event) {
      vendor_company_industrey = event.target.value;
      this.setState({ subindustriesdata: [] });
    } else {
      vendor_company_industrey = this.state.vendor_company_industrey;
    }

    if (vendor_company_industrey != "") {
      fetch(AppConstants.API + "/industries/getsubindustries/" + vendor_company_industrey)
        .then((response) => response.json())
        .then((data) => {
          //console.log(" sub industries ", data.subindustriesdata);
          if (data.Status == "Success") {
            this.setState({
              subindustriesdata: data.subindustriesdata,
              vendor_company_industrey: vendor_company_industrey,
            });
          } else {
            this.setState({ vendor_company_industrey: vendor_company_industrey });
          }
        });
    } else {
      this.setState({ vendor_company_industrey: vendor_company_industrey });
    }
  }

  handleClick(e) {
    //console.log(e.target.value);
    let index;
    if (e.target.checked) {
      certification_newids.push(e.target.value);
      document.getElementById("cert_" + e.target.value).style.display = "inline-block";

      filedata.push(document.getElementById("cert_" + e.target.value).style.display);
    } else {
      document.getElementById("cert_" + e.target.value).style.display = "none";

      index = filedata.indexOf(document.getElementById("cert_" + e.target.value));
      filedata.splice(index, 1);
      console.log(document.getElementById("cert_" + e.target.value).value);
      document.getElementById("cert_" + e.target.value).value = "";
    }
    filedata = filedata;
  }

  handleBusinessModelChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  handleChamberModelChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  handleFileChange(e) {
    //
    if (filedata != 0) {
      filedata.length--;
    }

    var newdata = e.target.id.split("_");
    certification_newids.push(newdata[1]);

    if (certification_newids.length > 0) {
      isFile = 1;
      this.setState({ loading: 1 });
    }

    //console.log(certification_newids);
    this.setState({ [e.target.name]: e.target.files[0] });

    let formData = new FormData();
    formData.append("vendorid", this.state.vendorid);
    formData.append("certification_documents", e.target.files[0]);
    formData.append("certificationid", JSON.stringify(certification_newids));
    axios.post(AppConstants.API + "/vendors/addvendorcertificate", formData).then((res) => {
      if (res.data.Status == "certifiedSuccess") {
        isFile = "";
        this.setState({ vendorid: res.data.vendorid, loading: "" });
      }
    });
  }

  handleVendorModelChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  handleInputFileChange(e) {
    this.setState({
      [e.target.name]: e.target.files[0],
    });
  }
  handleInputVideoChange(e) {
    this.setState({
      [e.target.name]: e.target.files[0],
    });
  }
  onChangeNumberkey(e) {
    const zip = this.state;
    //	const re = /^[0-9\b]+$/;
    const re = /^[0-9)\(+ '-]+$/g;
    if (e.target.value === "" || re.test(e.target.value)) {
      zip[e.target.name] = e.target.value;
      this.setState({ zip });
    }
  }

  handleDayChange(day) {
    //console.log('In handleDayChange')
    this.setState({ businessStartedDate: day });
  }
  /** Media multiple links */
  createMediaUI() {
    return this.state.multiplevalues.map((el, i) => (
      <div className="accolades" key={i}>
        <input
          type="text"
          name="media"
          value={el || ""}
          onChange={this.handleMediaChange.bind(this, i)}
          class="form-control"
          placeholder="Media"
        />
        <input type="button" className="remove-btn" value="remove" onClick={this.removeMediaClick.bind(this, i)} />
        <span className="error" id={"errr_" + i} style={{ color: "red" }}>
          {this.state.errors1[i]}
        </span>
      </div>
    ));
  }
  handleMediaChange(j, event) {
    let multiplevalues = [...this.state.multiplevalues];
    multiplevalues[j] = event.target.value;
    this.setState({ multiplevalues });

    if (event.target.value && event.target.value != "" && !/^https?:\/\//i.test(event.target.value)) {
      formIsValid1 = false;
      errors1[j] = "Please enter valid media link";
      this.setState({ errors1: errors1 });
      //console.log(errors1);
    } else {
      formIsValid1 = true;
    }
    return formIsValid1;
  }
  addMediaClick() {
    this.setState((prevState) => ({ multiplevalues: [...prevState.multiplevalues, ""] }));
  }
  removeMediaClick(i) {
    let multiplevalues = [...this.state.multiplevalues];
    multiplevalues.splice(i, 1);
    this.setState({ multiplevalues });
  }

  /** accolades multiple links */
  createAccoladesUI() {
    return this.state.multipleaccolades.map((el, i) => (
      <div className="accolades" key={i}>
        <input
          type="text"
          name="accolades"
          value={el || ""}
          onChange={this.handleAccoladesChange.bind(this, i)}
          class="form-control"
          placeholder="Accolades"
        />
        <input type="button" value="remove" className="remove-btn" onClick={this.removeAccoladesClick.bind(this, i)} />
      </div>
    ));
  }
  handleAccoladesChange(i, event) {
    let multipleaccolades = [...this.state.multipleaccolades];
    multipleaccolades[i] = event.target.value;
    this.setState({ multipleaccolades });
  }
  addAccoladesClick() {
    this.setState((prevState) => ({ multipleaccolades: [...prevState.multipleaccolades, ""] }));
  }
  removeAccoladesClick(i) {
    let multipleaccolades = [...this.state.multipleaccolades];
    multipleaccolades.splice(i, 1);
    this.setState({ multipleaccolades });
  }

  /** Memberships*/
   createMemberUI() {
    return this.state.multiplemembership.map((el, i) => (
      <div className="accolades" key={i}>
        <input
          type="text"
          name="membership"
          value={el || ""}
          onChange={this.handleMemberChange.bind(this, i)}
          class="form-control"
          placeholder="Membership"
        />
        <input type="button" className="remove-btn" value="remove" onClick={this.removeMemberClick.bind(this, i)} />
      </div>
    ));
  }
  handleMemberChange(i, event) {
    let multiplemembership = [...this.state.multiplemembership];
    multiplemembership[i] = event.target.value;
    this.setState({ multiplemembership });
  }
  addMemberClick() {
    this.setState((prevState) => ({ multiplemembership: [...prevState.multiplemembership, ""] }));
  }
  removeMemberClick(i) {
    let multiplemembership = [...this.state.multiplemembership];
    multiplemembership.splice(i, 1);
    this.setState({ multiplemembership });
  }

  handleInputChange(e) {
    //console.log(this.state.revanuerange);
    this.setState({
      [e.target.name]: e.target.value,
    });
  }
  handleInputChangeFund(e) {
    console.log(this.state.certifications);
    const certifications = this.state.certifications;
    let index;
    // check if the check box is checked or unchecked
    if (e.target.checked) {
      // add the numerical value of the checkbox to options array
      certifications.push(e.target.value);
    } else {
      // or remove the value from the unchecked checkbox from the array
      index = certifications.indexOf(e.target.value);
      certifications.splice(index, 1);
    }
    //console.log(certifications);
    // update the state with the new array of options
    this.setState({ certifications: certifications });

    if (this.state.checked == false) {
      this.setState({
        checked1: !this.state.checked1,
        certification_name: !this.state.checked1,
      });
    } else {
      this.setState({
        checked1: !this.state.checked1,
        certification_name: !this.state.checked1,
      });
    }
  }
  handleMultiChange(e) {
    var options = e.target.options;
    var value = [];
    for (var i = 0, l = options.length; i < l; i++) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    this.setState({
      naic_code: value,
    });
  }
  handleCountryChange(event) {
    //const country_id = event.target.value;
    let country_id = "";
    if (event) {
      country_id = event.target.value;
    } else {
      country_id = this.state.country;
    }
    console.log(country_id);
    if (country_id != "") {
      fetch(AppConstants.API + "/states/getallstates/" + country_id)
        .then((response) => response.json())
        .then((data) => {
          //console.log(" states ", data);
          if (data.Status == "Success") {
            this.setState({
              states: data.getallstates,
              country: country_id,
              cities: [],
            });
          } else {
            this.setState({
              country: country_id,
            });
          }
        });
    } else {
      this.setState({
        country: country_id,
        states: [],
        cities: [],
        state: "",
        city: "",
      });
    }
  }
  handleStateChange(event) {
    //const state_id = event.target.value;
    let state_id = "";
    if (event) {
      state_id = event.target.value;
    } else {
      state_id = this.state.state;
    }
    if (state_id != "") {
      fetch(AppConstants.API + "/cities/getallcities/" + state_id)
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          if (data.Status == "Success") {
            this.setState({
              cities: data.getallcities,
              state: state_id,
            });
          } else {
            this.setState({
              state: state_id,
            });
          }
        });
    } else {
      this.setState({
        state: state_id,
        cities: [],
        city: "",
      });
    }
  }

  /** Validations */
  handleValidation() {
    let errors = {};
    let formIsValid = true;
    //console.log(this.state.media)
    //Email
    /* if(!this.state.certifications){
      formIsValid = false;
      errors["certifications"] = "Please select certification";
    }  */

    //if(this.state.certifications.length!=0){
    // if(filedata.length>0){
    // formIsValid=false;
    // errors["certify"]="Please upload a file"
    // }
    if (!this.state.vendor_company_industrey) {
      formIsValid = false;
      errors["vendor_company_industrey"] = "Please select industry type";
      ReactDOM.findDOMNode(this.refs.vendor_company_industrey).focus();
    }
    // if(!this.state.vendor_company_phoneno){
    //   formIsValid = false;
    //   errors["vendor_company_phoneno"] = "Please enter phone number";
    //   ReactDOM.findDOMNode(this.refs.vendor_company_phoneno).focus();
    // }
    // if(this.state.vendor_primary_contact_phoneno !='' && this.state.vendor_primary_contact_phoneno.length !=14){
    //   formIsValid = false;
    //   errors["vendor_primary_contact_phoneno"] = "Please enter 10 digits phone number";
    // }
    /* 
    if(this.state.vendor_primary_cellno !='' && this.state.vendor_primary_cellno.length !=14){
      formIsValid = false;
      errors["vendor_primary_cellno"] = "Please enter 10 digits primary cell number";
    }
    if(this.state.vendor_primary_officeno !='' && this.state.vendor_primary_officeno.length !=14){
      formIsValid = false;
      errors["vendor_primary_officeno"] = "Please enter 10 digits primary office number";
    } */
    if (!this.state.naic_code.length) {
      formIsValid = false;
      errors["naic_code"] = "Please select at-least one NAICS Code";
    } else if (this.state.naic_code.length > 7) {
      formIsValid = false;
      errors["naic_code"] = "Please select max 7 NAICS Codes";
    }
    /* if(!this.state.country){
      formIsValid = false;
      errors["country"] = "Please select country";
    } */

    if (!this.state.zipcode) {
      formIsValid = false;
      errors["zipcode"] = "Please enter zip code";
      ReactDOM.findDOMNode(this.refs.zipcode).focus();
    } else if (this.state.zipcode.length > 15) {
      formIsValid = false;
      errors["zipcode"] = "Please enter valid zipcode";
      ReactDOM.findDOMNode(this.refs.zipcode).focus();
    }
    if (!this.state.state) {
      formIsValid = false;
      errors["state"] = "Please enter state";
      ReactDOM.findDOMNode(this.refs.state).focus();
    }
    if (!this.state.years_in_business) {
      formIsValid = false;
      errors["years_in_business"] = "Please enter years in business";
      ReactDOM.findDOMNode(this.refs.years_in_business).focus();
    }
    if (!this.state.no_of_jobs_currently_ongoing) {
      formIsValid = false;
      errors["no_of_jobs_currently_ongoing"] = "Please enter no.of jobs currently ongoing";
      ReactDOM.findDOMNode(this.refs.no_of_jobs_currently_ongoing).focus();
    }
    if (!this.state.order_size_capable) {
      formIsValid = false;
      errors["order_size_capable"] = "Please enter max job / order size capable";
      ReactDOM.findDOMNode(this.refs.order_size_capable).focus();
    }
    if (!this.state.size_of_largest_order) {
      formIsValid = false;
      errors["size_of_largest_order"] = "Please enter size of largest order";
      ReactDOM.findDOMNode(this.refs.size_of_largest_order).focus();
    }
    if (!this.state.avg_fulfillment_time) {
      formIsValid = false;
      errors["avg_fulfillment_time"] = "Please enter average fulfillment time";
      ReactDOM.findDOMNode(this.refs.avg_fulfillment_time).focus();
    }
    if (!this.state.employees) {
      formIsValid = false;
      errors["employees"] = "Please enter no.of employees";
      ReactDOM.findDOMNode(this.refs.employees).focus();
    }
    if (!this.state.exp_in_category) {
      formIsValid = false;
      errors["exp_in_category"] = " Please enter experience in category* ";
      ReactDOM.findDOMNode(this.refs.exp_in_category).focus();
    }
    if (!this.state.mwbe_certification_category) {
      formIsValid = false;
      errors["mwbe_certification_category"] = "Please enter mwbe certification category";
      ReactDOM.findDOMNode(this.refs.mwbe_certification_category).focus();
    }
    if (!this.state.desc_for_wrk_exp) {
      formIsValid = false;
      errors["desc_for_wrk_exp"] = "Please enter deficient experience in category or schedule completion   ";
      ReactDOM.findDOMNode(this.refs.desc_for_wrk_exp).focus();
    }
    if (!this.state.desc_for_wrk_exp_in_category) {
      formIsValid = false;
      errors["desc_for_wrk_exp_in_category"] = "Please enter stayed experience in category";
      ReactDOM.findDOMNode(this.refs.desc_for_wrk_exp_in_category).focus();
    }
    if (!this.state.references) {
      formIsValid = false;
      errors["references"] = "Please enter references";
      ReactDOM.findDOMNode(this.refs.references).focus();
    }
    
    if (!this.state.city) {
      formIsValid = false;
      errors["city"] = "Please enter city";
      ReactDOM.findDOMNode(this.refs.city).focus();
    }
    if (!this.state.vendor_company_address) {
      formIsValid = false;
      errors["vendor_company_address"] = "Please enter address 1";
      ReactDOM.findDOMNode(this.refs.vendor_company_address).focus();
    }
    if (!this.state.vendor_company_address2) {
      formIsValid = false;
      errors["vendor_company_address2"] = "Please enter Head quaters address";
      ReactDOM.findDOMNode(this.refs.vendor_company_address2).focus();
    }
    if (!this.state.vendor_product_needs) {
      formIsValid = false;
      errors["vendor_product_needs"] = "Please enter product needs";
      ReactDOM.findDOMNode(this.refs.vendor_product_needs).focus();
    }
    if (!this.state.vendor_company_type) {
      formIsValid = false;
      errors["vendor_company_type"] = "Please select company type";
      ReactDOM.findDOMNode(this.refs.vendor_company_type).focus();
    }
    if (typeof this.state.vendor_company_email !== "undefined") {
      let lastAtPos = this.state.vendor_company_email.lastIndexOf("@");
      let lastDotPos = this.state.vendor_company_email.lastIndexOf(".");

      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          this.state.vendor_company_email.indexOf("@@") == -1 &&
          lastDotPos > 2 &&
          this.state.vendor_company_email.length - lastDotPos > 2
        )
      ) {
        formIsValid = false;
        errors["vendor_company_email"] = "Please enter valid email";
        ReactDOM.findDOMNode(this.refs.vendor_company_email).focus();
      }
    }
    if (!this.state.vendor_company_email) {
      formIsValid = false;
      errors["vendor_company_email"] = "Please enter email";
      ReactDOM.findDOMNode(this.refs.vendor_company_email).focus();
    }
    if (!this.state.vendor_company_name) {
      formIsValid = false;
      errors["vendor_company_name"] = "Please enter company name";
      ReactDOM.findDOMNode(this.refs.vendor_company_name).focus();
    }
    if (
      this.state.companyLogo != "" &&
      this.state.companyLogo.type != "image/jpeg" &&
      this.state.companyLogo.type != "image/png"
    ) {
      formIsValid = false;
      errors["companyLogo"] = "Please select PNG/JPG file";
    }
    // if (!this.state.vendor_business_model) {
    //   formIsValid = false;
    //   errors["vendor_business_model"] = "please select Business model";
    // }
    if (this.state.vendor_company_website && !/^https?:\/\//i.test(this.state.vendor_company_website)) {
      formIsValid = false;
      errors["vendor_company_website"] = "Please enter valid website";
      ReactDOM.findDOMNode(this.refs.site).focus();
    }

    if (this.state.instagramLink && !/^https?:\/\//i.test(this.state.instagramLink)) {
      formIsValid = false;
      errors["instagramLink"] = "Please enter valid instagram link";
      ReactDOM.findDOMNode(this.refs.instagramLink).focus(); 
    }
    if (this.state.facebookLink && !/^https?:\/\//i.test(this.state.facebookLink)) {
      formIsValid = false;
      errors["facebookLink"] = "Please enter valid facebook link";
      ReactDOM.findDOMNode(this.refs.facebookLink).focus(); 
    }
    if (this.state.linkedInLink && !/^https?:\/\//i.test(this.state.linkedInLink)) {
      formIsValid = false;
      errors["linkedInLink"] = "Please enter valid linkedin link";
      ReactDOM.findDOMNode(this.refs.linkedInLink).focus(); 
    }
    if (this.state.youTubeLik && !/^https?:\/\//i.test(this.state.youTubeLik)) {
      formIsValid = false;
      errors["youTubeLik"] = "Please enter valid youtube link";
      ReactDOM.findDOMNode(this.refs.youTubeLik).focus(); 
    }

    if (this.state.twitterLink && !/^https?:\/\//i.test(this.state.twitterLink)) {
      formIsValid = false;
      errors["twitterLink"] = "Please enter valid twitter link";
      ReactDOM.findDOMNode(this.refs.twitterLink).focus(); 
    }

    if (this.state.media && !/^https?:\/\//i.test(this.state.media)) {
      formIsValid = false;
      errors["media"] = "Please enter valid media link";
    }

    this.setState({ errors: errors });
    return formIsValid;
  }

  handleSubmit = (event) => {
    event.preventDefault();
    //console.log('form submitted',formIsValid1);
    if (this.handleValidation() && formIsValid1 == true) {
      this.setState({ loading: 1 });
      let formData = new FormData();
      formData.append("vendorid", this.state.vendorid);
      formData.append("id", this.props.auth.user._id);
      formData.append("vendor_company_name", this.state.vendor_company_name);
      formData.append("vendor_company_email", this.state.vendor_company_email);
      formData.append("vendor_company_password", this.state.vendor_company_password);
      formData.append("vendor_company_type", this.state.vendor_company_type);
      formData.append("vendor_company_industrey", this.state.vendor_company_industrey);
      formData.append("certifications", JSON.stringify(this.state.certifications));
      formData.append("vendor_company_address", this.state.vendor_company_address);
      formData.append("vendor_company_address2", this.state.vendor_company_address2);
      formData.append("vendor_company_phoneno", this.state.vendor_company_phoneno);
      formData.append("vendor_company_website", this.state.vendor_company_website);
      formData.append("vendor_primary_contact_firstname", this.state.vendor_primary_contact_firstname);
      formData.append("vendor_primary_contact_lastname", this.state.vendor_primary_contact_lastname);

      formData.append("vendor_primary_contact_middlename", this.state.vendor_primary_contact_middlename);
      formData.append("vendor_primary_contact_title", this.state.vendor_primary_contact_title);

      formData.append("vendor_primary_contact_phoneno", this.state.vendor_primary_contact_phoneno);
      formData.append("vendor_primary_email", this.state.vendor_primary_email);
      formData.append("vendor_primary_cellno", this.state.vendor_primary_cellno);
      formData.append("vendor_primary_officeno", this.state.vendor_primary_officeno);

      formData.append("vendor_primary_department", this.state.vendor_primary_department);
      formData.append("certificationsandmembership", this.state.certificationsandmembership);

      formData.append("naic_code", JSON.stringify(this.state.naic_code));
      formData.append("descriptionofproductsandservices", this.state.descriptionofproductsandservices);

      formData.append("companyleadership", this.state.companyleadership);
      formData.append("department_min_revenue", this.state.department_min_revenue);
      formData.append("department_max_revenue", this.state.department_max_revenue);

      formData.append("employees", this.state.employees);
      formData.append("locationsOfOffices", this.state.locationsOfOffices);
      formData.append("latitude", this.state.latitude);
      formData.append("longitude", this.state.longitude);

      formData.append("country", this.state.country);
      formData.append("state", this.state.state);
      formData.append("city", this.state.city);

      formData.append("zipcode", this.state.zipcode);
      formData.append("companyLogo", this.state.companyLogo);
      formData.append("uploadVideo", this.state.uploadVideo);

      formData.append("instagramLink", this.state.instagramLink);
      formData.append("facebookLink", this.state.facebookLink);
      formData.append("linkedInLink", this.state.linkedInLink);

      formData.append("youTubeLik", this.state.youTubeLik);
      formData.append("twitterLink", this.state.twitterLink);
      formData.append("businessStartedDate", this.state.businessStartedDate);

      formData.append("accolades", this.state.accolades);
      formData.append("multipleaccolades", JSON.stringify(this.state.multipleaccolades));
      formData.append("status", this.state.status);
      formData.append("deleted", 0);
      formData.append("media", this.state.media);
      formData.append("multipleValues", JSON.stringify(this.state.multiplevalues));
      formData.append("leadershiptitle", this.state.leadershiptitle);
      formData.append("leadershipemail", this.state.leadershipemail);
      formData.append("request_from", "admin");
      formData.append("certificationfiles", this.state.certificationfiles);
      formData.append("membership", this.state.membership);
      formData.append("multiplemembership", JSON.stringify(this.state.multiplemembership));
      formData.append("vendor_business_model", this.state.vendor_business_model);

      formData.append("muslim_owned_business", this.state.muslim_owned_business);
      formData.append("memphis_chamber_mmember", this.state.memphis_chamber_mmember);
      formData.append("subindustries", JSON.stringify(this.state.subindustries));
      formData.append('years_in_business',this.state.years_in_business);
      formData.append('no_of_jobs_currently_ongoing', this.state.no_of_jobs_currently_ongoing);
      formData.append('order_size_capable', this.state.order_size_capable);
      formData.append('size_of_largest_order', this.state.size_of_largest_order);
      formData.append('avg_fulfillment_time', this.state.avg_fulfillment_time);
      formData.append('exp_in_category', this.state.exp_in_category);
      formData.append('mwbe_certification_category', this.state.mwbe_certification_category);
      formData.append('desc_for_wrk_exp', this.state.desc_for_wrk_exp);
      formData.append('desc_for_wrk_exp_in_category', this.state.desc_for_wrk_exp_in_category);
      formData.append('references', this.state.references);
      formData.append('vendor_product_needs',this.state.vendor_product_needs);

      // console.log(formData,"formData")
      axios.post(AppConstants.API + "/vendors/addvendor", formData).then((res) => {
        //console.log(res);
        if (res.data.Status == "Success") {
          setTimeout((e) => {
            this.props.history.push(AppConstants.STAGADMIN + "/vendors");
          }, 2000);
          this.setState({
            //errMsg: res.data.msg
            errMsg: <div className="alert alert-success fade show mt-3">{res.data.msg}</div>,
          });
        } else {
          this.setState({ loading: "" });
          setTimeout((e) => {
            this.setState({
              errMsg: "",
            });
          }, 2000);
          this.setState({
            //errMsg: res.data.msg
            errMsg: <div className="alert alert-danger fade show mt-3">{res.data.msg}</div>,
          });
        }
      });
    }
  };

  componentDidMount() {
    //Get Industries data
    fetch(AppConstants.API + "/industries/getallindustries")
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);
        if (data.Status == "Success") {
          this.setState({ industriesdata: data.industriesdata });
        } else {
          this.setState({ industriesdata: "" });
        }
      });
    //Get companies data
    fetch(AppConstants.API + "/companies/getallcompanies")
      .then((response) => response.json())
      .then((data) => {
        //console.log(" companies ", data);
        if (data.Status == "Success") {
          this.setState({ companiesdata: data.companiesdata });
        } else {
          this.setState({ companiesdata: "" });
        }
      });
    //Get Certification data
    fetch(AppConstants.API + "/certifications/getallcertifications")
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data.Status == "Success") {
          this.setState({ certificationsdata: data.certificationsdata });
        } else {
          this.setState({ certificationsdata: "" });
        }
      });

    //Get naic codes
    fetch(AppConstants.API + "/users/getallnaiccodes")
      .then((response) => response.json())
      .then((data) => {
        //console.log(" naic codes ", data);
        if (data.Status == "Success") {
          this.setState({ naiccodes: data.naiccodes });
        } else {
          this.setState({ naiccodes: "" });
        }
      });
    //Get all countries
    fetch(AppConstants.API + "/countries/getallcountries")
      .then((response) => response.json())
      .then((data) => {
        if (data.Status == "Success") {
          //console.log(data.getallcountries);
          this.setState({
            countries: data.getallcountries,
          });
        }
      });
    var element = document.getElementById("navItem-Vendors-2");
    element.classList.add("active");
  }
  render() {
    const { user } = this.props.auth;
    const { selectedDay } = this.state;

    /* const certificatesfile = this.state.checked1 
  ? <div> <Input type="file" name="certificationfiles[]"></Input> </div>
  : null;{certificatesfile} */

    const checkboxItems = this.state.certificationsdata.map((planet) => {
      return (
        <div className="cert-list">
          <Input
            type="checkbox"
            name="certification_name"
            onChange={this.handleInputChangeFund}
            value={planet._id}
            class="form-control"
            onClick={this.handleClick}
          ></Input>
          <span>{planet.certification_name}</span>

          <div
            class="file-field input-field fileupload cert-filupload"
            style={{ display: "none" }}
            id={`${"cert_"}${planet._id}`}
          >
            <div class="filebtn">
              <span>
                <i class="fa fa-upload" aria-hidden="true"></i>
              </span>
              <input type="file" name="docfile" onChange={this.handleFileChange} />
            </div>

            <div class="file-path-wrapper">
              <input class="file-path validate" type="text" placeholder="Please upload certificate." />
            </div>
          </div>
          {/* <input style={{"display":"none"}} name="docfile" type="file" id={`${'cert_'}${planet._id}`} onChange={ this.handleFileChange }></input> */}
        </div>
      );
    });

    const subcatcheckboxItems =
      this.state.company_industrey != ""
        ? this.state.subindustriesdata.map((planet) => {
            return (
              <div className="rd-btns">
                <Label check className="rd-btn">
                  <Input
                    className="rd-btn"
                    type="checkbox"
                    name="subindustry_name"
                    value={planet.subcatid}
                    onChange={this.handleSubCatChange}
                    class="form-control"
                    id={planet.subcatid}
                  ></Input>
                  <span>{planet.subindustryname}</span>
                </Label>
              </div>
            );
          })
        : null;

    return (
      <Page title="Add Vendor">
        <ReactTitle title="Add Vendor" />
        <Link to={AppConstants.STAGADMIN + "/dashboard"}>Home</Link>
        &nbsp; | &nbsp;&nbsp;
        <Link to={AppConstants.STAGADMIN + "/vendors"}>Vendors</Link>
        <Form className="form-block" onSubmit={this.handleSubmit}>
          <Row>
            <Col xl={6} lg={12} md={12}>
              <Card>
                {/*<span className="error" style={{color: 'red'}}>{this.state.errMsg}</span>*/}
                <CardHeader>Basic info</CardHeader>
                <CardBody>
                  <FormGroup>
                    <Label for="exampleEmail">
                      Company Name{" "}
                      <span className="error" style={{ color: "red" }}>
                        *
                      </span>
                    </Label>
                    <Input
                      type="text"
                      name="vendor_company_name"
                      placeholder="Company Name"
                      onChange={this.handleInputChange}
                      ref="vendor_company_name"
                    />
                    <span className="error" style={{ color: "red" }}>
                      {this.state.errors["vendor_company_name"]}
                    </span>
                  </FormGroup>
                  <FormGroup>
                    <Label for="exampleEmail">
                      Email{" "}
                      <span className="error" style={{ color: "red" }}>
                        *
                      </span>
                    </Label>
                    <Input
                      type="text"
                      name="vendor_company_email"
                      placeholder="Email"
                      onChange={this.handleInputChange}
                      ref="vendor_company_email"
                    />
                    <span className="error" style={{ color: "red" }}>
                      {this.state.errors["vendor_company_email"]}
                    </span>
                  </FormGroup>
                  <FormGroup>
                    <Label for="exampleEmail">
                      Type{" "}
                      <span className="error" style={{ color: "red" }}>
                        *
                      </span>
                    </Label>
                    <Input
                      type="select"
                      name="vendor_company_type"
                      value={this.state.vendor_company_type}
                      onChange={this.handleInputChange}
                      ref="vendor_company_type"
                    >
                      <option value="">Select Company Type</option>
                      {this.state.companiesdata.map((companies, index) => {
                        if (companies.status == "Active") {
                          return (
                            <option key={index} value={companies._id}>
                              {companies.companyname}
                            </option>
                          );
                        }
                      })}
                    </Input>
                    <span className="error" style={{ color: "red" }}>
                      {this.state.errors["vendor_company_type"]}
                    </span>
                  </FormGroup>

                  <FormGroup>
                    <Label for="examplePassword">
                      Address 1{" "}
                      <span className="error" style={{ color: "red" }}>
                        *
                      </span>
                    </Label>
                    <Input
                      type="text"
                      name="vendor_company_address"
                      placeholder="Address 1"
                      onChange={this.handleInputChange}
                      ref="vendor_company_address"
                    />
                    <span className="error" style={{ color: "red" }}>
                      {this.state.errors["vendor_company_address"]}
                    </span>
                  </FormGroup>
                  <FormGroup>
                    <Label for="examplePassword">Head quarters Address <span className="error" style={{ color: "red" }}>
                        *
                      </span></Label>
                    <Input
                      type="text"
                      name="vendor_company_address2"
                      placeholder="Head quarters Address"
                      onChange={this.handleInputChange}
                       ref="vendor_company_address2"
                    />
                     <span className="error" style={{ color: "red" }}>
                      {this.state.errors["vendor_company_address2"]}
                    </span>
                  </FormGroup>
                  <FormGroup>
                    <Label for="exampleEmail">
                      City{" "}
                      <span className="error" style={{ color: "red" }}>
                        *
                      </span>
                    </Label>
                    <Input type="text" name="city" placeholder="City" onChange={this.handleInputChange} ref="city" />
                    <span className="error" style={{ color: "red" }}>
                      {this.state.errors["city"]}
                    </span>
                  </FormGroup>
                  <FormGroup>
                    <Label for="exampleState">
                      State <span style={{ color: "red" }}> * </span>
                    </Label>
                    <Input
                      type="select"
                      name="state"
                      placeholder="State"
                      value={this.state.state}
                      onChange={this.handleInputChange}
                      ref="state"
                    >
                      <option value="">State</option>
                      <option value="Alabama">Alabama</option>
                      <option value="Alaska">Alaska</option>
                      <option value="Arizona">Arizona</option>
                      <option value="Arkansas">Arkansas</option>
                      <option value="California">California</option>
                      <option value="Colorado">Colorado</option>
                      <option value="Connecticut">Connecticut</option>
                      <option value="Delaware">Delaware</option>
                      <option value="District Of Columbia">District Of Columbia</option>
                      <option value="Florida">Florida</option>
                      <option value="Georgia">Georgia</option>
                      <option value="Hawaii">Hawaii</option>
                      <option value="Idaho">Idaho</option>
                      <option value="Illinois">Illinois</option>
                      <option value="Indiana">Indiana</option>
                      <option value="Iowa">Iowa</option>
                      <option value="Kansas">Kansas</option>
                      <option value="Kentucky">Kentucky</option>
                      <option value="Louisiana">Louisiana</option>
                      <option value="Maine">Maine</option>
                      <option value="Maryland">Maryland</option>
                      <option value="Massachusetts">Massachusetts</option>
                      <option value="Michigan">Michigan</option>
                      <option value="Minnesota">Minnesota</option>
                      <option value="Mississippi">Mississippi</option>
                      <option value="Missouri">Missouri</option>
                      <option value="Montana">Montana</option>
                      <option value="Nebraska">Nebraska</option>
                      <option value="Nevada">Nevada</option>
                      <option value="New Hampshire">New Hampshire</option>
                      <option value="New Jersey">New Jersey</option>
                      <option value="New Mexico">New Mexico</option>
                      <option value="New York">New York</option>
                      <option value="North Carolina">North Carolina</option>
                      <option value="North Dakota">North Dakota</option>
                      <option value="Ohio">Ohio</option>
                      <option value="Oklahoma">Oklahoma</option>
                      <option value="Oregon">Oregon</option>
                      <option value="Pennsylvania">Pennsylvania</option>
                      <option value="Rhode Island">Rhode Island</option>
                      <option value="South Carolina">South Carolina</option>
                      <option value="South Dakota">South Dakota</option>
                      <option value="Tennessee">Tennessee</option>
                      <option value="Texas">Texas</option>
                      <option value="Utah">Utah</option>
                      <option value="Vermont">Vermont</option>
                      <option value="Virginia">Virginia</option>
                      <option value="Washington">Washington</option>
                      <option value="West Virginia">West Virginia</option>
                      <option value="Wisconsin">Wisconsin</option>
                      <option value="Wyoming">Wyoming</option>
                    </Input>
                    <span className="error" style={{ color: "red" }}>
                      {this.state.errors["state"]}
                    </span>
                  </FormGroup>
                  {/* <FormGroup>
              <Label for="exampleEmail">State <span className="error" style={{color: 'red'}}>*</span></Label>                  
              <Input
                  type="text"
                  name="state"
                  placeholder="State"
                  onChange={ this.handleInputChange }     
                  ref ="state"             
                />
                <span className="error" style={{color: 'red'}}>{this.state.errors["state"]}</span>
            </FormGroup>             */}
                  <FormGroup>
                    <Label for="exampleEmail">
                      Zipcode{" "}
                      <span className="error" style={{ color: "red" }}>
                        *
                      </span>
                    </Label>
                    <Input
                      type="text"
                      name="zipcode"
                      placeholder="Zip Code"
                      // onChange={ this.handleInputChange }
                      onChange={this.onChangeNumberkey}
                      value={this.state.zipcode}
                      ref="zipcode"
                    />
                    <span className="error" style={{ color: "red" }}>
                      {this.state.errors["zipcode"]}
                    </span>
                  </FormGroup>
                  {/* <FormGroup>
                    <label>
                      What is your Company Business Model?{" "}
                      <span className="error" style={{ color: "red" }}>
                        *
                      </span>
                    </label>
                    <div>
                      <div class="form-group form-check inline">
                        <input
                          type="radio"
                          name="vendor_business_model"
                          id="vendor_business_model_B2B"
                          value="B2B"
                          checked={this.state.vendor_business_model == "B2B"}
                          onChange={this.handleVendorModelChange}
                        />
                        <label for="vendor_business_model_B2B">B2B</label>
                      </div>
                      <div class="form-group form-check inline">
                        <input
                          type="radio"
                          name="vendor_business_model"
                          id="vendor_business_model_B2C"
                          checked={this.state.vendor_business_model == "B2C"}
                          value="B2C"
                          onChange={this.handleVendorModelChange}
                        />
                        <label for="vendor_business_model_B2C">B2C</label>
                      </div>
                      <div class="form-group form-check inline">
                        <input
                          type="radio"
                          name="vendor_business_model"
                          id="vendor_business_model_both"
                          checked={this.state.vendor_business_model == "Both"}
                          value="Both"
                          onChange={this.handleVendorModelChange}
                        />
                        <label for="vendor_business_model_both">Both</label>
                      </div>
                    </div>
                    <span className="error" style={{ color: "red" }}>
                      {this.state.errors["vendor_business_model"]}
                    </span>
                  </FormGroup>

                  <FormGroup>
                    <label>Are you a Minority Owned Business? </label>
                    <div>
                      <div class="form-group form-check inline">
                        <input
                          type="radio"
                          name="muslim_owned_business"
                          id="muslim_owned_business_yes"
                          value="Yes"
                          checked={this.state.muslim_owned_business == "Yes"}
                          onChange={this.handleBusinessModelChange}
                        />
                        <label for="muslim_owned_business_yes">Yes</label>
                      </div>
                      <div class="form-group form-check inline">
                        <input
                          type="radio"
                          name="muslim_owned_business"
                          id="muslim_owned_business_no"
                          value="No"
                          checked={this.state.muslim_owned_business == "No"}
                          onChange={this.handleBusinessModelChange}
                        />
                        <label for="muslim_owned_business_no">No</label>
                      </div>
                    </div>
                  </FormGroup> */}

                  {/* <FormGroup>
                    <label>Are you a Greater Memphis Chamber Member? </label>
                    <div>
                      <div class="form-group form-check inline">
                        <input
                          type="radio"
                          name="memphis_chamber_mmember"
                          id="memphis_chamber_mmember_yes"
                          value="Yes"
                          checked={this.state.memphis_chamber_mmember == "Yes"}
                          onChange={this.handleChamberModelChange}
                        />
                        <label for="memphis_chamber_mmember_yes">Yes</label>
                      </div>
                      <div class="form-group form-check inline">
                        <input
                          type="radio"
                          name="memphis_chamber_mmember"
                          id="memphis_chamber_mmember_no"
                          value="No"
                          checked={this.state.memphis_chamber_mmember == "No"}
                          onChange={this.handleChamberModelChange}
                        />
                        <label for="memphis_chamber_mmember_no">No</label>
                      </div>
                    </div>
                  </FormGroup> */}

                  <FormGroup>
                    <Label for="exampleEmail">
                      Phone No
                      {/* <span className="error" style={{color: 'red'}}>*</span> */}
                    </Label>
                    {/* <NumberFormat
                format="(###) ###-####" mask=""
                name="vendor_company_phoneno"
                placeholder='Phone Number'
                onChange={this.handleInputChange}
                value={this.state.vendor_company_phoneno} class="form-control"/>
                <span className="error" style={{color: 'red'}}>{this.state.errors["vendor_company_phoneno"]}</span>  */}
                    <Input
                      type="text"
                      name="vendor_company_phoneno"
                      placeholder="Phone No"
                      onChange={this.handlePhoneChange}
                      value={this.state.vendor_company_phoneno}
                      maxLength="15"
                    />
                    {/* <span className="error" style={{color: 'red'}}>{this.state.errors["vendor_company_phoneno"]}</span>  */}
                  </FormGroup>
                  <FormGroup>
                    <Label for="examplePassword">
                    Product Needs {" "}
                     <span className="error" style={{color: 'red'}}>*</span>
                    </Label>
                    <Input
                      type="text"
                      name="vendor_product_needs"
                      placeholder="Product Needs"
                      onChange={this.handleInputChange}
                      ref="vendor_product_needs"
                      value={this.state.vendor_product_needs}
                    />
                     <span className="error" style={{ color: 'red' }}>
                                    {this.state.errors['vendor_product_needs']}
                                  </span>
                  </FormGroup>
                  <FormGroup>
                    <Label for="examplePassword">
                    years In Business{" "}
                    <span className="error" style={{color: 'red'}}>*</span>
                    </Label>
                    <Input
                      type="text"
                      name="years_in_business"
                      placeholder=" Please enter years In business "
                      onChange={this.handleInputChange}
                      ref="years_in_business"
                      value={this.state.years_in_business}
                    />
                     <span className="error" style={{ color: 'red' }}>
                                    {this.state.errors['years_in_business']}
                                  </span>
                  </FormGroup>
                  <FormGroup>
                    <Label for="examplePassword">
                    No of jobs currently ongoing{" "}
                     <span className="error" style={{color: 'red'}}>*</span>
                    </Label>
                    <Input
                      type="text"
                      name="no_of_jobs_currently_ongoing"
                      placeholder=" Please enter no of jobs currently ongoing"
                      onChange={this.handleInputChange}
                      ref="no_of_jobs_currently_ongoing"
                      value={this.state.no_of_jobs_currently_ongoing}

                    />
                    <span className="error" style={{ color: 'red' }}>
                                    {this.state.errors['no_of_jobs_currently_ongoing']}
                                  </span>
                  </FormGroup>
                  <FormGroup>
                    <Label for="examplePassword">
                    Max job/ order size capable{" "}
                    <span className="error" style={{color: 'red'}}>*</span>
                    </Label>
                    <Input
                      type="text"
                      name="order_size_capable"
                      placeholder="Please enter max job/ order size capable"
                      onChange={this.handleInputChange}
                      ref="order_size_capable"
                      value={this.state.order_size_capable}
                    />
                    <span className="error" style={{ color: 'red' }}>
                                    {this.state.errors['order_size_capable']}
                                  </span>
                  </FormGroup>
                  <FormGroup>
                    <Label for="examplePassword">
                    Size of largest order{" "}
                    <span className="error" style={{color: 'red'}}>*</span>
                    </Label>
                    <Input
                      type="text"
                      name="size_of_largest_order"
                      placeholder=" Please enter size of largest order"
                      onChange={this.handleInputChange}
                      ref="size_of_largest_order"
                      value={this.state.size_of_largest_order}
                    />
                    <span className="error" style={{ color: 'red' }}>
                                    {this.state.errors['size_of_largest_order']}
                                  </span>
                  </FormGroup>
                  <FormGroup>
                    <Label for="examplePassword">
                    Average fulfillment Time{" "}
                    <span className="error" style={{color: 'red'}}>*</span>
                    </Label>
                    <Input
                      type="text"
                      name="avg_fulfillment_time"
                      placeholder=" Please enter average fulfillment time"
                      onChange={this.handleInputChange}
                      ref="avg_fulfillment_time"
                      value={this.state.avg_fulfillment_time}

                    />
                    <span className="error" style={{ color: 'red' }}>
                                    {this.state.errors['avg_fulfillment_time']}
                                  </span>
                  </FormGroup>
                  <FormGroup>
                    <Label for="examplePassword">
                    Experience in category{" "}
                    <span className="error" style={{color: 'red'}}>*</span>
                    </Label>
                    <Input
                      type="text"
                      name="exp_in_category"
                      placeholder=" Please enter experience in category"
                      onChange={this.handleInputChange}
                      ref="exp_in_category"
                      value={this.state.exp_in_category}
                    />
                    <span className="error" style={{ color: 'red' }}>
                                    {this.state.errors['exp_in_category']}
                                  </span>
                  </FormGroup>
                  <FormGroup>
                    <Label for="examplePassword">
                    MWBE certification category{" "}
                    <span className="error" style={{color: 'red'}}>*</span>
                    </Label>
                    <Input
                      type="text"
                      name="mwbe_certification_category"
                      placeholder="Please enter mwbe certification category"
                      onChange={this.handleInputChange}
                      ref="mwbe_certification_category"
                      value={this.state.mwbe_certification_category}

                    />
                    <span className="error" style={{ color: 'red' }}>
                                    {this.state.errors['mwbe_certification_category']}
                                  </span>
                  </FormGroup>
                  <FormGroup>
                    <Label for="examplePassword">
                    References{" "}
                    <span className="error" style={{color: 'red'}}>*</span>
                    </Label>
                    <Input
                      type="text"
                      name="references"
                      placeholder=" Please enter references"
                      onChange={this.handleInputChange}
                      ref="references"
                      value={this.state.references}

                    />
                    <span className="error" style={{ color: 'red' }}>
                                    {this.state.errors['references']}
                                  </span>
                  </FormGroup>
                  <FormGroup>
                    <Label for="exampleText">Have you ever been found deficient in work experience or schedule completion{" "} <span className="error" style={{color: 'red'}}>*</span></Label>
                   
                    <Input
                      type="textarea"
                      name="desc_for_wrk_exp"
                      onChange={this.handleInputChange}
                      value={this.state.desc_for_wrk_exp}
                       ref="desc_for_wrk_exp"

                    />
                    <span className="error" style={{ color: 'red' }}>
                                    {this.state.errors['desc_for_wrk_exp']}
                                  </span>
                  </FormGroup>
                  <FormGroup>
                    <Label for="exampleText">Do you have stayed experience in category, would expand for number of jobs completed in each category{""}<span className="error" style={{color: 'red'}}>*</span></Label>
                    
                    <Input
                      type="textarea"
                      name="desc_for_wrk_exp_in_category"
                      onChange={this.handleInputChange}
                      value={this.state.desc_for_wrk_exp_in_category}
                       ref="desc_for_wrk_exp_in_category"

                    />
                    <span className="error" style={{ color: 'red' }}>
                                    {this.state.errors['desc_for_wrk_exp_in_category']}
                                  </span>
                  </FormGroup>
                  <FormGroup>
                    <Label for="exampleSelect">No.of Employees{""} <span className="error" style={{color: 'red'}}>*</span></Label>
                   
                    {/* <Input type="select" name="employees" onChange={ this.handleInputChange }>
                    <option value="">Select Employees</option>
                    <option value="0-100">0-100</option>
                    <option value="100-200">100-200</option>      
                    <option value="200-300">200-300</option>      
                    <option value="300-400">300-400</option>      
                    <option value="400-500">400-500</option>         
                    <option value="Above 500">Above 500</option>                 
                  </Input>*/}

                    <Input type="text" name="employees" placeholder="Employees" onChange={this.handleInputChange}ref = "employees" />
                    <span className="error" style={{ color: 'red' }}>
                                    {this.state.errors['employees']}
                                  </span>
                  </FormGroup>
                  <FormGroup>
                    <Label for="exampleSelect">Revenue Range</Label>
                    <Input type="select" name="department_max_revenue" onChange={this.handleInputChange}>
                      <option value="">Select Revenue Range</option>
                      <option value="$1 - $1,000,000">$1 - $1,000,000</option>
                      <option value="$1,000,001 - $5,000,000">$1,000,001 - $5,000,000</option>
                      <option value="$5,000,001 - $20,000,000">$5,000,001 - $20,000,000</option>
                      <option value="$20,000,001 - $50,000,000">$20,000,001 - $50,000,000</option>
                      <option value="$50,000,001 - $100,000,000">$50,000,001 - $100,000,000</option>
                      <option value="$100,000,001 - $500,000,000">$100,000,001 - $500,000,000</option>
                      <option value="$500,000,001 or Greater">$500,000,001 or Greater</option>
                      {/* <option value="$0 - $20,000">$0 - $20,000</option>
                    <option value="$20,000 - $50,000">$20,000 - $50,000</option>
                    <option value="Above $50,000">Above $50,000</option>*/}
                    </Input>
                  </FormGroup>
                  <FormGroup>
                    <Label for="exampleSelect">Business Start Date</Label>
                    <div className="graph-field">
                      <DatePicker
                        selected={this.state.businessStartedDate}
                        onChange={this.handleDayChange}
                        dateFormat="MM/dd/yyyy"
                        placeholderText="MM/DD/YYYY"
                        name="businessStartedDate"
                        className="form-control"
                        onChangeRaw={(e) => e.preventDefault()}
                      />
                      <i class="fa fa-calendar" aria-hidden="true"></i>
                    </div>
                  </FormGroup>
                </CardBody>
              </Card>
              <br />
                           <Card>
                <CardHeader>Accolades and Media Links</CardHeader>
                <CardBody>
                  <FormGroup>
                    <Label for="exampleSelect">Accolades</Label>
                    <Input type="text" name="accolades" placeholder="Accolades" onChange={this.handleInputChange} />
                    <br />
                    {this.createAccoladesUI()}
                    <div className="form-btn">
                      <input
                        type="button"
                        value="Add"
                        className="btn btn-secondary addbtn"
                        onClick={this.addAccoladesClick.bind(this)}
                      />
                      <br />
                    </div>
                  </FormGroup>

                  <FormGroup>
                    <Label for="exampleSelect">Media</Label>
                    <Input type="text" name="media" placeholder="Media" onChange={this.handleInputChange} />
                    <span className="error" style={{ color: "red" }}>
                      {this.state.errors["media"]}
                    </span>
                    <br />
                    {this.createMediaUI()}
                    <div className="form-btn">
                      <input
                        type="button"
                        value="Add"
                        className="btn btn-secondary addbtn"
                        onClick={this.addMediaClick.bind(this)}
                      />
                      <br />
                    </div>
                  </FormGroup>

                  <FormGroup>
                    <Label for="exampleSelect">Status</Label>
                    <Input type="select" name="status" onChange={this.handleInputChange}>
                      <option value="Active">Active</option>
                      <option value="In-Active">In Active</option>
                    </Input>
                  </FormGroup>
                </CardBody>
              </Card>
            </Col>
            <Col xl={6} lg={12} md={12}>
              <Card>
                <CardHeader>Contact info</CardHeader>
                <CardBody>
                  <FormGroup>
                    <Label for="exampleEmail">First Name</Label>
                    <Input
                      type="text"
                      name="vendor_primary_contact_firstname"
                      placeholder="First Name"
                      onChange={this.handleInputChange}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="exampleEmail">Middle Name</Label>
                    <Input
                      type="text"
                      name="vendor_primary_contact_middlename"
                      placeholder="Middle Name"
                      onChange={this.handleInputChange}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="exampleEmail">Last Name</Label>
                    <Input
                      type="text"
                      name="vendor_primary_contact_lastname"
                      placeholder="Last Name"
                      onChange={this.handleInputChange}
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label for="exampleEmail">Email</Label>
                    <Input
                      type="text"
                      name="vendor_primary_email"
                      placeholder="Email"
                      onChange={this.handleInputChange}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="exampleEmail">Department</Label>
                    <Input
                      type="text"
                      name="vendor_primary_department"
                      placeholder="Department"
                      onChange={this.handleInputChange}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="examplePassword">Website</Label>
                    <Input
                      type="text"
                      name="vendor_company_website"
                      placeholder="Website"
                      onChange={this.handleInputChange}
                      ref="site"
                    />
                    <span className="error" style={{ color: "red" }}>
                      {this.state.errors["vendor_company_website"]}
                    </span>
                  </FormGroup>
                </CardBody>
              </Card>
              <br />
              <Card>
                <CardHeader>NAICS Codes</CardHeader>
                <CardBody>
                  <FormGroup>
                    <Label for="exampleSearch">
                      NAICS Codes{" "}
                      <span className="error" style={{ color: "red" }}>
                        *
                      </span>
                    </Label>
                    <Typeahead
                      clearButton
                      labelKey={(option) => `${option.NAICS_Description}` + `(${option.NAICS})`}
                      multiple
                      options={this.state.naiccodes}
                      placeholder="NAICS Codes"
                      name="naic_code"
                      value={this.state.naiccodes}
                      onChange={(selected) => {
                        this.setState({ naic_code: selected });
                      }}
                      class="form-control"
                    />
                    <span className="error" style={{ color: "red" }}>
                      {this.state.errors["naic_code"]}
                    </span>
                  </FormGroup>
                </CardBody>
              </Card>
              <br />
              <Card>
                <CardHeader>Logo</CardHeader>
                <CardBody>
                  <FormGroup>
                    <Label for="exampleUrl">Logo</Label>
                    <div class="file-field input-field fileupload">
                      <div class="filebtn">
                        <span>
                          <i class="fa fa-upload" aria-hidden="true"></i>
                        </span>
                        <input type="file" name="companyLogo" onChange={this.handleInputFileChange} />
                      </div>

                      <div class="file-path-wrapper">
                        <input class="file-path validate" type="text" placeholder="  Please upload logo." />
                      </div>
                    </div>

                    <span className="error" style={{ color: "red" }}>
                      {this.state.errors["companyLogo"]}
                    </span>
                  </FormGroup>
                  {/* <FormGroup>
                <Label for="exampleUrl">Video</Label>
                <div class = "file-field input-field fileupload">
                    <div class = "filebtn">
                        <span><i class="fa fa-upload" aria-hidden="true"></i></span>
                        <input type = "file" name="uploadVideo" onChange={ this.handleInputVideoChange} />
                    </div>
                                                            
                    <div class = "file-path-wrapper">
                        <input class = "file-path validate" type = "text"
                                                                    placeholder = "  Please upload video." />
                    </div>
                </div> 
                                 
                </FormGroup> */}
                </CardBody>
              </Card>
              <br />
              <Card>
                <CardHeader>Social Links</CardHeader>
                <CardBody>
                  <FormGroup>
                    <Label for="exampleSelect">Instagram Link</Label>
                    <Input
                      type="text"
                      name="instagramLink"
                      placeholder="Please enter here"
                      onChange={this.handleInputChange}
                      ref="instagramLink"

                    />
                     <span className="error" style={{ color: "red" }}>
                      {this.state.errors["instagramLink"]}
                    </span> 
                  </FormGroup>
                  <FormGroup>
                    <Label for="exampleSelect">Facebook Link</Label>
                    <Input
                      type="text"
                      name="facebookLink"
                      placeholder="Please enter here"
                      onChange={this.handleInputChange}
                      ref="facebookLink"

                    />
                    <span className="error" style={{ color: "red" }}>
                      {this.state.errors["facebookLink"]}
                    </span>
                  </FormGroup>
                  <FormGroup>
                    <Label for="exampleSelect">LinkedIn Link</Label>
                    <Input
                      type="text"
                      name="linkedInLink"
                      placeholder="Please enter here"
                      onChange={this.handleInputChange}
                      ref="linkedInLink"

                    />
                    <span className="error" style={{ color: "red" }}>
                      {this.state.errors["linkedInLink"]}
                    </span>
                  </FormGroup>
                  <FormGroup>
                    <Label for="exampleSelect">YouTube Link</Label>
                    <Input
                      type="text"
                      name="youTubeLik"
                      placeholder="Please enter here"
                      onChange={this.handleInputChange}
                      ref="youTubeLik"

                    />
                    <span className="error" style={{ color: "red" }}>
                      {this.state.errors["youTubeLik"]}
                    </span>
                  </FormGroup>
                  <FormGroup>
                    <Label for="exampleSelect">Twitter Link</Label>
                    <Input
                      type="text"
                      name="twitterLink"
                      placeholder="Please enter here"
                      onChange={this.handleInputChange}
                      ref="twitterLink"

                    />
                    <span className="error" style={{ color: "red" }}>
                      {this.state.errors["twitterLink"]}
                    </span>
                  </FormGroup>
                </CardBody>
              </Card>
              <br />
              <Card>
                <CardHeader>Company Leadership</CardHeader>
                <CardBody>
                  <FormGroup>
                    <Label for="exampleSelect">Title</Label>
                    <Input type="text" name="leadershiptitle" placeholder="Title" onChange={this.handleInputChange} />
                  </FormGroup>
                  <FormGroup>
                    <Label for="exampleSelect">Name</Label>
                    <Input type="text" name="companyleadership" placeholder="Name" onChange={this.handleInputChange} />
                  </FormGroup>

                  <FormGroup>
                    <Label for="exampleSelect">Phone</Label>
                    {/* <NumberFormat
                    format="(###) ###-####" mask=""
                    name="vendor_primary_contact_phoneno"
                    placeholder='Phone'
                    onChange={this.handleInputChange}
                    value={this.state.vendor_primary_contact_phoneno} class="form-control"/>   <span className="error" style={{color: 'red'}}>{this.state.errors["vendor_primary_contact_phoneno"]}</span> */}

                    <Input
                      type="text"
                      name="vendor_primary_contact_phoneno"
                      placeholder="Phone"
                      onChange={this.handlePhoneChange}
                      value={this.state.vendor_primary_contact_phoneno}
                      maxLength="15"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="exampleSelect">Email</Label>
                    <Input type="text" name="leadershipemail" placeholder="Email" onChange={this.handleInputChange} />
                  </FormGroup>
                </CardBody>
              </Card>
            </Col>
            <Col xl={12} lg={12} md={12}>
              <Card>
                <CardHeader>Industry and Sub Industries</CardHeader>
                <CardBody>
                  <FormGroup>
                    <Label for="exampleDatetime">
                      {" "}
                      Industry{" "}
                      <span className="error" style={{ color: "red" }}>
                        *
                      </span>
                    </Label>
                    <Input
                      type="select"
                      name="vendor_company_industrey"
                      value={this.state.vendor_company_industrey}
                      onChange={this.handleIndustryChange}
                      ref="vendor_company_industrey"
                    >
                      <option value="">Select Industry Type</option>
                      {this.state.industriesdata.map((industries, index) => {
                        if (industries.status == "Active") {
                          return (
                            <option key={index} value={industries._id}>
                              {industries.industryname}
                            </option>
                          );
                        }
                      })}
                    </Input>
                    <span className="error" style={{ color: "red" }}>
                      {this.state.errors["vendor_company_industrey"]}
                    </span>
                  </FormGroup>
                  <FormGroup>{subcatcheckboxItems}</FormGroup>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md={12} className="form-btn">
              <span style={this.state.loading && isFile ? {} : { display: "none" }}>
                <div class="alert alert-warning" role="alert">
                  <strong>Uploading certifications will take a while depending on size of certifications.</strong>
                </div>
              </span>
              <Button
                class="btn btn-primary addbtn"
                className="bg-gradient-theme-left border-0"
                onClick={this.handleSubmit}
                title="Add Vendor"
              >
                Add
                <div style={this.state.loading ? {} : { display: "none" }} class="image-fill w-25 loader-login-div ">
                  <img src={Loading} alt="No Image" className="can-click " />
                </div>
              </Button>
              &nbsp;&nbsp;
              <Link to={AppConstants.STAGADMIN + "/vendors"} class="btn btn-primary redbtn" title="Cancel Vendor">
                Cancel
              </Link>
            </Col>
          </Row>
          {this.state.errMsg}
        </Form>
      </Page>
    );
  }
}

Addvendor.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

//export default ChangepasswordPage;
export default connect(mapStateToProps, {})(Addvendor);
