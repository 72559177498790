import React from 'react';
import { Link } from 'react-router-dom';
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  FormFeedback,
} from 'reactstrap';

import Page from '../../src/components/Page';
import axios from 'axios';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import AppConstants from '../AppConstants';
//require('dotenv').config();
import {ReactTitle} from 'react-meta-tags';
import ReactDOM from 'react-dom';


class ChangepasswordPage extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      _id:this.props.auth.user._id,
      fields: {},
      errors: {},
      errMsg:''
    }
  }

  handleChange(field, e){    		
    let fields = this.state.fields;
    fields[field] = e.target.value;        
    this.setState({fields});
  }
  /** Validations */
  handleValidation(){
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

   
   
    if(!fields["renewpassword"]){
      formIsValid = false;
      errors["renewpassword"] = "Please re enter new password";
      ReactDOM.findDOMNode(this.refs.renewpassword).focus();
    }
    else if(fields["newpassword"] != fields["renewpassword"]){
      formIsValid = false;
      errors["renewpassword"] = "New password does not match";
      ReactDOM.findDOMNode(this.refs.renewpassword).focus();
    }
    if(!fields["newpassword"]){
      formIsValid = false;
      errors["newpassword"] = "Please enter new password";
      ReactDOM.findDOMNode(this.refs.newpassword).focus();
    }
    if(!fields["oldpassword"]){
      formIsValid = false;
      errors["oldpassword"] = "Please enter current password";
      ReactDOM.findDOMNode(this.refs.oldpassword).focus();
    }
    /* if(fields["newpassword"] != fields["renewpassword"]){
      formIsValid = false;
      errors["renewpassword"] = "New password does not match";
    }  */
    
    

    this.setState({errors: errors});
    return formIsValid;
  }

  handleSubmit = event => {
    event.preventDefault();
    if(this.handleValidation()){
      const changepwd = {
        id:this.props.auth.user._id,
        oldpassword: this.state.fields['oldpassword'],
        newpassword: this.state.fields['newpassword']
      }
      axios.post(AppConstants.API+'/users/changeadminpwd',changepwd)
        .then(res => {   
          console.log(res);   
        if(res.data.Status == "Success"){
          setTimeout((e) => {           
            this.props.history.push(AppConstants.STAGADMIN+'/viewadminprofile');
          }, 1000); 
          this.setState({
            errMsg: res.data.msg
          });
        }
        else{
          this.setState({
            errMsg: res.data.msg
          });
        }
      });
    }
    
  };  
  
  
  render() {
    const {user} = this.props.auth;
    const { open } = this.state;
    console.log(user);
  return (
    <Page title="Change Password">
     <ReactTitle title="Change Password"/>
    <Link to={AppConstants.STAGADMIN+'/dashboard'}>
      Home
    </Link>
    &nbsp; | &nbsp;&nbsp;
    <Link to={AppConstants.STAGADMIN+'/changeadminpassword'}>
    Change Password
    </Link>
    <Form name="changeadminpassword"  className="form-block" onSubmit= {this.handleSubmit.bind(this)}>
      <Row>
        <Col xl={12} lg={12} md={12}>
          <Card>
            <CardBody>
              
            <Row>
        <Col xl={6} lg={6} md={6}>

                <FormGroup>
                  <Label for="examplePassword" >
                    Current Password <span className="error" style={{color: 'red'}}>*</span>
                  </Label>
                  
                    <Input
                      type="password"
                      name="oldpassword"
                      placeholder="current password"
                      onChange={this.handleChange.bind(this, "oldpassword")}
                      value={this.state.fields["oldpassword"]}
                      ref ="oldpassword"   
                    />
                    <span className="error" style={{color: 'red'}}>{this.state.errors["oldpassword"]}</span>
                  
                </FormGroup>
                <FormGroup>
                  <Label for="examplePassword">
                    New Password <span className="error" style={{color: 'red'}}>*</span>
                  </Label>
                  
                    <Input
                      type="password"
                      name="newpassword"
                      placeholder="new password"
                      onChange={this.handleChange.bind(this, "newpassword")}
                      value={this.state.fields["newpassword"]}
                      ref ="newpassword" 
                    />
                    <span className="error" style={{color: 'red'}}>{this.state.errors["newpassword"]}</span>
                  
                </FormGroup>
                <FormGroup>
                  <Label for="examplePassword">
                    Re-enter New Password <span className="error" style={{color: 'red'}}>*</span>
                  </Label>
                  
                    <Input
                      type="password"
                      name="renewpassword"
                      placeholder="re-enter new password"
                      onChange={this.handleChange.bind(this, "renewpassword")}
                      value={this.state.fields["renewpassword"]}
                      ref ="renewpassword" 
                    />
                    <span className="error" style={{color: 'red'}}>{this.state.errors["renewpassword"]}</span>
                  
                </FormGroup>          
               
                </Col>
      </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
      <Col md={12} className="form-btn">
          <Button class="btn btn-primary addbtn" >Submit
            
          </Button> &nbsp;&nbsp;
          {/* <Button class="btn btn-primary" color="danger" onClick={ this.CancelSubmit } title="Cancel Certificate">Cancel</Button> */}
          <Link to={AppConstants.STAGADMIN+"/viewadminprofile"} class="btn btn-primary redbtn" title="Cancel Company">Cancel</Link>
      </Col>
               
      </Row>
      {this.state.errMsg}
      </Form>
    </Page>
  );
};
}

ChangepasswordPage.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors
});

//export default ChangepasswordPage;
export  default connect(mapStateToProps, {})(ChangepasswordPage);
