import Page from '../../../src/components/Page';
import React from 'react';
import { Link } from 'react-router-dom';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { Card, Button, CardBody} from 'reactstrap';
import AppConstants from '../../AppConstants';
import {ReactTitle} from 'react-meta-tags';
import Workbook from 'react-xlsx-workbook';

class Vendorreport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {      
      vendorsreportdata: [],
      errors: {},
      errMsg:false
  };
}

// getImage(cell, row){
//   if(row.companyLogo)
//   return <div className="vendor-image"><img src={row.companyLogo} alt={row.companyLogo} className="img-responsive"/></div>;
//   else
//   return <div className="vendor-image"><img  src={nologo} alt={row.companyLogo} className="img-responsive"/></div>;
//   }

  getCompanyData(cell, row){
    return <div><Link  to={AppConstants.STAGADMIN+"/vendordetails/"+row._id} style={{color:"#212529"}} title="View Vendor Details">{row.vendor_company_name}</Link></div>;
  }
  getLikedrfpvendors(cell, row){
    if(row.vendorscount > 0){
      return <div><Link  to={AppConstants.STAGADMIN+"/likedrfpreport/"+row._id}  title="Liked Opportunities">{row.vendorscount}</Link></div>;
    }
    else
    {
      return <div>{row.vendorscount}</div>;
    }
  }

  getAppliedrfpvendors(cell, row){
    if(row.vendorsappliedcount > 0){
      return <div><Link  to={AppConstants.STAGADMIN+"/appliedrfpreport/"+row._id}  title="Applied Opportunities">{row.vendorsappliedcount}</Link></div>;
    }
    else
    {
      return <div>{row.vendorsappliedcount}</div>;
    }
  }

  getWonrfpvendors(cell, row){
    if(row.vendorwoncount > 0){
      return <div><Link  to={AppConstants.STAGADMIN+"/wonrfpreport/"+row._id}  title="Won Opportunities">{row.vendorwoncount}</Link></div>;
    }
    else
    {
      return <div>{row.vendorwoncount}</div>;
    }
  }

  getwNotonrfpvendors(cell, row){
    if(row.vendornotwoncount > 0){
      return <div><Link  to={AppConstants.STAGADMIN+"/lostrfpreport/"+row._id}  title="Lost Opportunities">{row.vendornotwoncount}</Link></div>;
    }
    else
    {
      return <div>{row.vendornotwoncount}</div>;
    }
  }
  
  componentDidMount() {
    fetch(AppConstants.API+'/reports/getallvendorreport').then(response => response.json())
      .then(data => {
     //console.log(data);
      if(data.Status == "Success")
      {
        this.setState({ vendorsreportdata: data.vendorsreportdata });
      }
      else
      {
        this.setState({ vendorsreportdata: []});
      }    
    });

    var element = document.getElementById("navItem-Companies-1");
    element.classList.remove("active");
    
    var element = document.getElementById("navItem-Certifications and Agencies-5");
    element.classList.remove("active");    
    var element = document.getElementById("navItem-NAICS/NIGP Codes-4");
    element.classList.remove("active");
    var element = document.getElementById("navItem-Opportunities-3");
    element.classList.remove("active");
    var element = document.getElementById("navItem-Vendors-2");
    element.classList.remove("active");
    var element = document.getElementById("navItem-Company Types-0");
    element.classList.remove("active");
    var element = document.getElementById("navItem-Industry Types-1");
    element.classList.remove("active");
    var element = document.getElementById("navItem-Reports-6");
    element.classList.add("active"); 
  }

  createCustomInsertButton=(click)=>{
    return(<span>{this.state.vendorsreportdata.length > 0 ?
        <Workbook title="Export Vendors" filename="Export Vendors.xlsx" element={<span className="btn btn-secondary editbtn" title="Export Vendors">Export</span>}>
        <Workbook.Sheet data={this.state.vendorsreportdata} name="Sheet A">
            <Workbook.Column label="Company Name" value="vendor_company_name"/>
            <Workbook.Column label="Liked Opportunities" value="vendorscount"/>
            <Workbook.Column label="Applied Opportunities" value="vendorsappliedcount"/>
            <Workbook.Column label="Won Opportunities" value="vendorwoncount"/>
            <Workbook.Column label="Lost Opportunities" value="vendornotwoncount"/>
        </Workbook.Sheet>
        </Workbook> :""}</span>)
}
  
   render() {
    if(localStorage.jwtTokenAdmin != undefined)
    {
    const options = {
      exportCSVText: 'Export CSV',    
      insertText: "New",    
      deleteText: 'Delete',    
      saveText: 'Save',    
      closeText: 'Close',
      insertBtn: this.createCustomInsertButton,
      page: 1,  // which page you want to show as default
      sizePerPageList: [ {
        text: '5', value: 5
      }, {
        text: '10', value: 10
      }, {
        text: '20', value: 20
      },
      {
        text: '50', value: 50
      }
    ], // you can change the dropdown list for size per page
      sizePerPage: 10,  // which size per page you want to locate as default
      pageStartIndex: 1, // where to start counting the pages
      paginationSize: 3,  // the pagination bar size.
     // prePage: 'Prev', // Previous page button text
      //nextPage: 'Next', // Next page button text
      //firstPage: 'First', // First page button text
      //lastPage: 'Last', // Last page button text
      paginationShowsTotal: this.state.vendorsreportdata.length,  // Accept bool or function
      paginationPosition: 'bottom',  // default is bottom, top and both is all available
      // hideSizePerPage: true > You can hide the dropdown for sizePerPage
      // alwaysShowAllBtns: true // Always show next and previous button
      // withFirstAndLast: false > Hide the going to First and Last page button
      insertModal: this.createCustomModal    
    };
    
    return (
        <Page title="Vendor Report">
         <ReactTitle title="Vendors Report"/>
        <Link to={AppConstants.STAGADMIN+'/dashboard'}>
          Home
          </Link>
          &nbsp; | &nbsp;&nbsp;
          <Link to={AppConstants.STAGADMIN+'/reports'}>
          Reports
          </Link>
            <row>
              <Card className="data-table">
                <CardBody>
                  <div>
                  <span className="error" style={{color: 'red'}}>{this.state.errMsg}</span>
                  <BootstrapTable data={ this.state.vendorsreportdata } search={ true } pagination={ true } multiColumnSearch={ true } options={ options } insertRow
                  >
                    <TableHeaderColumn dataField='_id' isKey={ true } searchable={ false } dataSort={ true } hidden={true}>ID</TableHeaderColumn> 

                    {/* <TableHeaderColumn dataField='companyLogo' dataFormat={this.getImage.bind(this)} dataSort={ true } dataSort={ true }>Company Logo</TableHeaderColumn> */}
                    <TableHeaderColumn dataField='vendor_company_name' dataFormat={this.getCompanyData.bind(this)} dataSort={ true }>Company Name</TableHeaderColumn>                 

                    <TableHeaderColumn dataField='vendorscount' dataFormat={this.getLikedrfpvendors.bind(this)} export={ false }>Liked Opportunities</TableHeaderColumn>

                    <TableHeaderColumn dataField='vendorsappliedcount' dataFormat={this.getAppliedrfpvendors.bind(this)} export={ false }>Applied Opportunities</TableHeaderColumn>

                    <TableHeaderColumn dataField='vendorwoncount' dataFormat={this.getWonrfpvendors.bind(this)} export={ false }>Won Opportunities</TableHeaderColumn>

                    <TableHeaderColumn dataField='vendornotwoncount' dataFormat={this.getwNotonrfpvendors.bind(this)} export={ false }>Lost Opportunities</TableHeaderColumn>
                    
                  </BootstrapTable>
                </div>
                </CardBody>
              </Card>
            </row>
          </Page>
      );
    }
    else 
    {
        window.location.href = AppConstants.STAGADMIN+'/'
    }
  }

};

export default Vendorreport;