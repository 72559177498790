import Page from '../../../src/components/Page';
import React from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import { BootstrapTable, TableHeaderColumn, InsertButton } from 'react-bootstrap-table';
//import { AddCompany, DataFormat } from 'components/NewUsers';
import { Card, Button, CardBody, CardHeader, Col, Row, Table, Modal,
  ModalBody,
  ModalFooter,
  ModalHeader } from 'reactstrap';
import {
    MdCreate,
    MdDelete,
    MdRemoveRedEye,MdAdd
  } from 'react-icons/md';
import AppConstants from '../../AppConstants';
import {ReactTitle} from 'react-meta-tags';

class Customers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {      
      customersdata: [],
      errors: {},
      errMsg:false,
      totalDataSize: 0,
      sizePerPage: 5,
      currentPage: 1,
      sortName: '',
      sortOrder: '',
      searchText: '',
      activeKey: '1'
  };
  this.format = this.format.bind(this);
  this.onPageChange = this.onPageChange.bind(this);
  this.onSizePerPageList = this.onSizePerPageList.bind(this);
  this.onSortChange = this.onSortChange.bind(this);
  this.onSearchChange = this.onSearchChange.bind(this);
}
  DeleteCustomer = params => e => {  
    e.preventDefault();
    if(window.confirm("Are you sure you want to delete this customer?"))
    {
      //console.log('the item to delete is: ', params);
      const customerdeletedata = {
        id:params
      }
      axios.post(AppConstants.API+'/cutomers/deletcutomer',customerdeletedata)
        .then(res => {
          setTimeout(() => {
            this.setState({
              errMsg: false,
            });
          }, 1000);   
          //console.log(res);   
        if(res.data.Status == "Success"){                           
          this.setState({
            errMsg: res.data.msg
          });
          this.updateCompaniesList(this.state.currentPage, this.state.sizePerPage, this.state.sortName, this.state.sortOrder, this.state.searchText);
        }
        else{
          this.setState({
            errMsg: res.data.msg
          });
        }
      });
    }
  };
  handleSelect(activeKey) {
    this.setState({ activeKey });
  }
  onPageChange(page, sizePerPage) {
    this.updateCompaniesList(page, sizePerPage, this.state.sortName, this.state.sortOrder, this.state.searchText);
  }
  onSizePerPageList(sizePerPage) {
    let page = 1;
    this.updateCompaniesList(page, sizePerPage, this.state.sortName, this.state.sortOrder, this.state.searchText);
  }
  onSortChange(sortName, sortOrder) {
   // console.log('sort name: '+sortName);
    //console.log('sort order: '+sortOrder);
    let page = 1;
    this.updateCompaniesList(page, this.state.sizePerPage, sortName, sortOrder, this.state.searchText);
  }
  onSearchChange(searchText, colInfos, multiColumnSearch) {
     // console.log('search text: '+searchText);
      let page = 1;
      this.updateCompaniesList(page, this.state.sizePerPage, this.state.sortName, this.state.sortOrder, searchText);
  }

format(cell, row){
  return <div className="ca-controls"><Link to={AppConstants.STAGADMIN+"/editcustomer/"+row._id} class="btn btn-secondary editbtn" title="Edit Company" style={{fontSize:"15px"}}>Edit</Link>
  {/*<span onClick={ this.DeleteCustomer(row._id) } class="text-muted" title="Delete Company"><MdDelete /></span> <a href="#" className="text-muted"><MdRemoveRedEye /></a> */}
  <Link to={AppConstants.STAGADMIN+"/viewcustomer/"+row._id} className="btn btn-success viewbtn" title="View Company Details" style={{fontSize:"15px"}}>View</Link> 
  </div>;
}

  createCustomInsertButton = (onClick) => {
    return (
        <span> &nbsp; <Link to={AppConstants.STAGADMIN+"/addcustomer"} class="btn btn-warning addbtn" title="Add Company">Add</Link></span>
    );
  }
  componentDidMount() {
    this.updateCompaniesList(this.state.currentPage, this.state.sizePerPage, this.state.sortName, this.state.sortOrder, this.state.searchText); 
    /* fetch(AppConstants.API+'/cutomers/getallcutomers').then(response => response.json())
      .then(data => {
     // console.log(data);
      if(data.Status == "Success")
      {
        this.setState({ customersdata: data.customersdata });
      }
      else
      {
        this.setState({ customersdata: "" });
      }    
    });  */
  }
  updateCompaniesList(page, sizePerPage, sortName, sortOrder, searchText){
    //console.log(this.state);
    let tablefilterdata = {
      page: page,
      sizeperpage: sizePerPage,
      sortname: sortName,
      sortorder: sortOrder,
      serchtext: searchText
    };
    axios.post(AppConstants.API+'/cutomers/getadminallcutomers', tablefilterdata)
    .then(res => {
        if (res.data.Status == 'Success') {
            //console.log(res.data);
             this.setState({
              customersdata: res.data.customersdata,
              totalDataSize: res.data.total_records,
              currentPage: page,
              sizePerPage: sizePerPage,
              sortName: sortName,
              sortOrder: sortOrder,
              serchtext: searchText
            });
        }
    });
    var element = document.getElementById("navItem-Companies-1");
    element.classList.add("active");
    
    var element = document.getElementById("navItem-Certifications and Agencies-5");
    element.classList.remove("active");    
    var element = document.getElementById("navItem-NAICS/NIGP Codes-4");
    element.classList.remove("active");
    var element = document.getElementById("navItem-Opportunities-3");
    element.classList.remove("active");
    var element = document.getElementById("navItem-Vendors-2");
    element.classList.remove("active");
    var element = document.getElementById("navItem-Company Types-0");
    element.classList.remove("active");
    var element = document.getElementById("navItem-Industry Types-1");
    element.classList.remove("active");
    var element = document.getElementById("navItem-Reports-6");
    element.classList.remove("active"); 

  }
   render() {
    if(localStorage.jwtTokenAdmin != undefined)
    {
    const options = {
      exportCSVText: 'Export CSV',    
      insertText: "New",    
      deleteText: 'Delete',    
      saveText: 'Save',    
      closeText: 'Close',
      insertBtn: this.createCustomInsertButton,
      //page: 1,  // which page you want to show as default
      page: this.state.currentPage,  // which page you want to show as default
      sizePerPageList: [ 5, 10, 20, 50], // you can change the dropdown list for size per page
      sizePerPage: this.state.sizePerPage,  // which size per page you want to locate as default
      pageStartIndex: 1, // where to start counting the pages
      paginationSize: 3,  // the pagination bar size.
     // prePage: 'Prev', // Previous page button text
      //nextPage: 'Next', // Next page button text
      //firstPage: 'First', // First page button text
      //lastPage: 'Last', // Last page button text
      paginationShowsTotal: this.state.customersdata.length,  // Accept bool or function
      paginationPosition: 'bottom',  // default is bottom, top and both is all available
      // hideSizePerPage: true > You can hide the dropdown for sizePerPage
      // alwaysShowAllBtns: true // Always show next and previous button
      // withFirstAndLast: false > Hide the going to First and Last page button
      
      onPageChange: this.onPageChange,
      onSizePerPageList: this.onSizePerPageList,
      onSortChange: this.onSortChange,
      onSearchChange: this.onSearchChange,
      insertModal: this.createCustomModal,
    };
    return (
        <Page title="Companies">
        <ReactTitle title="Companies"/>
          <Link to={AppConstants.STAGADMIN+'/dashboard'}>
          Home
          </Link>
          &nbsp; | &nbsp;&nbsp;
          <Link to={AppConstants.STAGADMIN+'/customers'}>
          Companies
          </Link>
            <row>
              <Card className="data-table">
                <CardBody>
                  <div>
                  <span className="error" style={{color: 'red'}}>{this.state.errMsg}</span>
                  <BootstrapTable data={ this.state.customersdata } remote = { true } search={ true } pagination={ true } multiColumnSearch={ true } fetchInfo={ { dataTotalSize: this.state.totalDataSize }} options={ options }
                  insertRow
                  >
                    <TableHeaderColumn dataField='_id' isKey={ true } searchable={ false } dataSort={ true } hidden={true}>Customer ID</TableHeaderColumn>
                    <TableHeaderColumn dataField='company_name' dataSort={ true }>Company Name</TableHeaderColumn>
                    <TableHeaderColumn dataField='company_email' dataSort={ true }>Company Email</TableHeaderColumn>
                    <TableHeaderColumn dataField='status' width={'15%'} dataSort={ true }>Status</TableHeaderColumn>
                    <TableHeaderColumn dataField='actions' width={'25%'} dataFormat={this.format.bind(this)} export={ false }>Actions</TableHeaderColumn>
                  </BootstrapTable>
                </div>
                </CardBody>
              </Card>
            </row>
          </Page>
      );
    }
    else 
    {
        window.location.href = AppConstants.STAGADMIN+'/'
    }
  }

};

export default Customers;
