import React from 'react';
import { Link } from 'react-router-dom';
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  FormFeedback,
} from 'reactstrap';
import Page from '../../../src/components/Page';
import axios from 'axios';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import AppConstants from '../../AppConstants';
import Loading from '../../assets/img/loader.gif';
import {ReactTitle} from 'react-meta-tags';
import ReactDOM from 'react-dom';



class EditNigpnaic extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      _id:this.props.auth.user._id,
      id:this.props.match.params.id,
      NAICS: "",
      NAICS_Description: "",
      status:'',   
      errors: {},
      errMsg:'',
      loading: '',
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this); 
  }
  
  handleInputChange(e) {
    this.setState({
        [e.target.name]: e.target.value
    });
    //console.log(this.state);
  }
  
  /** Validations */
  handleValidation(){
    let errors = {};
    let formIsValid = true;
    if (!this.state.NAICS) {
        formIsValid = false;
        errors["NAICS"] = "Please enter naics code";
        ReactDOM.findDOMNode(this.refs.NAICS).focus();
      }
  
      if (!this.state.NAICS_Description) {
        formIsValid = false;
        errors["NAICS_Description"] = "Please enter description";
        ReactDOM.findDOMNode(this.refs.NAICS_Description).focus();
      }

    this.setState({errors: errors});
    return formIsValid;
  }

  handleSubmit = event => {
    event.preventDefault();
    //alert('form submitted');    
     if(this.handleValidation()){
      this.setState({loading:1});   
      const codedata = {
        id:this.props.match.params.id,
        NAICS: this.state.NAICS,
        NAICS_Description: this.state.NAICS_Description,
        status: this.state.status
      }
      axios.post(AppConstants.API+'/users/editNaicAndNigp',codedata)
        .then(res => {   
          if(res.data.Status == "Success"){
          setTimeout((e) => {           
            this.props.history.push(AppConstants.STAGADMIN + "/nigpnaics");
          }, 2000)               
          this.setState({
            //errMsg: res.data.msg
            errMsg: (<div className="alert alert-success fade show mt-3">{res.data.msg}</div>)
          });
        }
        else{
          this.setState({loading:''});
          setTimeout((e) => {
            this.setState({
              errMsg: ''
            });
           },2000);     
          this.setState({
            //errMsg: res.data.msg
            errMsg: (<div className="alert alert-danger fade show mt-3">{res.data.msg}</div>)
          });
        }
      });
    } 
  };
  
  componentDidMount() {
    //console.log(this.props.match.params.id);
    const that = this; 
    fetch(AppConstants.API+'/users/getnaiccodebyid/'+this.props.match.params.id)
    .then(function(res) {
      //console.log(res.status);
      if(res.status == 200){
        return res.json();
      }
    })
    .then(function(data) {
      console.log(data,"dataaaaaaaaa")
      if(data.Status == "Success"){
        that.setState({
            NAICS:data.naiccode.NAICS,
            NAICS_Description:data.naiccode.NAICS_Description,
            status:data.naiccode.status
        });
      }else
      {
        //console.log('invalid data');
        this.setState({
          errMsg: data.msg
        });
      }
    });
    var element = document.getElementById("navItem-Industry Types-1");
    element.classList.add("active");
  }
  render() {
  const {user} = this.props.auth;
  return (
    <Page title="Edit NIGP/NAICS ">
    <ReactTitle title="Edit NIGP/NAICS"/>
       <Link to={AppConstants.STAGADMIN+'/dashboard'}>
        Home
        </Link>
        &nbsp; | &nbsp;&nbsp;
        <Link to={AppConstants.STAGADMIN + "/nigpnaics"}>NAICS/NIGP Codes</Link>

        <Form className="form-block" name="changeadminpassword" onSubmit= { this.handleSubmit }>
      <Row>
        <Col xl={12} lg={12} md={12}>
          <Card>
            <CardBody>
              
            <FormGroup>
                        <Label for="examplePassword">
                          NAICS{" "}
                          <span className="error" style={{ color: "red" }}>
                            *
                          </span>
                        </Label>
                        <Input
                          type="text"
                          name="NAICS"
                          placeholder="Please enter nigp code"
                          onChange={this.handleInputChange}
                          value={this.state.NAICS}
                          ref="NAICS"
                        />
                        <span className="error" style={{ color: "red" }}>
                          {this.state.errors["NAICS"]}
                        </span>
                      </FormGroup>

                      <FormGroup>
                        <Label for="examplePassword">
                          NAICS_Description
                          <span className="error" style={{ color: "red" }}>
                            *
                          </span>
                        </Label>
                        <Input
                          type="text"
                          name="NAICS_Description"
                          placeholder="Please enter description"
                          onChange={this.handleInputChange}
                          ref="NAICS_Description"
                          value={this.state.NAICS_Description}
                        />
                        <span className="error" style={{ color: "red" }}>
                          {this.state.errors["NAICS_Description"]}
                        </span>
                      </FormGroup>    
                      
                  <FormGroup>
                    <Label for="exampleSelect">Status</Label>
                    <Input type="select" name="status" value={this.state.status} onChange={this.handleInputChange}>
                      <option value="Active">Active</option>
                      <option value="In Active">In Active</option>
                    </Input>
                  </FormGroup>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
      <Col md={12} className="form-btn">
                    <Button class="btn btn-primary addbtn" className="bg-gradient-theme-left border-0" onClick={ this.handleSubmit } title="Update Industry">Update
                      <div style={this.state.loading ? {} : { display: 'none' }} class="image-fill w-25 loader-login-div "><img src={Loading} alt="No Image" className="can-click " /></div>
                    </Button>&nbsp;&nbsp;
                    <Link to={AppConstants.STAGADMIN + "/nigpnaics"} class="btn btn-primary redbtn" title="Cancel Industry">Cancel</Link>
                  </Col>
                
        </Row>
        {this.state.errMsg}
     </Form>
    </Page>
  );
};
}

EditNigpnaic.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors
});

//export default ChangepasswordPage;
export  default connect(mapStateToProps, {})(EditNigpnaic);