import React from "react";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  FormFeedback,
} from "reactstrap";
import Select from "react-select";
import Page from "../../../src/components/Page";
import axios from "axios";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";
import MomentLocaleUtils, { formatDate } from "react-day-picker/moment";
import AppConstants from "../../AppConstants";
import NumberFormat from "react-number-format";
import { Typeahead } from "react-bootstrap-typeahead";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Loading from "../../assets/img/loader.gif";
import { ReactTitle } from "react-meta-tags";
import ReactDOM from "react-dom";

var duedate = new Date();
duedate.setDate(duedate.getDate() + 7);

var deadlinedate = new Date();
deadlinedate.setDate(deadlinedate.getDate() + 3);

var uncheckedids = [];
var certification_newids = [];
class Editrfprfi extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.id,
      name_ofrfp: "",
      customer_id: "",
      //"due_dateandtime": '',
      due_dateandtime: duedate,
      description: "",
      department: "",
      bid_link: "",
      naic_code: [],
      contact_first_name: "",
      contact_last_name: "",
      contact_phonenumber: "",
      contact_email: "",
      //"minority_or_women_owned_business": false,
      minority_business: false,
      women_owned_business: false,
      checked: false,
      //"need_certifications": false,
      need_certifications: "No",
      checked1: false,
      checked2: false,
      //"certification_ids": [],
      certifications: [],
      certificationsdata: [],
      viewedrfprfp_vendorids: [],
      likedrfprfp_vendorids: [],
      interestedrfprfp_vendorids: [],
      documents: "",
      view_document: "",
      prev_document: "",
      //"rfp_post_date": '',
      //"deadline_for_questions": '',
      rfp_post_date: new Date(),
      deadline_for_questions: deadlinedate,
      bid_rfp_reference_number: "",
      department_min_revenue: "",
      department_max_revenue: "",
      status: "",
      created_by_rfprfi_admin: this.props.auth.user._id,
      updated_by_rfprfi_admin: this.props.auth.user._id,
      errors: {},
      errMsg: "",
      naiccodes: [],
      customersdata: [],
      getfprfiprofiledata: [],
      sendmail: "",
      loading: "",
      //"selected_naic_codes":[]
      certification_documents: [],
      multidocs: [],
      multidocids: [],
      certificationid: [],
      rfprfiid: this.props.match.params.id,
      certify: "",
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleCheckChange = this.handleCheckChange.bind(this);
    this.handleDayChange = this.handleDayChange.bind(this);
    this.handleDayChange1 = this.handleDayChange1.bind(this);
    this.handleDayChange2 = this.handleDayChange2.bind(this);
    this.handleInputFileChange = this.handleInputFileChange.bind(this);
    this.handleInputChangeFund = this.handleInputChangeFund.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChange1 = this.handleChange1.bind(this);
    this.handleChange2 = this.handleChange2.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handlePhoneChange = this.handlePhoneChange.bind(this);
  }

  handlePhoneChange(e) {
    const re1 = /^[0-9)\(+ '-]+$/g;
    // var phone_num = e.target.value.replace(/\D/g,'');
    if (e.target.value === "" || re1.test(e.target.value)) {
      // phone_num = this.phoneFormat(phone_num)
      this.setState({
        [e.target.name]: e.target.value,
      });
    }
  }

  phoneFormat(input) {
    // Strip all characters from the input except digits
    // Trim the remaining input to ten characters, to preserve phone number format
    input = input.substring(0, 10);
    // Based upon the length of the string, we add formatting as necessary
    var size = input.length;
    if (size == 0) {
      input = input;
    } else if (size < 4) {
      input = "(" + input;
    } else if (size < 7) {
      input = "(" + input.substring(0, 3) + ") " + input.substring(3, 6);
    } else {
      input = "(" + input.substring(0, 3) + ") " + input.substring(3, 6) + "-" + input.substring(6, 10);
    }
    return input;
  }

  handleClick(e) {
    if (e.target.checked) {
      certification_newids.push(e.target.value);
      document.getElementById("cert_" + e.target.value).style.display = "block";
    } else {
      document.getElementById("cert_" + e.target.value).style.display = "none";
    }
  }

  handleFileChange(e) {
    var newdata = e.target.id.split("_");
    certification_newids.push(newdata[1]);

    //console.log(e.target.files[0]);

    this.setState({ [e.target.name]: e.target.files[0] });
    let formData = new FormData();

    formData.append("rfprfiid", this.state.rfprfiid);
    formData.append("certification_documents", e.target.files[0]);
    formData.append("certificationid", JSON.stringify(certification_newids));

    axios.post(AppConstants.API + "/rfprfiprofiles/addcertificate", formData).then((res) => {
      //console.log(res);
      if (res.data.Status == "certifiedSuccess") {
        this.setState({
          rfprfiid: res.data.rfprfiid,
        });
      }
    });
  }

  handleInputFileChange(e) {
    console.log(e);
    this.setState({
      [e.target.name]: e.target.files[0],
    });
  }

  handleChange(date) {
    this.setState({
      rfp_post_date: date,
    });
  }
  handleChange1(date) {
    this.setState({
      due_dateandtime: date,
    });
  }
  handleChange2(date) {
    this.setState({
      deadline_for_questions: date,
    });
  }
  //checkbox
  handleCheckClickMinority = (e) => {
    if (this.state.checked == false) {
      this.setState({
        checked: !this.state.checked,
        minority_business: !this.state.checked,
      });
    } else {
      this.setState({
        checked: !this.state.checked,
        minority_business: !this.state.checked,
      });
    }
  };
  //checkbox handleCheckClickWomen
  handleCheckClickWomen = (e) => {
    if (this.state.checked2 == false) {
      this.setState({
        checked2: !this.state.checked2,
        women_owned_business: !this.state.checked2,
      });
    } else {
      this.setState({
        checked2: !this.state.checked2,
        women_owned_business: !this.state.checked2,
      });
    }
  };
  handleInputChangeFund(e) {
    // console.log(e.target)
    //console.log(this.state.certifications)
    // console.log(this.state.certificationsdata)
    const certifications = this.state.certifications;
    let index;
    // check if the check box is checked or unchecked
    if (e.target.checked) {
      // add the numerical value of the checkbox to options array
      //this.setState({certifications:e.target.value})
      certifications.push(e.target.value);
      // this.setState({[e.target.name]:e.target.value})
      //console.log(certifications)
    } else {
      console.log(e.target.value);
      var newids = e.target.value;
      uncheckedids.push(newids);
      // or remove the value from the unchecked checkbox from the array
      index = certifications.indexOf(e.target.value);
      certifications.splice(index, 1);
      //console.log(certifications)
    }
    //console.log(certifications);
    // update the state with the new array of options
    this.setState({ certifications: certifications });
  }
  handleCheckClickCertification = (e) => {
    //console.log(e.target);
    //console.log(this.state.checked1)
    // console.log(this.state.certificationsdata)
    // if(this.state.checked1 == false) {
    //   this.setState({
    //     checked1: !this.state.checked1,
    //     need_certifications:!this.state.checked1,

    //    // certifications:''
    //   });
    // }else{
    //   this.setState({
    //     checked1: !this.state.checked1,
    //     need_certifications:!this.state.checked1,
    //     //checked: !this.state.checked1
    //     certifications:[],
    //     //allcerificationdata:[],
    //     //certification_documents:[]
    //   });
    // }

    if (e.target.value == "Yes") {
      this.setState({
        need_certifications: e.target.value,
      });
    } else {
      this.setState({
        need_certifications: e.target.value,
        certifications: [],
      });
    }
  };

  handleCheckChange(event) {
    //console.log(event.target);
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    //console.log("welcome ", name);
    this.setState({
      [name]: value,
    });
  }

  handleDayChange(day) {
    this.setState({ rfp_post_date: day });
  }
  handleDayChange1(day) {
    this.setState({ deadline_for_questions: day });
  }
  handleDayChange2(day) {
    this.setState({ due_dateandtime: day });
  }

  handleInputChange(e) {
    //console.log(this.state.revanuerange);
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  /** Validations */
  handleValidation() {
    let errors = {};
    let formIsValid = true;

    //Email
    //console.log(this.state.rfp_post_date)

    var date = new Date(this.state.rfp_post_date);
    //console.log(date)
    //console.log(new Date(this.state.due_dateandtime))
    // console.log(new Date(this.state.deadline_for_questions))
    date.setDate(date.getDate() + 6);

    //   if(this.state.due_dateandtime<this.state.rfp_post_date){
    // 	formIsValid = false;
    // 	errors["due_dateandtime"] = "Due date greater than post date";
    // }
    if (this.state.need_certifications == "Yes") {
      if (this.state.certifications.length == 0) {
        formIsValid = false;
        errors["certify"] = "please select atleast one certification";
      }
    }

    if (!this.state.naic_code.length) {
      formIsValid = false;
      errors["naic_code"] = "Please select at-least one NAICS Code";
      ReactDOM.findDOMNode(this.refs.naiccode).focus();
    }

    // if(new Date(this.state.rfp_post_date)>new Date(this.state.deadline_for_questions)){
    //   formIsValid = false;
    //   errors["deadline_for_questions"] = "Deadline for questions in between posted date and due date";
    // }
    // if(new Date(this.state.deadline_for_questions) >new Date(this.state.due_dateandtime)){
    //   formIsValid = false;
    //   errors["deadline_for_questions"] = "Deadline for questions  in between posted date and due date";
    // }
    // if(!this.state.rfp_post_date&&!this.state.due_dateandtime){
    //   formIsValid = false;
    //   errors["deadline_for_questions"] = "Please select posted date and due date";
    // }
    // if(this.state.rfp_post_date&&!this.state.due_dateandtime){
    //   formIsValid = false;
    //   errors["deadline_for_questions"] = "Please select due date";
    // }
    // if(!this.state.rfp_post_date&&this.state.due_dateandtime){
    //   formIsValid = false;
    //   errors["deadline_for_questions"] = "Please select posted date";
    // }
    // if(this.state.due_dateandtime<date){
    //   formIsValid = false;
    //   errors["due_dateandtime"] = "Due date must be 7 days greater than post date";
    // }
    if (!this.state.due_dateandtime) {
      formIsValid = false;
      errors["due_dateandtime"] = "Please select due date";
    }
    if (!this.state.rfp_post_date) {
      formIsValid = false;
      errors["rfp_post_date"] = "Please select posted date";
    }
    // if(!this.state.deadline_for_questions){
    //   formIsValid = false;
    //   errors["deadline_for_questions"] = "Please select dead line for questions";
    // }

    // if(!this.state.contact_email){
    //   formIsValid = false;
    //   errors["contact_email"] = "Please enter email";
    //   ReactDOM.findDOMNode(this.refs.email).focus();
    // }
    // if(typeof this.state.contact_email !== "undefined"){
    //   let lastAtPos = this.state.contact_email.lastIndexOf('@');
    //   let lastDotPos = this.state.contact_email.lastIndexOf('.');

    //   if (!(lastAtPos < lastDotPos && lastAtPos > 0 && this.state.contact_email.indexOf('@@') == -1 && lastDotPos > 2 && (this.state.contact_email.length - lastDotPos) > 2)) {
    //     formIsValid = false;
    //     errors["contact_email"] = "Please enter valid email";
    //   ReactDOM.findDOMNode(this.refs.email).focus();
    //   }
    // }
    // if(!this.state.contact_phonenumber){
    //   formIsValid = false;
    //   errors["contact_phonenumber"] = "Please enter phone number";
    //   ReactDOM.findDOMNode(this.refs.phoneno).focus();
    // }
    //else if(this.state.contact_phonenumber.length !=14){
    //   formIsValid = false;
    //   errors["contact_phonenumber"] = "Please enter 10 digits phone number";
    //   ReactDOM.findDOMNode(this.refs.phoneno).focus();
    // }
    if (!this.state.contact_last_name) {
      formIsValid = false;
      errors["contact_last_name"] = "Please enter last name";
      ReactDOM.findDOMNode(this.refs.lastname).focus();
    }
    if (!this.state.contact_first_name) {
      formIsValid = false;
      errors["contact_first_name"] = "Please enter first name";
      ReactDOM.findDOMNode(this.refs.firstname).focus();
    }

    // if(!this.state.bid_link){
    //   formIsValid = false;
    //   errors["bid_link"] = "Please enter bid link";
    //   ReactDOM.findDOMNode(this.refs.bid_link).focus();
    // }
    // if(this.state.bid_link && !/^https?:\/\//i.test(this.state.bid_link)){
    //   formIsValid = false;
    //   errors["bid_link"] = "Please enter valid bid link";
    //   ReactDOM.findDOMNode(this.refs.bid_link).focus();
    // }
    if (!this.state.department) {
      formIsValid = false;
      errors["department"] = "Please enter department";
      ReactDOM.findDOMNode(this.refs.department).focus();
    }
    if (!this.state.customer_id) {
      formIsValid = false;
      errors["customer_id"] = "Please select company";
      ReactDOM.findDOMNode(this.refs.customer_id).focus();
    }
    if (!this.state.description) {
      formIsValid = false;
      errors["description"] = "Please enter description";
      ReactDOM.findDOMNode(this.refs.description).focus();
    }
    if (!this.state.name_ofrfp) {
      formIsValid = false;
      errors["name_ofrfp"] = "Please enter name of opportunity";
      ReactDOM.findDOMNode(this.refs.name_ofrfp).focus();
    }

    if (this.state.documents && this.state.documents.name && !this.state.documents.name.match(/.(pdf|doc|docx)$/i)) {
      formIsValid = false;
      errors["documents"] = "Please upload pdf or document";
      ReactDOM.findDOMNode(this.refs.documents).focus();
    }

    this.setState({ errors: errors });
    return formIsValid;
  }

  handleSubmit = (event) => {
    event.preventDefault();
    if (this.handleValidation()) {
      this.setState({ loading: 1 });
      let formData = new FormData();
      formData.append("id", this.props.match.params.id);
      formData.append("name_ofrfp", this.state.name_ofrfp);
      formData.append("customer_id", this.state.customer_id);
      formData.append("due_dateandtime", this.state.due_dateandtime);
      formData.append("description", this.state.description);
      formData.append("department", this.state.department);
      formData.append("bid_link", this.state.bid_link);
      //formData.append('naic_code', this.state.naic_code);
      formData.append("naic_code", JSON.stringify(this.state.naic_code));
      formData.append("contact_first_name", this.state.contact_first_name);
      formData.append("contact_last_name", this.state.contact_last_name);
      formData.append("contact_phonenumber", this.state.contact_phonenumber);
      formData.append("contact_email", this.state.contact_email);
      //formData.append('minority_or_women_owned_business', this.state.minority_or_women_owned_business);
      formData.append("minority_business", this.state.minority_business);
      formData.append("women_owned_business", this.state.women_owned_business);
      formData.append("certifications", JSON.stringify(this.state.certifications));
      formData.append("need_certifications", this.state.need_certifications);
      formData.append("prev_document", this.state.prev_document);
      formData.append("documents", this.state.documents);
      formData.append("rfp_post_date", this.state.rfp_post_date);
      formData.append("deadline_for_questions", this.state.deadline_for_questions);
      formData.append("bid_rfp_reference_number", this.state.bid_rfp_reference_number);
      formData.append("department_min_revenue", this.state.department_min_revenue);
      formData.append("department_max_revenue", this.state.department_max_revenue);
      formData.append("status", this.state.status);
      formData.append("created_by_rfprfi_admin", this.state.created_by_rfprfi_admin);
      formData.append("updated_by_rfprfi_admin", this.state.updated_by_rfprfi_admin);
      formData.append("sendmail", this.state.sendmail);
      formData.append("uncheckedids", JSON.stringify(uncheckedids));
      //console.log(updaterfprfidata);
      axios.post(AppConstants.API + "/rfprfiprofiles/editrfprfiprofile", formData).then((res) => {
        //console.log(res);
        if (res.data.Status == "Success") {
          setTimeout((e) => {
            this.props.history.push(AppConstants.STAGADMIN + "/rfprfiprofiles");
          }, 2000);
          this.setState({
            //errMsg: res.data.msg
            errMsg: <div className="alert alert-success fade show mt-3">{res.data.msg}</div>,
          });
        } else {
          this.setState({ loading: "" });
          this.setState({
            //errMsg: res.data.msg
            errMsg: <div className="alert alert-danger fade show mt-3">{res.data.msg}</div>,
          });
        }
      });
    }
  };

  componentDidMount() {
    //Get Rfp Rfi profile data
    fetch(AppConstants.API + "/rfprfiprofiles/getrfprfiprofiledetails/" + this.props.match.params.id)
      .then((response) => response.json())
      .then((data) => {
        if (data.Status == "Success") {
          var multidocs = [];
          var multidocids = [];
          if (data.getfprfiprofiledata.certification_documents.length > 0) {
            var allcerificationdata = data.getfprfiprofiledata.certification_documents;
            allcerificationdata.map((alldata) => {
              var idsObj = {};
              multidocids.push(alldata.certificationid);
              multidocs.push(alldata.certificationurl);
            });
            this.setState({
              multidocids: multidocids,
              multidocs: multidocs,
            });
          }

          this.setState({
            name_ofrfp: data.getfprfiprofiledata.name_ofrfp,
            contact_first_name: data.getfprfiprofiledata.contact_first_name,
            contact_last_name: data.getfprfiprofiledata.contact_last_name,
            contact_phonenumber: data.getfprfiprofiledata.contact_phonenumber,
            contact_email: data.getfprfiprofiledata.contact_email,
            rfp_post_date: new Date(data.getfprfiprofiledata.rfp_post_date),
            due_dateandtime: new Date(data.getfprfiprofiledata.due_dateandtime),
            description: data.getfprfiprofiledata.description,
            customer_id: data.getfprfiprofiledata.customer_id,
            department: data.getfprfiprofiledata.department,
            bid_link: data.getfprfiprofiledata.bid_link,
            // naic_code: data.getfprfiprofiledata.naic_code,
            deadline_for_questions: new Date(data.getfprfiprofiledata.deadline_for_questions),
            //minority_or_women_owned_business: data.getfprfiprofiledata.minority_or_women_owned_business,
            minority_business: data.getfprfiprofiledata.minority_business,
            checked: data.getfprfiprofiledata.minority_business,

            women_owned_business: data.getfprfiprofiledata.women_owned_business,
            checked2: data.getfprfiprofiledata.women_owned_business,

            need_certifications: data.getfprfiprofiledata.need_certifications,

            checked1: data.getfprfiprofiledata.need_certifications,

            bid_rfp_reference_number: data.getfprfiprofiledata.bid_rfp_reference_number,
            status: data.getfprfiprofiledata.status,
            documents: data.getfprfiprofiledata.documents,
            prev_document: data.getfprfiprofiledata.documents,
            view_document: data.getfprfiprofiledata.documents,
            naic_code: data.getfprfiprofiledata.selected_naic_codes,
            certifications: data.getfprfiprofiledata.certification_ids,
            allcerificationdata: data.getfprfiprofiledata.certification_documents,
          });
        } else {
          this.setState({ getfprfiprofiledata: "" });
        }
      });
    //Get all customers
    fetch(AppConstants.API + "/cutomers/getallcutomers")
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);
        if (data.Status == "Success") {
          this.setState({ customersdata: data.customersdata });
        } else {
          this.setState({ customersdata: "" });
        }
      });
    //Get naic codes
    fetch(AppConstants.API + "/users/getallnaiccodes")
      .then((response) => response.json())
      .then((data) => {
        //console.log(" naic codes ", data);
        if (data.Status == "Success") {
          this.setState({ naiccodes: data.naiccodes });
        } else {
          this.setState({ naiccodes: "" });
        }
      });
    var element = document.getElementById("navItem-Opportunities-3");
    element.classList.add("active");
    //Get Certification data
    fetch(AppConstants.API + "/certifications/getallcertifications")
      .then((response) => response.json())
      .then((data) => {
        //console.log(data);
        if (data.Status == "Success") {
          this.setState({ certificationsdata: data.certificationsdata });
        } else {
          this.setState({ certificationsdata: "" });
        }
      });
  }
  render() {
    // if(this.state.need_certifications == true){
    //   var certificationcheckvalu =  <Input type="checkbox" name="need_certifications" value={this.state.need_certifications} checked={this.state.need_certifications} onChange={ this.handleCheckChange } />
    // }
    // else
    // {
    //   var certificationcheckvalu =  <Input type="checkbox" name="need_certifications"  onChange={ this.handleCheckChange } />
    // }
    // const checkboxItems = this.state.checked1
    //   ? this.state.certificationsdata.map(planet => {
    //     return (<div className="cert-list"><Input type="checkbox" name="certifications" onChange={this.handleInputChangeFund} checked={this.state.certifications.includes(planet._id) || ''}  value={planet._id} class="form-control"></Input><span>{planet.certification_name}</span></div>);
    //     })
    //   : null;
    // const checkboxItems = this.state.checked1
    //   ? this.state.certificationsdata.map((planet, key) => {

    //     return (<div class="form-group form-check">
    //     <input type="checkbox" name="certifications" onChange={this.handleInputChangeFund} checked={this.state.certifications.includes(planet._id) || ''}  value={planet._id} id={planet._id} onClick={this.handleClick} /><label for={planet._id}>{planet.certification_name}</label>

    //     <span style={this.state.multidocids.includes(planet._id) ? {"display":"block"} : {"display":"none"}} id={`${'cert_'}${planet._id}`}>
    //         <input type="file" name="certification_documents" onChange={ this.handleFileChange } id={`${'cert_'}${planet._id}`}></input>
    //           {this.state.allcerificationdata.map((value, index) => {
    //                 return <div key={index}>
    //                 {value.certificationid ==  planet._id? <span><a href={value.certificationurl} target="_blank">{value.certificationurl}</a></span> : ''}
    //                 </div>
    //           })}
    //     </span>

    {
      /* {this.state.multidocids.includes(planet._id) ? <span><a href={this.state.multidocs[key]} target="_blank">{this.state.multidocs[key]}</a></span> : ''}  */
    }
    {
      /* <span>
        {planet._id == certification_documents.certificationid ? <span>{certification_documents.certificationurl}</span> : ''}</span> */
    }

    const checkboxItems =
      this.state.need_certifications == "Yes"
        ? this.state.certificationsdata.map((planet, key) => {
            return (
              <div className="cert-list">
                <input
                  type="checkbox"
                  className="form-check-input"
                  name="certifications"
                  onChange={this.handleInputChangeFund}
                  checked={this.state.certifications.includes(planet._id) || ""}
                  value={planet._id}
                  id={planet._id}
                />
                <span for={planet._id}>{planet.certification_name}</span>
              </div>
            );
          })
        : null;

    return (
      <Page title="Edit Opportunity">
        <ReactTitle title="Edit Opportunity" />
        <Link to={AppConstants.STAGADMIN + "/dashboard"}>Home</Link>
        &nbsp; | &nbsp;&nbsp;
        <Link to={AppConstants.STAGADMIN + "/rfprfiprofiles"}>Opportunities List</Link>
        <Form className="form-block" onSubmit={this.handleSubmit}>
          <Row>
            <Col xl={6} lg={12} md={12}>
              <Card>
                <CardHeader>Opportunity Info</CardHeader>
                <CardBody>
                  <FormGroup>
                    <Label for="examplePassword">
                      Companies{" "}
                      <span className="error" style={{ color: "red" }}>
                        *
                      </span>
                    </Label>
                    <Input
                      type="select"
                      name="customer_id"
                      value={this.state.customer_id}
                      onChange={this.handleInputChange}
                      ref="customer_id"
                    >
                      <option value="">Select Company</option>
                      {this.state.customersdata.map((customers, index) => {
                        if (customers.status == "Active") {
                          return (
                            <option key={index} value={customers._id}>
                              {customers.company_name}
                            </option>
                          );
                        }
                      })}
                    </Input>
                    <span className="error" style={{ color: "red" }}>
                      {this.state.errors["customer_id"]}
                    </span>
                  </FormGroup>
                  <FormGroup>
                    <Label for="exampleEmail">
                      Name Of Opportunity{" "}
                      <span className="error" style={{ color: "red" }}>
                        *
                      </span>
                    </Label>
                    <Input
                      type="text"
                      name="name_ofrfp"
                      placeholder="Name Of Opportunity"
                      onChange={this.handleInputChange}
                      value={this.state.name_ofrfp}
                      ref="name_ofrfp"
                    />
                    <span className="error" style={{ color: "red" }}>
                      {this.state.errors["name_ofrfp"]}
                    </span>
                  </FormGroup>
                  <FormGroup>
                    <Label for="exampleEmail">
                      Description{" "}
                      <span className="error" style={{ color: "red" }}>
                        *
                      </span>
                    </Label>
                    <Input
                      type="textarea"
                      name="description"
                      placeholder="Enter your description and details"
                      onChange={this.handleInputChange}
                      value={this.state.description}
                      ref="description"
                    />
                    <span className="error" style={{ color: "red" }}>
                      {this.state.errors["description"]}
                    </span>
                  </FormGroup>

                  <FormGroup>
                    <Label for="examplePassword">
                      Department{" "}
                      <span className="error" style={{ color: "red" }}>
                        *
                      </span>
                    </Label>
                    <Input
                      type="text"
                      name="department"
                      placeholder="Department"
                      onChange={this.handleInputChange}
                      value={this.state.department}
                      ref="department"
                    />
                    <span className="error" style={{ color: "red" }}>
                      {this.state.errors["department"]}
                    </span>
                  </FormGroup>
                  <FormGroup>
                    <Label for="examplePassword">
                      Biz Link
                      {/* <span className="error" style={{color: 'red'}}>*</span> */}
                    </Label>
                    <Input
                      type="text"
                      name="bid_link"
                      placeholder="Biz link"
                      onChange={this.handleInputChange}
                      value={this.state.bid_link}
                      ref="bid_link"
                    />
                    {/* <span className="error" style={{color: 'red'}}>{this.state.errors["bid_link"]}</span>          */}
                  </FormGroup>

                  <FormGroup>
                    <Label for="exampleUrl">Upload Document</Label>
                    {this.state.view_document ? (
                      <div>
                        <a href={this.state.view_document} target="_blank">
                          {this.state.view_document}
                        </a>
                        <br />
                        <br />
                      </div>
                    ) : (
                      <div></div>
                    )}
                    <div class="file-field input-field fileupload">
                      <div class="filebtn">
                        <span>
                          <i class="fa fa-upload" aria-hidden="true"></i>
                        </span>
                        <input type="file" name="documents" ref="documents" onChange={this.handleInputFileChange} />
                      </div>

                      <div class="file-path-wrapper">
                        <input class="file-path validate" type="text" placeholder="  Please upload Document." />
                      </div>
                    </div>

                    <span className="error" style={{ color: "red" }}>
                      {this.state.errors["documents"]}
                    </span>
                  </FormGroup>
                  <FormGroup>
                    <Label for="exampleSelect">
                      Posted Date
                      {/* <span className="error" style={{color: 'red'}}>*</span> */}
                    </Label>
                    {/* <DayPickerInput
                    formatDate={formatDate}
                    value={this.state.rfp_post_date} 
                    onDayChange={this.handleDayChange}
                    placeholder="MM/DD/YYYY"
                    name="rfp_post_date"
                   />   */}
                    {/* <DatePicker
                    selected={this.state.rfp_post_date}
                    onChange={this.handleChange}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    //dateFormat="MMMM d, yyyy h:mm aa"
                    dateFormat="MM/dd/YYYY h:mm aa"
                    timeCaption="time"
                    placeholderText="MM/DD/YYYY hh:mm"
                    name="rfp_post_date"
                    className="form-control"
                  /> */}
                    <DatePicker
                      selected={this.state.rfp_post_date}
                      onChange={this.handleChange}
                      dateFormat="MM/dd/yyyy"
                      timeCaption="time"
                      placeholderText="MM/DD/YYYY"
                      name="rfp_post_date"
                      className="form-control"
                      onChangeRaw={(e) => e.preventDefault()}
                    />
                    {/* <span className="error" style={{color: 'red'}}>{this.state.errors["rfp_post_date"]}</span>                   */}
                  </FormGroup>
                  <FormGroup>
                    <Label for="exampleSelect">
                      Due Date & Time
                      {/* <span className="error" style={{color: 'red'}}>*</span> */}
                    </Label>
                    {/* <DayPickerInput
                    formatDate={formatDate}
                    value={this.state.due_dateandtime} 
                    onDayChange={this.handleDayChange2}
                    placeholder="MM/DD/YYYY"
                    name="due_dateandtime"
                   /> */}
                    {/* <DatePicker
                    selected={this.state.due_dateandtime}
                    onChange={this.handleChange1}                  
                    dateFormat="MM/dd/YYYY"
                    timeCaption="time"
                    placeholderText="MM/DD/YYYY"
                    name="rfp_post_date"
                    className="form-control"
                  /> */}
                    <DatePicker
                      selected={this.state.due_dateandtime}
                      onChange={this.handleChange1}
                      showTimeSelect
                      timeIntervals={15}
                      dateFormat="MM/dd/yyyy h:mm aa"
                      timeCaption="time"
                      placeholderText="MM/DD/YYYY hh:mm"
                      name="due_dateandtime"
                      className="form-control"
                      onChangeRaw={(e) => e.preventDefault()}
                    />
                    {/* <span className="error" style={{color: 'red'}}>{this.state.errors["due_dateandtime"]}</span>                   */}
                  </FormGroup>
                  <FormGroup>
                    <Label for="exampleSelect">
                      Deadline For Questions
                      {/* <span className="error" style={{color: 'red'}}>*</span> */}
                    </Label>
                    {/* <DayPickerInput
                    formatDate={formatDate}
                    value={this.state.deadline_for_questions} 
                    onDayChange={this.handleDayChange1}
                    placeholder="MM/DD/YYYY"
                    name="deadline_for_questions"
                  /> */}
                    {/* <DatePicker
                    selected={this.state.deadline_for_questions}
                    onChange={this.handleChange2}                                      
                    dateFormat="MM/dd/YYYY"
                    timeCaption="time"
                    placeholderText="MM/DD/YYYY"
                    name="rfp_post_date"
                    className="form-control"
                  /> */}
                    <DatePicker
                      selected={this.state.deadline_for_questions}
                      onChange={this.handleChange2}
                      dateFormat="MM/dd/yyyy"
                      timeCaption="time"
                      placeholderText="MM/DD/YYYY"
                      name="deadline_for_questions"
                      className="form-control"
                      onChangeRaw={(e) => e.preventDefault()}
                    />
                    {/* <span className="error" style={{color: 'red'}}>{this.state.errors["deadline_for_questions"]}</span>  */}
                  </FormGroup>
                  <FormGroup>
                    <Label checkbox2>Would you like to give special consideration to either group below?</Label>
                    <Input
                      type="checkbox"
                      name="minority_business"
                      value={this.state.minority_business}
                      checked={this.state.checked}
                      onChange={this.handleCheckClickMinority}
                    />
                    <Label checkbox2>Minority business</Label>
                  </FormGroup>
                  <FormGroup>
                    <Input
                      type="checkbox"
                      name="women_owned_business"
                      value={this.state.women_owned_business}
                      checked={this.state.checked2}
                      onChange={this.handleCheckClickWomen}
                    />
                    <Label checkbox2>Women owned business</Label>
                  </FormGroup>
                </CardBody>
              </Card>
            </Col>
            <Col xl={6} lg={12} md={12}>
              <Card>
                <CardHeader>Opportunity Contact info</CardHeader>
                <CardBody>
                  <FormGroup>
                    <Label for="examplePassword">
                      First Name{" "}
                      <span className="error" style={{ color: "red" }}>
                        *
                      </span>
                    </Label>
                    <Input
                      type="text"
                      name="contact_first_name"
                      placeholder="Contact Person First Name"
                      onChange={this.handleInputChange}
                      value={this.state.contact_first_name}
                      ref="firstname"
                    />
                    <span className="error" style={{ color: "red" }}>
                      {this.state.errors["contact_first_name"]}
                    </span>
                  </FormGroup>
                  <FormGroup>
                    <Label for="examplePassword">
                      Last Name{" "}
                      <span className="error" style={{ color: "red" }}>
                        *
                      </span>
                    </Label>
                    <Input
                      type="text"
                      name="contact_last_name"
                      placeholder="Contact Person Last Name"
                      onChange={this.handleInputChange}
                      value={this.state.contact_last_name}
                      ref="lastname"
                    />
                    <span className="error" style={{ color: "red" }}>
                      {this.state.errors["contact_last_name"]}
                    </span>
                  </FormGroup>
                  <FormGroup>
                    <Label for="examplePassword">
                      Phone Number
                      {/* <span className="error" style={{color: 'red'}}> *</span> */}
                    </Label>
                    <Input
                      type="text"
                      name="contact_phonenumber"
                      placeholder="Contact Person Phone Number"
                      onChange={this.handlePhoneChange}
                      value={this.state.contact_phonenumber}
                      maxLength="15"
                    />
                    {/* <NumberFormat
                format="(###) ###-####" mask=""
                name="contact_phonenumber"
                placeholder='Contact Person Phone Number'
                onChange={this.handleInputChange}
                value={this.state.contact_phonenumber} class="form-control" ref ="phoneno"/>                 */}
                    {/* <span className="error" style={{color: 'red'}}>{this.state.errors["contact_phonenumber"]}</span>               */}
                  </FormGroup>
                  <FormGroup>
                    <Label for="examplePassword">
                      Email
                      {/* <span className="error" style={{color: 'red'}}>*</span> */}
                    </Label>
                    <Input
                      type="text"
                      name="contact_email"
                      placeholder="Contact Person Email"
                      onChange={this.handleInputChange}
                      value={this.state.contact_email}
                      ref="email"
                    />
                    {/* <span className="error" style={{color: 'red'}}>{this.state.errors["contact_email"]}</span>                   */}
                  </FormGroup>
                  <FormGroup>
                    <Label for="exampleSelect">Reference Number</Label>
                    <Input
                      type="text"
                      name="bid_rfp_reference_number"
                      placeholder="Reference Number"
                      value={this.state.bid_rfp_reference_number}
                      onChange={this.handleInputChange}
                    />
                  </FormGroup>
                  {/* <FormGroup>
            <div className="cert-list">                           
              <Input
                type="checkbox"
                name="need_certifications"
                value={this.state.need_certifications}                      
                checked={this.state.checked1} onChange={this.handleCheckClickCertification}
              />   
              <span checkbox2>
              What certifications would you like the applying company to have?
              </span> 
              </div>                   
            </FormGroup> */}
                  <FormGroup>
                    <label>Would you like the applying company to be certified?</label>
                    <div>
                      <div class="form-group form-check inline">
                        <input
                          type="radio"
                          name="need_certifications"
                          id="need_certifications_yes"
                          value="Yes"
                          checked={this.state.need_certifications == "Yes"}
                          onChange={this.handleCheckClickCertification}
                        />
                        <label for="need_certifications_yes">Yes</label>
                      </div>
                      <div class="form-group form-check inline">
                        <input
                          type="radio"
                          name="need_certifications"
                          id="need_certifications_no"
                          checked={this.state.need_certifications == "No"}
                          value="No"
                          onChange={this.handleCheckClickCertification}
                        />
                        <label for="need_certifications_no">No</label>
                      </div>
                    </div>
                  </FormGroup>
                  <FormGroup>
                    {/* <Label for="exampleFile">
                Certifications
              </Label>  */}
                    {checkboxItems}
                    <span className="error" style={{ color: "red" }}>
                      {this.state.errors["certify"]}
                    </span>
                  </FormGroup>

                  <FormGroup>
                    <Label for="exampleSelect">
                      NAICS Codes{" "}
                      <span className="error" style={{ color: "red" }}>
                        *
                      </span>
                    </Label>
                    <Typeahead
                      clearButton
                      labelKey={(option) => `${option.NAICS_Description}` + `(${option.NAICS})`}
                      options={this.state.naiccodes}
                      placeholder="NAICS Codes"
                      name="naic_code"
                      value={this.state.naiccodes}
                      onChange={(selected) => {
                        this.setState({ naic_code: selected });
                      }}
                      class="form-control"
                      selected={this.state.naic_code}
                      ref="naiccode"
                      multiple
                    />
                    <span className="error" style={{ color: "red" }}>
                      {this.state.errors["naic_code"]}
                    </span>
                  </FormGroup>

                  <FormGroup>
                    <Label for="exampleSelect">Status</Label>
                    <Input type="select" name="status" value={this.state.status} onChange={this.handleInputChange}>
                      <option value="Active">Active</option>
                      <option value="In-Active">In Active</option>
                    </Input>
                  </FormGroup>
                  <FormGroup>
                    <Label for="date_of_birth"></Label>
                    <span style={{ "padding-left": "15px;" }}>
                      <Input
                        type="checkbox"
                        name="sendmail"
                        placeholder="send an email to the vendors after update"
                        onChange={this.handleInputChange}
                      />
                    </span>{" "}
                    <span> Send an email to the vendors after update </span>
                  </FormGroup>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md={12} className="form-btn">
              <Button
                class="btn btn-primary addbtn"
                className="bg-gradient-theme-left border-0"
                onClick={this.handleSubmit}
                title="Update Opportunity"
              >
                Update
                <div style={this.state.loading ? {} : { display: "none" }} class="image-fill w-25 loader-login-div ">
                  <img src={Loading} alt="No Image" className="can-click " />
                </div>
              </Button>
              &nbsp;&nbsp;
              <Link
                to={AppConstants.STAGADMIN + "/rfprfiprofiles"}
                class="btn btn-primary redbtn"
                title="Cancel Opportunity"
              >
                Cancel
              </Link>
            </Col>
          </Row>
          {this.state.errMsg}
        </Form>
      </Page>
    );
  }
}

Editrfprfi.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

//export default ChangepasswordPage;
export default connect(mapStateToProps, {})(Editrfprfi);
