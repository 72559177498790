import Page from '../../../src/components/Page';
import React from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import { BootstrapTable, TableHeaderColumn, InsertButton } from 'react-bootstrap-table';
//import { AddCompany, DataFormat } from 'components/NewUsers';
import { Card, Button, CardBody, CardHeader, Col, Row, Table, Modal,
  ModalBody,
  ModalFooter,
  ModalHeader } from 'reactstrap';
import {
    MdCreate,
    MdDelete,
    MdRemoveRedEye,MdAdd
  } from 'react-icons/md';
import AppConstants from '../../AppConstants';
import {ReactTitle} from 'react-meta-tags';


class Agencies extends React.Component {
  constructor(props) {
    super(props);
    this.state = {      
      agenciesdata: [],
      errors: {},
      errMsg:false
  };
  this.format = this.format.bind(this);
}
  DeleteAgency = params => e => {
    e.preventDefault();
    if(window.confirm("Are you sure you want to delete this agency?"))
    {
      const agencydeletedata = {
        id:params
      }
      axios.post(AppConstants.API+'/agency/deleteagency',agencydeletedata)
        .then(res => {   
          //console.log(res);   
        if(res.data.Status == "Success"){                           
          this.setState({
            errMsg: res.data.msg
          });
          fetch(AppConstants.API+'/certifications/getallagencies/'+this.props.match.params.id).then(response => response.json())
            .then(data => {
            //console.log(data);
            if(data.Status == "Success")
            {
              //console.log(data.agenciesdata[0].agencies);
              this.setState({ agenciesdata: data.agenciesdata });
            }
            else
            {
              this.setState({ agenciesdata: "" });
            }    
          });  
        }
        else{
          this.setState({
            errMsg: res.data.msg
          });
        }
      });
    }
  }  

format(cell, row){
  return <div className="ca-controls"><Link to={AppConstants.STAGADMIN+"/editagency/"+row.agencyid} class="btn btn-secondary editbtn" title="Edit Agency" style={{fontSize:"15px"}}>Edit</Link>
                           {/*  <span onClick={ this.DeleteAgency(row.agencyid) } class="text-muted" title="Delete Agency"><MdDelete /></span> */}</div>;
}

  createCustomInsertButton = (onClick) => {
    return (
        <span> &nbsp; <Link to={AppConstants.STAGADMIN+"/addagency/"+this.props.match.params.id} class="btn btn-warning addbtn" title="Add Agency">Add</Link></span>
    );
  }
  componentDidMount() {
      fetch(AppConstants.API+'/certifications/getallagencies/'+this.props.match.params.id).then(response => response.json())
      .then(data => {
      console.log(data);
      if(data.Status == "Success")
      {
        //console.log(data.agenciesdata[0].agencies);
        this.setState({ agenciesdata: data.agenciesdata });
      }
      else
      {
        this.setState({ agenciesdata: "" });
      }    
    });
    var element = document.getElementById("navItem-Certifications and Agencies-5");
    element.classList.add("active");
  }
   render() {
    if(localStorage.jwtTokenAdmin != undefined)
    {
    const options = {
      exportCSVText: 'Export CSV',    
      insertText: "New",    
      deleteText: 'Delete',    
      saveText: 'Save',    
      closeText: 'Close',
      insertBtn: this.createCustomInsertButton,
      page: 1,  // which page you want to show as default
      sizePerPageList: [ {
        text: '5', value: 5
      }, {
        text: '10', value: 10
      }, {
        text: '20', value: 20
      },
      {
        text: '50', value: 50
      }
    ], // you can change the dropdown list for size per page
      sizePerPage: 10,  // which size per page you want to locate as default
      pageStartIndex: 1, // where to start counting the pages
      paginationSize: 3,  // the pagination bar size.
     // prePage: 'Prev', // Previous page button text
      //nextPage: 'Next', // Next page button text
      //firstPage: 'First', // First page button text
      //lastPage: 'Last', // Last page button text
      paginationShowsTotal: this.state.agenciesdata.length,  // Accept bool or function
      paginationPosition: 'bottom',  // default is bottom, top and both is all available
      // hideSizePerPage: true > You can hide the dropdown for sizePerPage
      // alwaysShowAllBtns: true // Always show next and previous button
      // withFirstAndLast: false > Hide the going to First and Last page button
      insertModal: this.createCustomModal    
    };
    return (
        <Page title="Agencies">
        <ReactTitle title="Agencies"/>
        <Link to={AppConstants.STAGADMIN+'/dashboard'}>
          Home
        </Link>
        &nbsp; | &nbsp;&nbsp;
        <Link to={AppConstants.STAGADMIN+'/certifications'}>
        Certifications and Agencies
        </Link>
            <row>
              <Card className="data-table">
                <CardBody>
                  <div>
                  <span className="error" style={{color: 'red'}}>{this.state.errMsg}</span>
                  <BootstrapTable data={ this.state.agenciesdata } search={ true } pagination={ true } multiColumnSearch={ true } options={ options }
                  insertRow
                  >
                    <TableHeaderColumn dataField='_id' isKey={ true } searchable={ false } dataSort={ true } hidden={true}>ID</TableHeaderColumn>
                    <TableHeaderColumn dataField='agencyname' dataSort={ true }>Agency Name</TableHeaderColumn>                    
                    <TableHeaderColumn dataField='agencystatus' width={'15%'} dataSort={ true }>Status</TableHeaderColumn>
                    <TableHeaderColumn dataField='actions' width={'25%'} dataFormat={this.format.bind(this)} export={ false }>Actions</TableHeaderColumn>
                  </BootstrapTable>
                </div>
                </CardBody>
              </Card>
            </row>
          </Page>
      );
    }
    else 
    {
        window.location.href = AppConstants.STAGADMIN+'/'
    }
  }

};

export default Agencies;