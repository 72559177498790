import React from 'react';
import { Link } from 'react-router-dom';
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Form,
  FormGroup,
  Label,
} from 'reactstrap';

import Page from '../../../src/components/Page';
import axios from 'axios';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import AppConstants from '../../AppConstants';
import {ReactTitle} from 'react-meta-tags';
import Loading from '../../assets/img/loader.gif';
import Workbook from "react-xlsx-workbook";

const data1 = [
  {
    industryname: "Test Industry",
    subindustryname: "Agriculture, Fishing and Hunting",
  },
  {
    industryname: "Test Industry1",
    subindustryname: "Agriculture, Forestry",
  },
  {
    industryname: "Test Industry111",
    subindustryname: "Agriculture, Forestry, Fishing and Hunting",
  },
];


class UploadVendors extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      _id:this.props.auth.user._id,
      file : null,
      errors: {},
      errMsg:'',
      loading: '',
    }
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleInputChange(e) {
    this.setState({file:e.target.files[0]});
  }
  /** Validations */
  handleValidation(){
    //let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
   // console.log(this.state.file);
    if(this.state.file == null || this.state.file == undefined){
      formIsValid = false;
      errors["myImage"] = "Please upload valid file.";
    }
    else if(this.state.file.type !='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
    {
      formIsValid = false;
      errors["myImage"] = "Please upload xlsx file.";
    }
    this.setState({errors: errors});
    return formIsValid;
  }

  handleSubmit(e)
  {
      e.preventDefault();
      if(this.handleValidation()){ 
        this.setState({loading:1}); 
      const formData = new FormData();
            formData.append('myImage',this.state.file);
      const config = {
          headers: {
              'content-type': 'multipart/form-data'
          }
      };
      axios.post(AppConstants.API+'/industries/uploadindustriessxlsx', formData,config)
          .then((res) => {
            //console.log(response);  
             // alert("The file is successfully uploaded");
              if(res.data.Status == "Success"){      
                this.setState({
                  //errMsg: res.data.msg
                  errMsg: (<div className="alert alert-success fade show mt-3">{res.data.msg}</div>)
                });
                setTimeout((e) => {
                    this.props.history.push(AppConstants.STAGADMIN+'/industries');
                 },2000);   
              }
              else{
                this.setState({loading:''}); 
                setTimeout((e) => {
                  this.setState({
                    errMsg: ''
                  });
                 },2000);  
                this.setState({
                  //errMsg: res.data.msg
                  errMsg: (<div className="alert alert-danger fade show mt-3">{res.data.msg}</div>)
                });
              }
          }).catch((error) => {
              alert("Upload failed.."); 
      });
    }
  }
  componentDidMount(){
    var element = document.getElementById("navItem-Industry Types-1");
    element.classList.add("active");
  }
  render() {
    const {user} = this.props.auth;
    const { open } = this.state;
    //console.log(user);
  return (
    <Page title="Import Industries">
    <ReactTitle title="Import Industries"/>
    <Link to={AppConstants.STAGADMIN+'/dashboard'}>
      Home
    </Link>
    &nbsp; | &nbsp;&nbsp;
    <Link to={AppConstants.STAGADMIN+'/industries'}>
    Industry Types
    </Link>
    <Form className="form-block" onSubmit= {this.handleSubmit}>
      <Row >
        <Col xl={12} lg={12} md={12}>
          <Card>
            <CardBody>
                <FormGroup>
                  <Label for="examplePassword" sm={2}>
                  </Label>
                  <Col sm={10}>                    
                  {/* <span className="error" style={{color: 'green'}}>{this.state.errMsg}</span> */}
                  </Col>
                </FormGroup><br />
                <FormGroup >
                <Label  for="exampleUrl">Upload File</Label>
                <div  class = "file-field input-field fileupload "  style={{width: '300px'}}>
                    <div class = "filebtn">
                        <span><i class="fa fa-upload" aria-hidden="true"></i></span>
                        <input type = "file" name="myImage" onChange={ this.handleInputChange} />
                    </div>
                    <div class = "file-path-wrapper">
                        <input class = "file-path validate" type = "text"
                          placeholder = "Upload file." />
                    </div>
                </div> 
                  <span className="error" style={{color: 'red'}}>{this.state.errors["myImage"]}</span>

                  <br />
                    Sample file to Import Industries: &nbsp;{" "}
                    <Workbook
                      title="Export Import Industries"
                      filename="Industries.xlsx"
                      element={
                        <span style={{color: "red", cursor: "pointer"}}>
                          Click Here
                        </span>
                      }
                    >
                      <Workbook.Sheet data={data1} name="Sheet A">
                        <Workbook.Column
                          label="industryname"
                          value="industryname"
                          style={{font: "bold"}}
                        />
                        <Workbook.Column
                          label="subindustryname"
                          value="subindustryname"
                        />
                       
                      </Workbook.Sheet>
                    </Workbook>


                </FormGroup>
            </CardBody>
          </Card>
        </Col>
      </Row>
        <Row>
          <Col md={12} className="form-btn">
              <Button class="btn btn-primary addbtn" className="bg-gradient-theme-left border-0" onClick={ this.handleSubmit } title="Import Industries">Submit
              <div style={this.state.loading ? {} : { display: 'none' }} class="image-fill w-25 loader-login-div "><img src={Loading} alt="No Image" className="can-click " /></div>
              </Button>&nbsp;&nbsp;
            <Link to={AppConstants.STAGADMIN+"/industries"} class="btn btn-primary redbtn" title="Cancel">Cancel</Link>
          </Col>
        </Row>
        {this.state.errMsg}
        </Form>
    </Page>
  );
};
}

UploadVendors.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors
});
export  default connect(mapStateToProps, {})(UploadVendors);