import React from 'react';
import { Link } from 'react-router-dom';
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  FormFeedback,
} from 'reactstrap';
import Page from '../../../src/components/Page';
import axios from 'axios';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import AppConstants from '../../AppConstants';
import Loading from '../../assets/img/loader.gif';
import {ReactTitle} from 'react-meta-tags';
import ReactDOM from 'react-dom';


class AddNigpnaic extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      _id:this.props.auth.user._id,
      NAICS: "",
      NAICS_Description: "",
      status:'Active',       
      errors: {},
      errMsg:'',
      loading: '',
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this); 
  }
  
  handleInputChange(e) {
    this.setState({
        [e.target.name]: e.target.value
    });
    //console.log(this.state);
  }
  
  /** Validations */
  handleValidation(){
    let errors = {};
    let formIsValid = true;
    if(!this.state.NAICS){
      formIsValid = false;
      errors["NAICS"] = "Please enter NAICS";
      ReactDOM.findDOMNode(this.refs.NAICS).focus();

    }
    if(!this.state.NAICS_Description){
      formIsValid = false;
      errors["NAICS_Description"] = "Please enter NAICS_Description";
      ReactDOM.findDOMNode(this.refs.NAICS_Description).focus();

    }

    this.setState({errors: errors});
    return formIsValid;
  }

  handleSubmit = event => {
    event.preventDefault();
    //alert('form submitted');    
     if(this.handleValidation()){
      this.setState({loading:1});   
      const codedata = {
        id:this.props.auth.user._id,
        NAICS: this.state.NAICS,
        NAICS_Description: this.state.NAICS_Description,
        status: this.state.status
      }
      axios.post(AppConstants.API+'/users/addNaicAndNigp',codedata)
        .then(res => {   
          console.log(res);   
         if(res.data.Status == "Success"){ 
          setTimeout((e) => {           
            this.props.history.push(AppConstants.STAGADMIN + "/nigpnaics");
          }, 1000);       
          this.setState({
            //errMsg: res.data.msg
            errMsg: (<div className="alert alert-success fade show mt-3">{res.data.msg}</div>)
          });
        }
        else{
          this.setState({loading:''});   
          setTimeout((e) => {
            this.setState({
              errMsg: ''
            });
           },2000);
          this.setState({
            //errMsg: res.data.msg
            errMsg: (<div className="alert alert-danger fade show mt-3">{res.data.msg}</div>)
          });
        }
      });
    } 
  };
  componentDidMount() {
    var element = document.getElementById("navItem-Company Types-0");
    element.classList.add("active");
  }
  render() {
  const {user} = this.props.auth;
  return (
    <Page title="Add NIGP/NAICS">
      <ReactTitle title=""/>
    <Link to={AppConstants.STAGADMIN+'/dashboard'}>
    Home
    </Link>
    &nbsp; | &nbsp;&nbsp;
    <Link to={AppConstants.STAGADMIN + "/nigpnaics"}>
    Company Types
    </Link>
    <Form  className="form-block" name="changeadminpassword" onSubmit= { this.handleSubmit } >
      <Row>
        <Col xl={12} lg={12} md={12}>
          <Card className="">
            <CardBody>
              
                <FormGroup>
                  <Label for="examplePassword" >
                  </Label>
                                     
                  
                </FormGroup>

                <FormGroup >
                  <Label for="examplePassword">
                  NAICS <span className="error" style={{color: 'red'}}>*</span>
                  </Label>
                  
                    <Input
                      type="text"
                      name="NAICS"
                      placeholder="Enter the NAICS"
                      onChange={ this.handleInputChange } ref ="NAICS"  className="col-md-6"               
                    />
                    <span className="error" style={{color: 'red'}}>{this.state.errors["NAICS"]}</span>
                  
                </FormGroup>       
                <FormGroup >
                  <Label for="examplePassword">
                  NAICS_Description <span className="error" style={{color: 'red'}}>*</span>
                  </Label>
                  
                    <Input
                      type="text"
                      name="NAICS_Description"
                      placeholder="Enter the description"
                      onChange={ this.handleInputChange } ref ="NAICS_Description"  className="col-md-6"               
                    />
                    <span className="error" style={{color: 'red'}}>{this.state.errors["NAICS_Description"]}</span>
                  
                </FormGroup>          
                <FormGroup>
                  <Label for="exampleSelect">
                    Status
                  </Label>
                 
                  <Input className="col-md-6" type="select" name="status" onChange={ this.handleInputChange }>
                    <option value="Active">Active</option>
                    <option value="In-Active">In Active</option>                    
                  </Input>
                  
                </FormGroup>
                
              
            </CardBody>
          </Card>
        </Col>
      </Row> 
      <Row>
      <Col md={12} className="form-btn">
                    <Button class="btn btn-primary addbtn" className="bg-gradient-theme-left border-0" onClick={ this.handleSubmit } title="Add Company">Add
                      <div style={this.state.loading ? {} : { display: 'none' }} class="image-fill w-25 loader-login-div "><img src={Loading} alt="No Image" className="can-click " /></div>
                    </Button>&nbsp;&nbsp;
                    {/* <Button class="btn btn-primary" color="danger" onClick={ this.CancelSubmit } title="Cancel Company">Cancel</Button> */}
                    <Link to={AppConstants.STAGADMIN + "/nigpnaics"} class="btn btn-primary redbtn" title="Cancel Company">Cancel</Link>
                  </Col>
                
        </Row>  
        {this.state.errMsg}
      </Form>  
    </Page>
  );
};
}

AddNigpnaic.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors
});

//export default ChangepasswordPage;
export  default connect(mapStateToProps, {})(AddNigpnaic);