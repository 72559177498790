import React from "react";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  FormFeedback,
} from "reactstrap";
import Page from "../../../src/components/Page";
import axios from "axios";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import AppConstants from "../../AppConstants";
import Loading from "../../assets/img/loader.gif";
import {ReactTitle} from "react-meta-tags";
import ReactDOM from "react-dom";
import isValidUrl from "valid-url";

class AddCertificate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      _id: this.props.auth.user._id,
      certification_name: "",
      alias_name: "",
      description: "",
      status: "Active",
      errors: {},
      errMsg: "",
      loading: "",
      badge: "",
      setUrl: "",
      isValidVideo: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  componentDidMount() {
    var element = document.getElementById("navItem-How It Works-4");
    element.classList.add("active");
  }

  /** Validations */
  handleValidation() {
    let errors = {};
    let formIsValid = true;

    if (!this.state.description) {
      formIsValid = false;
      errors["description"] = "Please enter description";
      ReactDOM.findDOMNode(this.refs.description).focus();
    }

    if (!this.state.certification_name) {
      formIsValid = false;
      errors["certification_name"] = "Enter YouTube or Vimeo URL";
      ReactDOM.findDOMNode(this.refs.certification_name).focus();
    } else if (isValidUrl.isUri(this.state.certification_name)) {
      if (
        this.state.certification_name.includes("youtube.com") ||
        this.state.certification_name.includes("vimeo.com")
      ) {
        this.setState({isValidVideo: true});
      } else {
        formIsValid = false;
        errors["certification_name"] = "Enter YouTube or Vimeo URL";
        ReactDOM.findDOMNode(this.refs.certification_name).focus();
      }
    } else {
      formIsValid = false;
      errors["certification_name"] = "Enter YouTube or Vimeo URL";
      ReactDOM.findDOMNode(this.refs.certification_name).focus();
    }

    if (!this.state.alias_name) {
      formIsValid = false;
      errors["alias_name"] = "Please enter title";
      ReactDOM.findDOMNode(this.refs.alias_name).focus();
    }

    this.setState({errors: errors});
    return formIsValid;
  }

  handleSubmit = (event) => {
    event.preventDefault();
    //alert('form submitted');
    if (this.handleValidation()) {
      this.setState({loading: 1});
      const workData = {
        id: this.props.auth.user._id,
        description: this.state.description,
        status: this.state.status,
        socialLink: this.state.certification_name,
        title: this.state.alias_name,
      };

      axios
        .post(AppConstants.API + "/certifications/addWork", workData)
        .then((res) => {
          console.log(res);
          if (res.data.Status == "Success") {
            setTimeout((e) => {
              this.props.history.push(AppConstants.STAGADMIN + "/howitworks");
            }, 2000);

            this.setState({
              //errMsg: res.data.msg
              errMsg: (
                <div className="alert alert-success fade show mt-3">
                  {res.data.msg}
                </div>
              ),
            });
          } else {
            this.setState({loading: ""});
            setTimeout((e) => {
              this.setState({
                errMsg: "",
              });
            }, 2000);
            this.setState({
              //errMsg: res.data.msg
              errMsg: (
                <div className="alert alert-danger fade show mt-3">
                  {res.data.msg}
                </div>
              ),
            });
          }
        });
    }
  };
  /* CancelSubmit = e => {
    e.preventDefault();
    window.location.href = '/certifications';
  } */

  render() {
    const {user} = this.props.auth;
    const {url, isValidVideo} = this.state;
    return (
      <Page title="Add Work">
        <ReactTitle title="Add Work" />
        <Link to={AppConstants.STAGADMIN + "/dashboard"}>Home</Link>
        &nbsp; | &nbsp;&nbsp;
        <Link to={AppConstants.STAGADMIN + "/howitworks"}>How It Works</Link>
        <Form
          className="form-block"
          name="changeadminpassword"
          onSubmit={this.handleSubmit}
        >
          <Row>
            <Col xl={12} lg={12} md={12}>
              <Card>
                <CardBody>
                  <Row>
                    <Col xl={6} lg={6} md={6}>
                      <FormGroup>
                        <Label for="examplePassword">
                          Title{" "}
                          <span className="error" style={{color: "red"}}>
                            *
                          </span>
                        </Label>
                        <Input
                          type="text"
                          name="alias_name"
                          placeholder="Title"
                          onChange={this.handleInputChange}
                          ref="alias_name"
                        />
                        <span className="error" style={{color: "red"}}>
                          {this.state.errors["alias_name"]}
                        </span>
                      </FormGroup>

                      <FormGroup>
                        <Label for="examplePassword">
                          Link{" "}
                          <span className="error" style={{color: "red"}}>
                            *
                          </span>
                        </Label>
                        <Input
                          type="text"
                          name="certification_name"
                          placeholder="Enter YouTube or Vimeo URL"
                          onChange={this.handleInputChange}
                          ref="certification_name"
                          value={url}
                        />
                        <span className="error" style={{color: "red"}}>
                          {this.state.errors["certification_name"]}
                        </span>
                      </FormGroup>

                      <FormGroup>
                        <Label for="examplePassword">
                          Description{" "}
                          <span className="error" style={{color: "red"}}>
                            *
                          </span>
                        </Label>
                        <Input
                          type="textarea"
                          name="description"
                          placeholder="Description"
                          onChange={this.handleInputChange}
                          ref="description"
                        />
                        <span className="error" style={{color: "red"}}>
                          {this.state.errors["description"]}
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>

                  <FormGroup>
                    <Label for="exampleSelect">Status</Label>

                    <Input
                      type="select"
                      name="status"
                      onChange={this.handleInputChange}
                      className="col-md-6"
                    >
                      <option value="Active">Active</option>
                      <option value="In-Active">In Active</option>
                    </Input>
                  </FormGroup>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md={12} className="form-btn">
              <Button
                className="btn btn-primary addbtn"
                onClick={this.handleSubmit}
                title="Add Work"
              >
                Add
                <div
                  style={this.state.loading ? {} : {display: "none"}}
                  class="image-fill w-25 loader-login-div "
                >
                  <img src={Loading} alt="No Image" className="can-click " />
                </div>
              </Button>{" "}
              &nbsp;&nbsp;
              {/* <Button class="btn btn-primary" color="danger" onClick={ this.CancelSubmit } title="Cancel Certificate">Cancel</Button> */}
              <Link
                to={AppConstants.STAGADMIN + "/howitworks"}
                class="btn btn-primary redbtn"
                title="Cancel Work"
              >
                Cancel
              </Link>
            </Col>
          </Row>
          {this.state.errMsg}
        </Form>
      </Page>
    );
  }
}

AddCertificate.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

//export default ChangepasswordPage;
export default connect(mapStateToProps, {})(AddCertificate);
