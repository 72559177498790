import React from "react";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  FormFeedback,
} from "reactstrap";
import Select from "react-select";
import Page from "../../../src/components/Page";
import axios from "axios";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import AppConstants from "../../AppConstants";
import NumberFormat from "react-number-format";
import { Typeahead } from "react-bootstrap-typeahead";
import Loading from "../../assets/img/loader.gif";
import { ReactTitle } from "react-meta-tags";
import ReactDOM from "react-dom";

class AddCustomer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      _id: this.props.auth.user._id,
      company_username: "",
      company_name: "",
      company_logo: "",
      company_email: "",
      company_password: "",
      company_type: "",
      company_otp: "",
      company_otp_status: "",
      company_industrey: "",
      company_address: "",
      company_phoneno: "",
      company_website: "",
      company_primary_contact_firstname: "",
      company_primary_contact_lastname: "",
      company_primary_contact_middlename: "",
      company_primary_contact_title: "",
      company_primary_contact_phoneno: "",
      company_primary_email: "",
      company_primary_cellno: "",
      company_primary_officeno: "",
      company_primary_department: "",
      company_secondary_contactno: "",
      naic_code: [],
      created_by: this.props.auth.user._id,
      locations: "",
      latitude: "",
      longitude: "",
      country: "",
      state: "",
      city: "",
      zipcode: "",
      status: "Active",
      errors: {},
      errMsg: "",
      companiesdata: [],
      naiccodes: [],
      countries: [],
      states: [],
      cities: [],
      industriesdata: [],
      loading: "",
      company_business_model: "",
      subindustriesdata: [],
      subindustries: [],
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleCountryChange = this.handleCountryChange.bind(this);
    this.handleStateChange = this.handleStateChange.bind(this);
    this.handleMultiChange = this.handleMultiChange.bind(this);
    this.handleInputFileChange = this.handleInputFileChange.bind(this);
    this.onChangeNumberkey = this.onChangeNumberkey.bind(this);
    this.handleCompanyModelChange = this.handleCompanyModelChange.bind(this);
    this.handleIndustryChange = this.handleIndustryChange.bind(this);
    this.handleInputChangeFund = this.handleInputChangeFund.bind(this);
    this.handlePhoneChange = this.handlePhoneChange.bind(this);
  }

  handlePhoneChange(e) {
    const re1 = /^[0-9)\(+ '-]+$/g;
    // var phone_num = e.target.value.replace(/\D/g,'');
    if (e.target.value === "" || re1.test(e.target.value)) {
      // phone_num = this.phoneFormat(phone_num)
      this.setState({
        [e.target.name]: e.target.value,
      });
    }
  }

  phoneFormat(input) {
    // Strip all characters from the input except digits
    // Trim the remaining input to ten characters, to preserve phone number format
    input = input.substring(0, 10);
    // Based upon the length of the string, we add formatting as necessary
    var size = input.length;
    if (size == 0) {
      input = input;
    } else if (size < 4) {
      input = "(" + input;
    } else if (size < 7) {
      input = "(" + input.substring(0, 3) + ") " + input.substring(3, 6);
    } else {
      input = "(" + input.substring(0, 3) + ") " + input.substring(3, 6) + "-" + input.substring(6, 10);
    }
    return input;
  }

  handleIndustryChange(event) {
    //console.log(event.target.value);
    let company_industrey = "";
    if (event) {
      company_industrey = event.target.value;
      this.setState({ subindustriesdata: [] });
    } else {
      company_industrey = this.state.company_industrey;
    }

    if (company_industrey != "") {
      fetch(AppConstants.API + "/industries/getsubindustries/" + company_industrey)
        .then((response) => response.json())
        .then((data) => {
          console.log(" sub industries ", data.subindustriesdata);
          if (data.Status == "Success") {
            this.setState({
              subindustriesdata: data.subindustriesdata,
              company_industrey: company_industrey,
              //subindustries:[]
            });
          } else {
            this.setState({
              company_industrey: company_industrey,
            });
          }
        });
    } else {
      this.setState({
        company_industrey: company_industrey,
      });
    }
  }

  handleInputChangeFund(e) {
    const subindustries = this.state.subindustries;
    let index;
    // check if the check box is checked or unchecked
    if (e.target.checked) {
      // add the numerical value of the checkbox to options array
      subindustries.push(e.target.value);
    } else {
      // or remove the value from the unchecked checkbox from the array
      index = subindustries.indexOf(e.target.value);
      subindustries.splice(index, 1);
    }
    //console.log(subindustries);
    // update the state with the new array of options
    this.setState({ subindustries: subindustries });
  }

  handleCompanyModelChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }
  handleInputChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }
  handleInputFileChange(e) {
    //console.log(e);
    this.setState({
      [e.target.name]: e.target.files[0],
    });
  }
  onChangeNumberkey(e) {
    const zip = this.state;
    //const re = /^[0-9\b]+$/;
    const re = /^[0-9)\(+ '-]+$/g;
    if (e.target.value === "" || re.test(e.target.value)) {
      zip[e.target.name] = e.target.value;
      this.setState({ zip });
    }
  }
  handleMultiChange(e) {
    var options = e.target.options;
    var value = [];
    for (var i = 0, l = options.length; i < l; i++) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    this.setState({
      naic_code: value,
    });
  }
  handleCountryChange(event) {
    let country_id = "";
    if (event) {
      country_id = event.target.value;
    } else {
      country_id = this.state.country;
    }
    console.log(country_id);
    if (country_id != "") {
      fetch(AppConstants.API + "/states/getallstates/" + country_id)
        .then((response) => response.json())
        .then((data) => {
          //console.log(" states ", data);
          if (data.Status == "Success") {
            this.setState({
              states: data.getallstates,
              country: country_id,
              cities: [],
            });
          } else {
            this.setState({
              country: country_id,
            });
          }
        });
    } else {
      this.setState({
        country: country_id,
        states: [],
        cities: [],
        state: "",
        city: "",
      });
    }
  }

  handleStateChange(event) {
    //const state_id = event.target.value;
    let state_id = "";
    if (event) {
      state_id = event.target.value;
    } else {
      state_id = this.state.state;
    }
    if (state_id != "") {
      fetch(AppConstants.API + "/cities/getallcities/" + state_id)
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          if (data.Status == "Success") {
            this.setState({
              cities: data.getallcities,
              state: state_id,
            });
          } else {
            this.setState({
              state: state_id,
            });
          }
        });
    } else {
      this.setState({
        state: state_id,
        cities: [],
        city: "",
      });
    }
  }

  /** Validations */
  handleValidation() {
    let errors = {};
    let formIsValid = true;
    /* if(!this.state.company_username){
      formIsValid = false;
      errors["company_username"] = "Please enter customer name";
    } */
    //Email

    // if(!this.state.company_logo =='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'){
    //   formIsValid = false;
    //   errors["company_logo"] = "Please upload valid image";
    // }

    if (
      this.state.company_logo != "" &&
      this.state.company_logo.type != "image/jpeg" &&
      this.state.company_logo.type != "image/png"
    ) {
      formIsValid = false;
      errors["company_logo"] = "Please select PNG/JPG file";
    }
    /*if(!this.state.company_name){
      formIsValid = false;
      errors["company_name"] = "Please enter company name";
      ReactDOM.findDOMNode(this.refs.company_name).focus();
    }*/

    if (!this.state.company_industrey) {
      formIsValid = false;
      errors["company_industrey"] = "Please select industry type";
      ReactDOM.findDOMNode(this.refs.company_industrey).focus();
    }

    if (!this.state.naic_code.length) {
      formIsValid = false;
      errors["naic_code"] = "Please select at-least one NAICS Code";
      ReactDOM.findDOMNode(this.refs.naiccode).focus();
    }

    //  if(!this.state.company_primary_contact_phoneno){
    //    formIsValid = false;
    //    errors["company_primary_contact_phoneno"] = "Please enter phone number";
    //    ReactDOM.findDOMNode(this.refs.phonenumber).focus();

    // }
    //else if(this.state.company_primary_contact_phoneno.length !=14){
    //   formIsValid = false;
    //   errors["company_primary_contact_phoneno"] = "Please enter 10 digits phone number";
    //   ReactDOM.findDOMNode(this.refs.phonenumber).focus();

    // }
    if (!this.state.company_primary_email) {
      formIsValid = false;
      errors["company_primary_email"] = "Please enter email";
      ReactDOM.findDOMNode(this.refs.email).focus();
    }
    if (typeof this.state.company_primary_email !== "undefined") {
      let lastAtPos = this.state.company_primary_email.lastIndexOf("@");
      let lastDotPos = this.state.company_primary_email.lastIndexOf(".");

      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          this.state.company_primary_email.indexOf("@@") == -1 &&
          lastDotPos > 2 &&
          this.state.company_primary_email.length - lastDotPos > 2
        )
      ) {
        formIsValid = false;
        errors["company_primary_email"] = "Please enter valid email";
        ReactDOM.findDOMNode(this.refs.email).focus();
      }
    }
    if (!this.state.company_primary_contact_lastname) {
      formIsValid = false;
      errors["company_primary_contact_lastname"] = "Please enter lastname";
      ReactDOM.findDOMNode(this.refs.lastname).focus();
    }
    if (!this.state.company_primary_contact_firstname) {
      formIsValid = false;
      errors["company_primary_contact_firstname"] = "Please enter firstname";
      ReactDOM.findDOMNode(this.refs.firstname).focus();
    }
    if (!this.state.zipcode) {
      formIsValid = false;
      errors["zipcode"] = "Please enter zip code";
      ReactDOM.findDOMNode(this.refs.zipcode).focus();
    } else if (this.state.zipcode.length > 15) {
      formIsValid = false;
      errors["zipcode"] = "Please enter valid zipcode";
      ReactDOM.findDOMNode(this.refs.zipcode).focus();
    }
    if (!this.state.state) {
      formIsValid = false;
      errors["state"] = "Please enter state";
      ReactDOM.findDOMNode(this.refs.state).focus();
    }
    if (!this.state.city) {
      formIsValid = false;
      errors["city"] = "Please enter city";
      ReactDOM.findDOMNode(this.refs.city).focus();
    }
    if (!this.state.company_address) {
      formIsValid = false;
      errors["company_address"] = "Please enter address";
      ReactDOM.findDOMNode(this.refs.company_address).focus();
    }
    if (!this.state.company_type) {
      formIsValid = false;
      errors["company_type"] = "Please select company type";
      ReactDOM.findDOMNode(this.refs.company_type).focus();
    }
    if (!this.state.company_email) {
      formIsValid = false;
      errors["company_email"] = "Please enter email";
      ReactDOM.findDOMNode(this.refs.company_email).focus();
    }
    if (typeof this.state.company_email !== "undefined") {
      let lastAtPos = this.state.company_email.lastIndexOf("@");
      let lastDotPos = this.state.company_email.lastIndexOf(".");

      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          this.state.company_email.indexOf("@@") == -1 &&
          lastDotPos > 2 &&
          this.state.company_email.length - lastDotPos > 2
        )
      ) {
        formIsValid = false;
        errors["company_email"] = "Please enter valid email";
        ReactDOM.findDOMNode(this.refs.company_email).focus();
      }
    }
    if (!this.state.company_name) {
      formIsValid = false;
      errors["company_name"] = "Please enter company name";
      ReactDOM.findDOMNode(this.refs.company_name).focus();
    }
    if (!this.state.company_business_model) {
      formIsValid = false;
      errors["company_business_model"] = "please select business model";
    }
    if (this.state.company_website && !/^https?:\/\//i.test(this.state.company_website)) {
      formIsValid = false;
      errors["company_website"] = "Please enter valid website";
      ReactDOM.findDOMNode(this.refs.site).focus();
    }

    /*  if(this.state.company_phoneno !='' && this.state.company_phoneno.length !=14){
      formIsValid = false;
      errors["company_phoneno"] = "Please enter 10 digits company phone number";
    } */
    /* if(this.state.company_primary_cellno !='' && this.state.company_primary_cellno.length !=14){
      formIsValid = false;
      errors["company_primary_cellno"] = "Please enter 10 digits company primary cell number";
    } */
    /* if(this.state.company_primary_officeno !='' && this.state.company_primary_officeno.length !=14){
      formIsValid = false;
      errors["company_primary_officeno"] = "Please enter 10 digits company primary office number";
    } */
    /* if(this.state.company_secondary_contactno !='' && this.state.company_secondary_contactno.length !=14){
      formIsValid = false;
      errors["company_secondary_contactno"] = "Please enter 10 digits company secondary contact number";
    } */

    /* if(!this.state.country){
      formIsValid = false;
      errors["country"] = "Please select country";
    } */

    this.setState({ errors: errors });
    return formIsValid;
  }

  handleSubmit = (event) => {
    event.preventDefault();
    if (this.handleValidation()) {
      this.setState({ loading: 1 });
      let formData = new FormData();
      formData.append("id", this.props.auth.user._id);
      formData.append("company_username", this.state.company_username);
      formData.append("company_name", this.state.company_name);
      formData.append("company_logo", this.state.company_logo);
      formData.append("company_email", this.state.company_email);
      formData.append("company_type", this.state.company_type);
      formData.append("company_industrey", this.state.company_industrey);
      formData.append("company_address", this.state.company_address);
      formData.append("company_phoneno", this.state.company_phoneno);
      formData.append("company_website", this.state.company_website);
      formData.append("company_primary_contact_firstname", this.state.company_primary_contact_firstname);
      formData.append("company_primary_contact_lastname", this.state.company_primary_contact_lastname);
      formData.append("company_primary_contact_middlename", this.state.company_primary_contact_middlename);
      formData.append("company_primary_contact_title", this.state.company_primary_contact_title);
      formData.append("company_primary_contact_phoneno", this.state.company_primary_contact_phoneno);
      formData.append("company_primary_email", this.state.company_primary_email);
      formData.append("company_primary_cellno", this.state.company_primary_cellno);
      formData.append("company_primary_officeno", this.state.company_primary_officeno);
      formData.append("company_primary_department", this.state.company_primary_department);
      formData.append("company_secondary_contactno", this.state.company_secondary_contactno);
      formData.append("naic_code", JSON.stringify(this.state.naic_code));
      formData.append("locations", this.state.locations);
      formData.append("country", this.state.country);
      formData.append("state", this.state.state);
      formData.append("city", this.state.city);
      formData.append("zipcode", this.state.zipcode);
      formData.append("status", this.state.status);
      formData.append("deleted", 0);
      formData.append("request_from", "admin");
      formData.append("company_business_model", this.state.company_business_model);
      formData.append("subindustries", JSON.stringify(this.state.subindustries));
      //console.log(formData);
      axios.post(AppConstants.API + "/cutomers/addcustomer", formData).then((res) => {
        //console.log(res);
        if (res.data.Status == "Success") {
          setTimeout((e) => {
            this.props.history.push(AppConstants.STAGADMIN + "/customers");
          }, 2500);
          this.setState({
            //errMsg: res.data.msg
            errMsg: <div className="alert alert-success fade show mt-3">{res.data.msg}</div>,
          });
        } else {
          this.setState({ loading: "" });
          setTimeout((e) => {
            this.setState({
              errMsg: "",
            });
          }, 2000);
          this.setState({
            //errMsg: res.data.msg
            errMsg: <div className="alert alert-danger fade show mt-3">{res.data.msg}</div>,
          });
        }
      });
    }
  };

  componentDidMount() {
    //Get Industries data
    fetch(AppConstants.API + "/industries/getallindustries")
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);
        if (data.Status == "Success") {
          this.setState({ industriesdata: data.industriesdata });
        } else {
          this.setState({ industriesdata: "" });
        }
      });
    //Get companies data
    fetch(AppConstants.API + "/companies/getallcompanies")
      .then((response) => response.json())
      .then((data) => {
        //console.log(" companies ", data);
        if (data.Status == "Success") {
          this.setState({ companiesdata: data.companiesdata });
        } else {
          this.setState({ companiesdata: "" });
        }
      });

    //Get naic codes
    fetch(AppConstants.API + "/users/getallnaiccodes")
      .then((response) => response.json())
      .then((data) => {
        //console.log(" naic codes ", data);
        if (data.Status == "Success") {
          this.setState({ naiccodes: data.naiccodes });
        } else {
          this.setState({ naiccodes: "" });
        }
      });

    //Get all countries
    fetch(AppConstants.API + "/countries/getallcountries")
      .then((response) => response.json())
      .then((data) => {
        if (data.Status == "Success") {
          //console.log(data.getallcountries);
          this.setState({
            countries: data.getallcountries,
          });
        }
      });
    var element = document.getElementById("navItem-Companies-1");
    element.classList.add("active");
  }
  render() {
    const { user } = this.props.auth;

    const checkboxItems =
      this.state.company_industrey != ""
        ? this.state.subindustriesdata.map((planet) => {
            return (
              <div className="rd-btns">
                <Label check className="rd-btn">
                  <Input
                    type="checkbox"
                    className="rd-btn"
                    name="subindustry_name"
                    value={planet.subcatid}
                    onChange={this.handleInputChangeFund}
                    class="form-control"
                    id={planet.subcatid}
                  ></Input>
                  <span>{planet.subindustryname}</span>
                </Label>
              </div>
            );
          })
        : null;

    return (
      <Page title="Add Company">
        <ReactTitle title="Add Company" />
        <Link to={AppConstants.STAGADMIN + "/dashboard"}>Home</Link>
        &nbsp; | &nbsp;&nbsp;
        <Link to={AppConstants.STAGADMIN + "/customers"}>Companies</Link>
        <Form className="form-block" onSubmit={this.handleSubmit}>
          <Row>
            <Col xl={6} lg={12} md={12}>
              <Card>
                {/*<span className="error" style={{color: 'red'}}>{this.state.errMsg}</span>*/}
                <CardHeader>Basic info</CardHeader>
                <CardBody>
                  <FormGroup>
                    <Label for="exampleEmail">
                      Company Name{" "}
                      <span className="error" style={{ color: "red" }}>
                        *
                      </span>
                    </Label>
                    <Input
                      type="text"
                      name="company_name"
                      placeholder="Company Name"
                      onChange={this.handleInputChange}
                      ref="company_name"
                    />
                    <span className="error" style={{ color: "red" }}>
                      {this.state.errors["company_name"]}
                    </span>
                  </FormGroup>
                  <FormGroup>
                    <Label for="exampleEmail">
                      Email{" "}
                      <span className="error" style={{ color: "red" }}>
                        *
                      </span>
                    </Label>
                    <Input
                      type="text"
                      name="company_email"
                      placeholder="Email"
                      onChange={this.handleInputChange}
                      ref="company_email"
                    />
                    <span className="error" style={{ color: "red" }}>
                      {this.state.errors["company_email"]}
                    </span>
                  </FormGroup>
                  <FormGroup>
                    <Label for="exampleUrl">Company Logo</Label>
                    <div class="file-field input-field fileupload">
                      <div class="filebtn">
                        <span>
                          <i class="fa fa-upload" aria-hidden="true"></i>
                        </span>
                        <input type="file" name="company_logo" onChange={this.handleInputFileChange} />
                      </div>

                      <div class="file-path-wrapper">
                        <input class="file-path validate" type="text" placeholder=" Please upload company logo." />
                      </div>
                    </div>

                    <span className="error" style={{ color: "red" }}>
                      {this.state.errors["company_logo"]}
                    </span>
                  </FormGroup>
                  <FormGroup>
                    <Label for="exampleNumber">
                      Type{" "}
                      <span className="error" style={{ color: "red" }}>
                        *
                      </span>
                    </Label>
                    <Input
                      type="select"
                      name="company_type"
                      value={this.state.company_type}
                      onChange={this.handleInputChange}
                      ref="company_type"
                    >
                      <option value="">Select Company Type</option>
                      {this.state.companiesdata.map((companies, index) => {
                        if (companies.status == "Active") {
                          return (
                            <option key={index} value={companies._id}>
                              {companies.companyname}
                            </option>
                          );
                        }
                      })}
                    </Input>
                    <span className="error" style={{ color: "red" }}>
                      {this.state.errors["company_type"]}
                    </span>
                  </FormGroup>

                  {/* <FormGroup>
                  <Label for="exampleTime">Phone Number</Label>
                  <NumberFormat
                    format="(###) ###-####" mask=""
                    name="company_phoneno"
                    placeholder='Company Phone Number'
                    onChange={this.handleInputChange}
                    value={this.state.company_phoneno} class="form-control"/>
                    <span className="error" style={{color: 'red'}}>{this.state.errors["company_phoneno"]}</span> 
                </FormGroup> */}

                  <FormGroup>
                    <Label for="exampleDate">
                      Address{" "}
                      <span className="error" style={{ color: "red" }}>
                        *
                      </span>
                    </Label>
                    <Input
                      type="text"
                      name="company_address"
                      placeholder="Address"
                      onChange={this.handleInputChange}
                      ref="company_address"
                    />
                    <span className="error" style={{ color: "red" }}>
                      {this.state.errors["company_address"]}
                    </span>
                  </FormGroup>
                  <FormGroup>
                    <Label for="exampleSelect">
                      City{" "}
                      <span className="error" style={{ color: "red" }}>
                        *
                      </span>
                    </Label>
                    <Input type="text" name="city" placeholder="City" onChange={this.handleInputChange} ref="city" />
                    <span className="error" style={{ color: "red" }}>
                      {this.state.errors["city"]}
                    </span>
                  </FormGroup>

                  <FormGroup>
                    <Label for="exampleState">
                      State <span style={{ color: "red" }}> * </span>
                    </Label>
                    <Input
                      type="select"
                      name="state"
                      placeholder="State"
                      value={this.state.state}
                      onChange={this.handleInputChange}
                      ref="state"
                    >
                      <option value="">State</option>
                      <option value="Alabama">Alabama</option>
                      <option value="Alaska">Alaska</option>
                      <option value="Arizona">Arizona</option>
                      <option value="Arkansas">Arkansas</option>
                      <option value="California">California</option>
                      <option value="Colorado">Colorado</option>
                      <option value="Connecticut">Connecticut</option>
                      <option value="Delaware">Delaware</option>
                      <option value="District Of Columbia">District Of Columbia</option>
                      <option value="Florida">Florida</option>
                      <option value="Georgia">Georgia</option>
                      <option value="Hawaii">Hawaii</option>
                      <option value="Idaho">Idaho</option>
                      <option value="Illinois">Illinois</option>
                      <option value="Indiana">Indiana</option>
                      <option value="Iowa">Iowa</option>
                      <option value="Kansas">Kansas</option>
                      <option value="Kentucky">Kentucky</option>
                      <option value="Louisiana">Louisiana</option>
                      <option value="Maine">Maine</option>
                      <option value="Maryland">Maryland</option>
                      <option value="Massachusetts">Massachusetts</option>
                      <option value="Michigan">Michigan</option>
                      <option value="Minnesota">Minnesota</option>
                      <option value="Mississippi">Mississippi</option>
                      <option value="Missouri">Missouri</option>
                      <option value="Montana">Montana</option>
                      <option value="Nebraska">Nebraska</option>
                      <option value="Nevada">Nevada</option>
                      <option value="New Hampshire">New Hampshire</option>
                      <option value="New Jersey">New Jersey</option>
                      <option value="New Mexico">New Mexico</option>
                      <option value="New York">New York</option>
                      <option value="North Carolina">North Carolina</option>
                      <option value="North Dakota">North Dakota</option>
                      <option value="Ohio">Ohio</option>
                      <option value="Oklahoma">Oklahoma</option>
                      <option value="Oregon">Oregon</option>
                      <option value="Pennsylvania">Pennsylvania</option>
                      <option value="Rhode Island">Rhode Island</option>
                      <option value="South Carolina">South Carolina</option>
                      <option value="South Dakota">South Dakota</option>
                      <option value="Tennessee">Tennessee</option>
                      <option value="Texas">Texas</option>
                      <option value="Utah">Utah</option>
                      <option value="Vermont">Vermont</option>
                      <option value="Virginia">Virginia</option>
                      <option value="Washington">Washington</option>
                      <option value="West Virginia">West Virginia</option>
                      <option value="Wisconsin">Wisconsin</option>
                      <option value="Wyoming">Wyoming</option>
                    </Input>
                    <span className="error" style={{ color: "red" }}>
                      {this.state.errors["state"]}
                    </span>
                  </FormGroup>

                  {/* <FormGroup>
                  <Label for="exampleSelect">State <span className="error" style={{color: 'red'}}>*</span></Label>
                  <Input
                      type="text"
                      name="state"
                      placeholder="State"
                      onChange={ this.handleInputChange }      
                      ref ="state"            
                    />
                    <span className="error" style={{color: 'red'}}>{this.state.errors["state"]}</span> 
                </FormGroup> */}

                  <FormGroup>
                    <Label for="exampleText">
                      Zip Code{" "}
                      <span className="error" style={{ color: "red" }}>
                        *
                      </span>
                    </Label>
                    <Input
                      type="text"
                      name="zipcode"
                      placeholder="Zip Code"
                      value={this.state.zipcode}
                      //onChange={ this.handleInputChange }
                      onChange={this.onChangeNumberkey}
                      ref="zipcode"
                    />
                    <span className="error" style={{ color: "red" }}>
                      {this.state.errors["zipcode"]}
                    </span>
                  </FormGroup>
                  <FormGroup>
                    <label>
                      What is your Company Business Model?{" "}
                      <span className="error" style={{ color: "red" }}>
                        *
                      </span>
                    </label>
                    <div>
                      <div class="form-group form-check inline">
                        <input
                          type="radio"
                          name="company_business_model"
                          id="company_business_model_B2B"
                          value="B2B"
                          checked={this.state.company_business_model == "B2B"}
                          onChange={this.handleCompanyModelChange}
                        />
                        <label for="company_business_model_B2B">B2B</label>
                      </div>
                      <div class="form-group form-check inline">
                        <input
                          type="radio"
                          name="company_business_model"
                          id="company_business_model_B2C"
                          value="B2C"
                          checked={this.state.company_business_model == "B2C"}
                          onChange={this.handleCompanyModelChange}
                        />
                        <label for="company_business_model_B2C">B2C</label>
                      </div>
                      <div class="form-group form-check inline">
                        <input
                          type="radio"
                          name="company_business_model"
                          id="company_business_model_both"
                          checked={this.state.company_business_model == "Both"}
                          value="Both"
                          onChange={this.handleCompanyModelChange}
                        />
                        <label for="company_business_model_both">Both</label>
                      </div>
                    </div>
                    <span className="error" style={{ color: "red" }}>
                      {this.state.errors["company_business_model"]}
                    </span>
                  </FormGroup>
                  {/*  </Form> */}
                </CardBody>
              </Card>
            </Col>

            <Col xl={6} lg={12} md={12}>
              <Card>
                <CardHeader>Contact info</CardHeader>
                <CardBody>
                  {/* <Form onSubmit= { this.handleSubmit }> */}
                  <FormGroup>
                    <Label for="exampleEmail">
                      First Name{" "}
                      <span className="error" style={{ color: "red" }}>
                        *
                      </span>
                    </Label>
                    <Input
                      type="text"
                      name="company_primary_contact_firstname"
                      placeholder="First Name"
                      onChange={this.handleInputChange}
                      ref="firstname"
                    />
                    <span className="error" style={{ color: "red" }}>
                      {this.state.errors["company_primary_contact_firstname"]}
                    </span>
                  </FormGroup>
                  <FormGroup>
                    <Label for="exampleSelect">Middle Name</Label>
                    <Input
                      type="text"
                      name="company_primary_contact_middlename"
                      placeholder="Middle Name"
                      onChange={this.handleInputChange}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="exampleEmail">
                      Last Name{" "}
                      <span className="error" style={{ color: "red" }}>
                        *
                      </span>
                    </Label>
                    <Input
                      type="text"
                      name="company_primary_contact_lastname"
                      placeholder="Last Name"
                      onChange={this.handleInputChange}
                      ref="lastname"
                    />
                    <span className="error" style={{ color: "red" }}>
                      {this.state.errors["company_primary_contact_lastname"]}
                    </span>
                  </FormGroup>
                  <FormGroup>
                    <Label for="exampleSelect">
                      Email{" "}
                      <span className="error" style={{ color: "red" }}>
                        *
                      </span>
                    </Label>
                    <Input
                      type="text"
                      name="company_primary_email"
                      placeholder="Email"
                      onChange={this.handleInputChange}
                      ref="email"
                    />
                    <span className="error" style={{ color: "red" }}>
                      {this.state.errors["company_primary_email"]}
                    </span>
                  </FormGroup>
                  <FormGroup>
                    <Label for="exampleSelect">Title</Label>
                    <Input
                      type="text"
                      name="company_primary_contact_title"
                      placeholder="Title"
                      onChange={this.handleInputChange}
                    />
                  </FormGroup>
                  {/* <FormGroup>
                  <Label for="exampleSelect">
                  Phone Number <span className="error" style={{color: 'red'}}>*</span>
                  </Label>
                  <NumberFormat
                    format="(###) ###-####" mask=""
                    name="company_primary_contact_phoneno"
                    placeholder='Phone Number'
                    onChange={this.handleInputChange}
                    value={this.state.company_primary_contact_phoneno} class="form-control" ref ="phonenumber" />
                    <span className="error" style={{color: 'red'}}>{this.state.errors["company_primary_contact_phoneno"]}</span>    
                </FormGroup>                 */}

                  <FormGroup>
                    <Label for="exampleSelect">
                      Phone Number
                      {/* <span className="error" style={{color: 'red'}}>*</span> */}
                    </Label>
                    <Input
                      type="text"
                      name="company_primary_contact_phoneno"
                      placeholder="Phone Number"
                      onChange={this.handlePhoneChange}
                      value={this.state.company_primary_contact_phoneno}
                      maxLength="15"
                    />
                    {/* <span className="error" style={{color: 'red'}}>{this.state.errors["company_primary_contact_phoneno"]}</span>  */}
                  </FormGroup>

                  <FormGroup>
                    <Label for="exampleSelect">Department</Label>
                    <Input
                      type="text"
                      name="company_primary_department"
                      placeholder="Department"
                      onChange={this.handleInputChange}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="exampleColor">Website</Label>
                    <Input
                      type="text"
                      name="company_website"
                      value={this.state.company_website}
                      placeholder="Website"
                      onChange={this.handleInputChange}
                      ref="site"
                    />
                    <span className="error" style={{ color: "red" }}>
                      {this.state.errors["company_website"]}
                    </span>
                  </FormGroup>
                  <FormGroup>
                    <Label for="exampleFile">Status</Label>
                    <Input type="select" name="status" onChange={this.handleInputChange}>
                      <option value="Active">Active</option>
                      <option value="In-Active">In Active</option>
                    </Input>
                  </FormGroup>
                </CardBody>
              </Card>
            </Col>

            <Col xl={12} lg={12} md={12}>
              <Card>
                <CardHeader>NAICS Codes</CardHeader>
                <CardBody>
                  <FormGroup>
                    <Label for="exampleSearch">
                      NAICS Codes{" "}
                      <span className="error" style={{ color: "red" }}>
                        *
                      </span>
                    </Label>
                    <Typeahead
                      clearButton
                      labelKey={(option) => `${option.NAICS_Description}` + `(${option.NAICS})`}
                      multiple
                      options={this.state.naiccodes}
                      placeholder="NAICS Codes"
                      name="naic_code"
                      value={this.state.naiccodes}
                      ref="naiccode"
                      onChange={(selected) => {
                        this.setState({ naic_code: selected });
                      }}
                      class="form-control"
                    />
                    <span className="error" style={{ color: "red" }}>
                      {this.state.errors["naic_code"]}
                    </span>
                  </FormGroup>
                </CardBody>
              </Card>
            </Col>

            <Col xl={12} lg={12} md={12}>
              <Card>
                <CardHeader>Industry and Sub Industries</CardHeader>
                <CardBody>
                  <FormGroup>
                    <Label for="exampleDatetime">
                      {" "}
                      Industry{" "}
                      <span className="error" style={{ color: "red" }}>
                        *
                      </span>
                    </Label>
                    <Input
                      type="select"
                      name="company_industrey"
                      value={this.state.company_industrey}
                      onChange={this.handleIndustryChange}
                      ref="company_industrey"
                    >
                      <option value="">Select Industry Type</option>
                      {this.state.industriesdata.map((industries, index) => {
                        if (industries.status == "Active") {
                          return (
                            <option key={index} value={industries._id}>
                              {industries.industryname}
                            </option>
                          );
                        }
                      })}
                    </Input>
                    <span className="error" style={{ color: "red" }}>
                      {this.state.errors["company_industrey"]}
                    </span>
                  </FormGroup>
                  <FormGroup>{checkboxItems}</FormGroup>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md={12} className="form-btn">
              <Button
                class="btn btn-primary addbtn"
                className="bg-gradient-theme-left border-0"
                onClick={this.handleSubmit}
                title="Add Company"
              >
                Add
                <div style={this.state.loading ? {} : { display: "none" }} class="image-fill w-25 loader-login-div ">
                  <img src={Loading} alt="No Image" className="can-click " />
                </div>
              </Button>
              &nbsp;&nbsp;
              <Link to={AppConstants.STAGADMIN + "/customers"} class="btn btn-primary redbtn" title="Cancel Company">
                Cancel
              </Link>
            </Col>
          </Row>
          {this.state.errMsg}
        </Form>
      </Page>
    );
  }
}

AddCustomer.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

//export default ChangepasswordPage;
export default connect(mapStateToProps, {})(AddCustomer);
