import Page from '../../../src/components/Page';
import React from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import { BootstrapTable, TableHeaderColumn, InsertButton } from 'react-bootstrap-table';
//import { AddCompany, DataFormat } from 'components/NewUsers';
import { Card, Button, CardBody, CardHeader, Col, Row, Table, Modal,
  ModalBody,
  ModalFooter,
  ModalHeader } from 'reactstrap';
import {
    MdCreate,
    MdDelete,
    MdRemoveRedEye,MdAdd
  } from 'react-icons/md';
import AppConstants from '../../AppConstants';
import {ReactTitle} from 'react-meta-tags';

class Cms extends React.Component {
  constructor(props) {
    super(props);
    this.state = {      
      cmsdata: [],
      errors: {},
      errMsg:false
  };
  this.format = this.format.bind(this);
}
  
format(cell, row){
  return <div className="ca-controls"><Link to={AppConstants.STAGADMIN+"/editcms/"+row._id} class="btn btn-secondary editbtn" title="Edit CMS" style={{fontSize:"15px"}}>Edit</Link>
  {/* <span onClick={ this.DeleteCompany(row._id) } class="text-muted" title="Delete Company Type"><MdDelete /></span> */}{/* <a href="#" className="text-muted"><MdRemoveRedEye /></a> */}</div>;
}
disformat(cell, row){
  return <div>
     <div dangerouslySetInnerHTML={{__html:row.description}} /> 

  </div>
}
  createCustomInsertButton = (onClick) => {
    return (
        <span> &nbsp; <Link to={AppConstants.STAGADMIN+"/addcms"} class="btn btn-warning addbtn" title="Add CMS">Add</Link></span>
    );
  }
  componentDidMount() {
    fetch(AppConstants.API+'/cms/getallcms').then(response => response.json())
      .then(data => {
     // console.log(data);
      if(data.Status == "Success")
      {
        this.setState({ cmsdata: data.cmsdata });
      }
      else
      {
        this.setState({ cmsdata: "" });
      }    
    });

    var element = document.getElementById("navItem-Companies-1");
    element.classList.remove("active");
    
    var element = document.getElementById("navItem-Certifications and Agencies-5");
    element.classList.remove("active");    
    var element = document.getElementById("navItem-NAICS/NIGP Codes-4");
    element.classList.remove("active");
    var element = document.getElementById("navItem-Opportunities-3");
    element.classList.remove("active");
    var element = document.getElementById("navItem-Vendors-2");
    element.classList.remove("active");
    var element = document.getElementById("navItem-Company Types-0");
    element.classList.remove("active");
    var element = document.getElementById("navItem-Industry Types-1");
    element.classList.remove("active");
    var element = document.getElementById("navItem-Reports-6");
    element.classList.remove("active");

    var element = document.getElementById("navItem-CMS-7");
    element.classList.add("active");
    
  }
   render() {
    if(localStorage.jwtTokenAdmin != undefined)
    {
    const options = {
      exportCSVText: 'Export CSV',    
      insertText: "New",    
      deleteText: 'Delete',    
      saveText: 'Save',    
      closeText: 'Close',
      insertBtn: this.createCustomInsertButton,
      page: 1,  // which page you want to show as default
      sizePerPageList: [ {
        text: '5', value: 5
      }, {
        text: '10', value: 10
      }, {
        text: '20', value: 20
      },
      {
        text: '50', value: 50
      }
    ], // you can change the dropdown list for size per page
      sizePerPage: 10,  // which size per page you want to locate as default
      pageStartIndex: 1, // where to start counting the pages
      paginationSize: 3,  // the pagination bar size.
     // prePage: 'Prev', // Previous page button text
      //nextPage: 'Next', // Next page button text
      //firstPage: 'First', // First page button text
      //lastPage: 'Last', // Last page button text
      paginationShowsTotal: this.state.cmsdata.length,  // Accept bool or function
      paginationPosition: 'bottom',  // default is bottom, top and both is all available
      // hideSizePerPage: true > You can hide the dropdown for sizePerPage
      // alwaysShowAllBtns: true // Always show next and previous button
      // withFirstAndLast: false > Hide the going to First and Last page button
      insertModal: this.createCustomModal    
    };
    return (
        <Page title="CMS">
       <ReactTitle title="CMS"/>
          <Link to={AppConstants.STAGADMIN+'/dashboard'}>
          Home
          </Link>
          &nbsp; | &nbsp;&nbsp;
          <Link to={AppConstants.STAGADMIN+'/cms'}>
          CMS
          </Link>
            <row>
              <Card  className="data-table">
                <CardBody>
                  <div>
                  <span className="error" style={{color: 'red'}}>{this.state.errMsg}</span>
                  <BootstrapTable data={ this.state.cmsdata } search={ true } pagination={ true } multiColumnSearch={ true } options={ options }
                  insertRow
                  >
                    <TableHeaderColumn dataField='_id' isKey={ true } searchable={ false } dataSort={ true } hidden={true}>ID</TableHeaderColumn>
                    <TableHeaderColumn dataField='title' width={'20%'} dataSort={ true }>Title</TableHeaderColumn>                    
                    <TableHeaderColumn  style={{'white-space': 'initial'}} dataField='description' dataSort={ true } dataFormat={this.disformat.bind(this)}>Description</TableHeaderColumn>
                    <TableHeaderColumn dataField='actions' width={'20%'} dataFormat={this.format.bind(this)} export={ false }>Actions</TableHeaderColumn>
                  </BootstrapTable>
                </div>
                </CardBody>
              </Card>
            </row>
          </Page>
      );
    }
    else 
    {
        window.location.href = AppConstants.STAGADMIN+'/'
    }
  }

};

export default Cms;