import React from 'react';
import { Navbar, Nav, NavItem } from 'reactstrap';
const Footer = () => {
  return (
    <footer class="p-5 text-center">
      <Nav navbar>
        <NavItem>
        {/* &nbsp; Copyright 2019, Chamber of Commerce RFP/RFI */}
        © {(new Date()).getFullYear()} - Buying Diverse. All rights reserved &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Created by <a href="https://www.thinkebiz.net/" target="_blank" style={{color:"white"}}>eBiz Solutions</a>
        </NavItem>
      </Nav>
    </footer>
  );
};

export default Footer;
