import React from 'react';
import { Link } from 'react-router-dom';
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  FormFeedback,
} from 'reactstrap';
import Page from '../../../src/components/Page';
import axios from 'axios';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import AppConstants from '../../AppConstants';
import Loading from '../../assets/img/loader.gif';
import {ReactTitle} from 'react-meta-tags';
import ReactDOM from 'react-dom';


class Editagency extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      _id:this.props.auth.user._id,
      id:this.props.match.params.id,
      certfy_id:'',
      agencyname:'',
      status:'',       
      errors: {},
      errMsg:'',
      loading: '',
    }
    this.handleSubmit = this.handleSubmit.bind(this);    
    this.handleInputChange = this.handleInputChange.bind(this); 
  }
  
  handleInputChange(e) {
    this.setState({
        [e.target.name]: e.target.value
    });
    //console.log(this.state);
  }
  
  /** Validations */
  handleValidation(){
    let errors = {};
    let formIsValid = true;
    if(!this.state.agencyname){
      formIsValid = false;
      errors["agencyname"] = "Please enter agency name";
      ReactDOM.findDOMNode(this.refs.agencyname).focus();
    }

    this.setState({errors: errors});
    return formIsValid;
  }

  handleSubmit = event => {
    event.preventDefault();
    //alert('form submitted');    
     if(this.handleValidation()){
      this.setState({loading:1});
      const agencydata = {
        id:this.props.match.params.id,
        certfy_id:this.state.certfy_id,
        agencyname: this.state.agencyname,
        status: this.state.status
      }
      axios.post(AppConstants.API+'/agency/editagency',agencydata)
        .then(res => {   
          console.log(res);   
         if(res.data.Status == "Success"){
            setTimeout((e) => {           
              this.props.history.push(AppConstants.STAGADMIN+'/agencies/'+agencydata.certfy_id);
            }, 2000);
            this.setState({
            //errMsg: res.data.msg
            errMsg: (<div className="alert alert-success fade show mt-3">{res.data.msg}</div>)
          });
        }
        else{
          this.setState({loading:''});
          setTimeout((e) => {
            this.setState({
              errMsg: ''
            });
           },2000);
          this.setState({
            //errMsg: res.data.msg
            errMsg: (<div className="alert alert-danger fade show mt-3">{res.data.msg}</div>)
          });
        }
      });
    } 
  };
  componentDidMount() {
    //console.log(this.props.match.params.id);
    const that = this; 
    fetch(AppConstants.API+'/agency/getagencydetails/'+this.props.match.params.id)
    .then(function(res) {
      //console.log(res.status);
      if(res.status == 200){
        return res.json();
      }
    })
    .then(function(data) {
      console.log(data.data.agencies._id);
      if(data.Status == "Success"){
        that.setState({
          certfy_id:data.data._id,
          agency_id:data.data.agencies._id,
          agencyname:data.data.agencies.agencyname,
          status:data.data.agencies.status
        });
      }else
      {
        console.log('invalid data');
        this.setState({
          errMsg: data.Status
        });
      }
    });
    var element = document.getElementById("navItem-Certifications and Agencies-5");
    element.classList.add("active");
  }  
  render() {
  const {user} = this.props.auth;
  return (
    <Page title="Edit Agency">
     <ReactTitle title="Edit Agency"/>
      <Link to={AppConstants.STAGADMIN+'/dashboard'}>
          Home
        </Link>
        &nbsp; | &nbsp;&nbsp;
        <Link to={AppConstants.STAGADMIN+'/certifications'}>
        Certifications
        </Link>
        &nbsp; | &nbsp;&nbsp;
        <Link to={AppConstants.STAGADMIN+"/agencies/"+this.state.certfy_id}>
        Agencies
        </Link>
        <Form className="form-block"  name="changeadminpassword" onSubmit= { this.handleSubmit }>
      <Row>
        <Col xl={12} lg={12} md={12}>
          <Card>
            <CardBody>
              
                <FormGroup>
                  <Label for="examplePassword">
                  </Label>
                                     
                  
                </FormGroup>

                <FormGroup>
                  <Label for="examplePassword" >
                    Agency Name <span className="error" style={{color: 'red'}}>*</span>
                  </Label>
                  
                    <Input
                      type="text"
                      name="agencyname"
                      placeholder="Agency Name"
                      value={this.state.agencyname}
                      onChange={ this.handleInputChange }  ref ="agencyname" className="col-md-6"               
                    />
                    <span className="error" style={{color: 'red'}}>{this.state.errors["agencyname"]}</span>
                  
                </FormGroup>                
                <FormGroup >
                  <Label for="exampleSelect">
                    Status
                  </Label>
                  
                  <Input className="col-md-6" type="select" name="status" onChange={ this.handleInputChange } value={this.state.status}>
                    <option value="Active" selected="{{this.state.status === 
                    this.value}}">Active</option>
                    <option value="In Active" selected="{{this.state.status === 
                     this.value}}">In Active</option>                    
                  </Input>
                  
                </FormGroup>
                
              
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
      <Col md={12} className="form-btn">
                    <Button class="btn btn-primary addbtn" className="bg-gradient-theme-left border-0"  onClick={ this.handleSubmit } title="Update Agency">Update<div style={this.state.loading ? {} : { display: 'none' }} class="image-fill w-25 loader-login-div "><img src={Loading} alt="No Image" className="can-click " /></div></Button>&nbsp;&nbsp;
                    <Link to={AppConstants.STAGADMIN+"/agencies/"+this.state.certfy_id} class="btn btn-primary redbtn" title="Cancel Agency">Cancel</Link>
                    
                  </Col>
                
      </Row>
      {this.state.errMsg}
      </Form>
    </Page>
  );
};
}

Editagency.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors
});

//export default ChangepasswordPage;
export  default connect(mapStateToProps, {})(Editagency);