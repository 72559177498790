import logo200Image from '../assets/img/logo/buyingdiverse-logo-dark.png';
import React from 'react';
import { Button, Form, FormGroup, Input, Label } from 'reactstrap';
import { Card, Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Link } from 'react-router-dom';
import AppConstants from '../AppConstants';
import Loading from '../assets/img/loader.gif';


class ForgotPassword extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      fields: {},
      errors: {},
      invalidUser:'',
      errMsg:'',
      loading: ''

    }
  }
  
  handleChange(field, e){    		
    let fields = this.state.fields;
    fields[field] = e.target.value;        
    this.setState({fields});
  }

  /** Validations */
  handleValidation(){
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    //Email
    if(!fields["email"]){
      formIsValid = false;
      errors["email"] = "Please enter email";
    }
    if(typeof fields["email"] !== "undefined"){
      let lastAtPos = fields["email"].lastIndexOf('@');
      let lastDotPos = fields["email"].lastIndexOf('.');

      if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fields["email"].indexOf('@@') == -1 && lastDotPos > 2 && (fields["email"].length - lastDotPos) > 2)) {
        formIsValid = false;
        errors["email"] = "Email is not valid";
      }
    }  
    

    this.setState({errors: errors});
    return formIsValid;
  }
  handleSubmit = event => {
    //console.log(process.env.REACT_APP_API_URL+'/users/login');
    event.preventDefault();
    if(this.handleValidation()){
      this.setState({loading:1});
      const adminforgotpwd = {
        email: this.state.fields['email'],
        admintype: 'admin'
      }
      //console.log(adminforgotpwd);
      axios.post(AppConstants.API+'/users/forgotpasswordadmin',adminforgotpwd)
        .then(res => {   
          //console.log(res);   
         if(res.data.Status == "Success"){ 
          this.setState({loading:''});       
          this.setState({
            errMsg: res.data.msg
          });          
        }
        else{
          this.setState({loading:''});          
          this.setState({
            errMsg: res.data.msg
          });
          setTimeout((e) => {
            this.setState({
              errMsg: ''
            });
           },2000);       
        } 
      });      
    }
  };
  
  
  render() {
    const {
      showLogo,
      onLogoClick,
    } = this.props;
    
    return (
      <Row
        style={{
          height: '100vh',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <Col md={6} lg={4}>
          <Card body>
         
            <Form name="loginform" onSubmit= {this.handleSubmit.bind(this)}>
              {showLogo && (
                <div className="text-center pb-4">
                  <img
                    src={logo200Image}
                    className="rounded"
                    style={{ width: 130, cursor: 'pointer' }}
                    alt="logo"
                    onClick={onLogoClick}
                    title="Buying Diverse"
                  />
                </div>
              )}
              <span className="error" style={{color: 'red'}}>{this.state.errMsg}</span>
              <FormGroup>
                <Label for="exampleEmail">Email</Label>
                <Input
                  type="text"                 
                  placeholder="Email"
                  onChange={this.handleChange.bind(this, "email")}
                  name="email"
                  value={this.state.fields["email"]}
                />
                <span className="error" style={{color: 'red'}}>{this.state.errors["email"]}</span>
              </FormGroup>                       
              <Button
                size="lg"
                className="cst-login-btn border-0"
                block
                onClick={this.handleSubmit} title="Send Mail">
                Send Mail
                <div style={this.state.loading ? {} : { display: 'none' }} class="image-fill w-25 loader-login-div "><img src={Loading} alt="No Image" className="can-click " /></div>
              </Button>
              <div className="text-center pt-1">
                <h6>or</h6>
                <h6>
                    <Link to={AppConstants.STAGADMIN+"/"} title="Login">Login</Link>       
                </h6>
              </div>             
            </Form>
          </Card>
        </Col>
      </Row>
    );
  }
}
ForgotPassword.defaultProps = {
  showLogo: true
};
ForgotPassword.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors
});
export  default connect(mapStateToProps, {})(ForgotPassword);
