import Page from "../../../src/components/Page";
import React from "react";
import {Link} from "react-router-dom";
import axios from "axios";
import "react-bootstrap-table/dist/react-bootstrap-table-all.min.css";
import {BootstrapTable, TableHeaderColumn} from "react-bootstrap-table";
import {Card, CardBody, Input, Row} from "reactstrap";
import AppConstants from "../../AppConstants";
import {ReactTitle} from "react-meta-tags";
import Workbook from "react-xlsx-workbook";

class Rfprfireport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rfprfireportdata: [],
      errors: {},
      errMsg: false,
      businessType: "",
    };
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(e) {
    //console.log(e.target.value);

    this.setState({
      [e.target.name]: e.target.value,
    });
    //var Type = e.target.value;

    if (e.target.value == "all") {
      fetch(
        AppConstants.API + "/reports/getallrfprfireporttype/" + e.target.value
      )
        .then((response) => response.json())
        .then((data) => {
          //console.log(data);
          if (data.Status == "Success") {
            this.setState({rfprfireportdata: data.rfprfireportdata});
          } else {
            this.setState({rfprfireportdata: []});
          }
        });
    } else if (e.target.value == "minority_business") {
      fetch(
        AppConstants.API + "/reports/getallrfprfireporttype/" + e.target.value
      )
        .then((response) => response.json())
        .then((data) => {
          //console.log(data);
          if (data.Status == "Success") {
            this.setState({rfprfireportdata: data.rfprfireportdata});
          } else {
            this.setState({rfprfireportdata: []});
          }
        });
    } else if (e.target.value == "women_owned_business") {
      fetch(
        AppConstants.API + "/reports/getallrfprfireporttype/" + e.target.value
      )
        .then((response) => response.json())
        .then((data) => {
          //console.log(data);
          if (data.Status == "Success") {
            this.setState({rfprfireportdata: data.rfprfireportdata});
          } else {
            this.setState({rfprfireportdata: []});
          }
        });
    } else {
      fetch(AppConstants.API + "/reports/getallrfprfireport")
        .then((response) => response.json())
        .then((data) => {
          //console.log(data);
          if (data.Status == "Success") {
            this.setState({rfprfireportdata: data.rfprfireportdata});
          } else {
            this.setState({rfprfireportdata: []});
          }
        });
    }
    // this.setState({
    //     [e.target.name]: e.target.value
    // });
    // var Type = e.target.value;
  }

  componentDidMount() {
    fetch(AppConstants.API + "/reports/getallrfprfireport")
      .then((response) => response.json())
      .then((data) => {
        //console.log(data);
        if (data.Status == "Success") {
          this.setState({rfprfireportdata: data.rfprfireportdata});
        } else {
          this.setState({rfprfireportdata: []});
        }
      });

    var element = document.getElementById("navItem-Companies-1");
    element.classList.remove("active");

    var element = document.getElementById(
      "navItem-Certifications and Agencies-5"
    );
    element.classList.remove("active");
    var element = document.getElementById("navItem-NAICS/NIGP Codes-4");
    element.classList.remove("active");
    var element = document.getElementById("navItem-Opportunities-3");
    element.classList.remove("active");
    var element = document.getElementById("navItem-Vendors-2");
    element.classList.remove("active");
    var element = document.getElementById("navItem-Company Types-0");
    element.classList.remove("active");
    var element = document.getElementById("navItem-Industry Types-1");
    element.classList.remove("active");
    var element = document.getElementById("navItem-Reports-6");
    element.classList.add("active");
  }

  createCustomInsertButton = (click) => {
    return (
      <span>
        {this.state.rfprfireportdata.length > 0 ? (
          <Workbook
            title="Export Opportunity"
            filename="Export Opportunity.xlsx"
            element={
              <span
                className="btn btn-secondary editbtn"
                title="Export Opportunity"
                style={{marginTop:"50px", marginLeft:"5px"}}
              >
                Export
              </span>
            }
          >
            <Workbook.Sheet data={this.state.rfprfireportdata} name="Sheet A">
              <Workbook.Column label="Name Of Opportunity" value="name_ofrfp" />
              <Workbook.Column label="Company Name" value="company_name" />
              <Workbook.Column label="Liked Vendors" value="vendorscount" />
              <Workbook.Column
                label="Feedback Vendors"
                value="vendorfeedback"
              />
            </Workbook.Sheet>
          </Workbook>
        ) : (
          ""
        )}
      </span>
    );
  };

  dateFormat(cell, row) {
    var date = row.rfp_post_date;
    var date = new Date(date);
    return (
      ("0" + (date.getMonth() + 1)).slice(-2) +
      "-" +
      (date.getDate() < 10 ? "0" : "") +
      date.getDate() +
      "-" +
      date.getFullYear()
    );
    //return date.getFullYear() + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-" + date.getDate() + " " + strTime;
  }

  rfpdetails(cell, row) {
    return (
      <div>
        <Link
          to={AppConstants.STAGADMIN + "/viewrfprfi/" + row._id}
          style={{color: "#212529"}}
          title="View Opportunity Details"
        >
          {row.name_ofrfp}
        </Link>
      </div>
    );
  }
  datecountFormat(cell, row) {
    if (row.vendorscount > 0) {
      return (
        <div>
          <Link
            to={AppConstants.STAGADMIN + "/likedrfpvendors/" + row._id}
            style={{color: "#212529"}}
            title="Liked Vendors"
          >
            {row.vendorscount}
          </Link>
        </div>
      );
    } else {
      return <div>{row.vendorscount}</div>;
    }
  }
  datafeedbackFormat(cell, row) {
    if (row.vendorfeedback > 0) {
      return (
        <div>
          <Link
            to={AppConstants.STAGADMIN + "/feedbackrfpvendors/" + row._id}
            style={{color: "#212529"}}
            title="Feedback Vendors"
          >
            {row.vendorfeedback}
          </Link>
        </div>
      );
    } else {
      return <div>{row.vendorfeedback}</div>;
    }
  }

  render() {
    if (localStorage.jwtTokenAdmin != undefined) {
      const options = {
        exportCSVText: "Export CSV",
        insertText: "New",
        deleteText: "Delete",
        saveText: "Save",
        closeText: "Close",
        insertBtn: this.createCustomInsertButton,
        page: 1, // which page you want to show as default
        sizePerPageList: [
          {
            text: "5",
            value: 5,
          },
          {
            text: "10",
            value: 10,
          },
          {
            text: "20",
            value: 20,
          },
          {
            text: "50",
            value: 50,
          },
        ], // you can change the dropdown list for size per page
        sizePerPage: 10, // which size per page you want to locate as default
        pageStartIndex: 1, // where to start counting the pages
        paginationSize: 3, // the pagination bar size.
        // prePage: 'Prev', // Previous page button text
        //nextPage: 'Next', // Next page button text
        //firstPage: 'First', // First page button text
        //lastPage: 'Last', // Last page button text
        paginationShowsTotal: this.state.rfprfireportdata.length, // Accept bool or function
        paginationPosition: "bottom", // default is bottom, top and both is all available
        // hideSizePerPage: true > You can hide the dropdown for sizePerPage
        // alwaysShowAllBtns: true // Always show next and previous button
        // withFirstAndLast: false > Hide the going to First and Last page button
        insertModal: this.createCustomModal,
      };

      return (
        <Page title="Opportunities Report">
          <ReactTitle title="Opportunities Report" />
          <Link to={AppConstants.STAGADMIN + "/dashboard"}>Home</Link>
          &nbsp; | &nbsp;&nbsp;
          <Link to={AppConstants.STAGADMIN + "/reports"}>Reports</Link>
          <row>
            <Card className="data-table">
              <CardBody>
                <Row>
                <div>
                  <span className="error" style={{color: "red"}}>
                    {this.state.errMsg}
                  </span>
                  {/* <div style={{position: 'absolute','width':'50%',"z-index":'99'}}> */}
                  <div style={{position: 'absolute','width':'50%',"z-index":'99'}}>
                          <Input className="col-md-6" type="select" name = "businessType" onChange={ this.handleInputChange } value={this.state.businessType}>
                              <option value="">Select Type </option>
                              <option value="all">Both </option>
                              <option value="minority_business">Minority Business</option>
                              <option value="women_owned_business">Women Owned Business</option>
                          </Input>
                    </div>
                  <BootstrapTable
                    className="rptb"
                    data={this.state.rfprfireportdata}
                    search={true}
                    pagination={true}
                    multiColumnSearch={true}
                    options={options}
                    insertRow
                  >
                    <TableHeaderColumn
                      dataField="_id"
                      isKey={true}
                      searchable={false}
                      dataSort={true}
                      hidden={true}
                    >
                      ID
                    </TableHeaderColumn>

                    <TableHeaderColumn
                      dataField="name_ofrfp"
                      dataSort={true}
                      dataFormat={this.rfpdetails.bind(this)}
                    >
                      Name Of Opportunity
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField="company_name" dataSort={true}>
                      Company Name
                    </TableHeaderColumn>

                    <TableHeaderColumn
                      dataField="rfp_post_date"
                      dataFormat={this.dateFormat.bind(this)}
                      dataSort={true}
                    >
                      Posted Date
                    </TableHeaderColumn>

                    <TableHeaderColumn
                      dataField="vendorscount"
                      dataFormat={this.datecountFormat.bind(this)}
                      width={"10%"}
                      export={false}
                    >
                      Liked Vendors
                    </TableHeaderColumn>

                    <TableHeaderColumn
                      dataField="vendorfeedback"
                      dataFormat={this.datafeedbackFormat.bind(this)}
                      width={"14%"}
                      export={false}
                    >
                      Feedback Vendors
                    </TableHeaderColumn>
                  </BootstrapTable>
                </div>
                </Row>
               
              </CardBody>
            </Card>
          </row>
        </Page>
      );
    } else {
      window.location.href = AppConstants.STAGADMIN + "/";
    }
  }
}

export default Rfprfireport;
