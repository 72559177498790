import React from "react";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  FormFeedback,
} from "reactstrap";
import Page from "../Page";
import axios from "axios";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import AppConstants from "../../AppConstants";
import Loading from "../../assets/img/loader.gif";
import {ReactTitle} from "react-meta-tags";
import ReactDOM from "react-dom";

class AddPartner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      _id: this.props.auth.user._id,
      status: "Active",
      errors: {},
      errMsg: "",
      loading: "",
      partnerImage: "",
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputFileChange = this.handleInputFileChange.bind(this);
  }

  handleInputChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleInputFileChange = (e) => {
    const inputName = e.target.name;

    if (!e.target.files || e.target.files.length === 0) {
      console.log("No files selected.");
      this.setState({
        [inputName]: null, // Clear the selected file
      });
      return;
    }

    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      const img = new Image();
      img.src = event.target.result;

      img.onload = () => {
        const width = img.width;
        const height = img.height;
        console.log(`Image width: ${width}px, height: ${height}px`);

        if (width !== 225 || height !== 143) {
          alert("Width must be 225px and Height must be 143px.");
          this.setState({
            [inputName]: null, // Clear the selected file
          });
        } else {
          console.log(file);
          this.setState({
            [inputName]: file,
          });
        }
      };
    };

    reader.readAsDataURL(file);
  };

  componentDidMount() {
    var element = document.getElementById("navItem-Partners-2");
    element.classList.add("active");
  }

  /** Validations */
  handleValidation() {
    // console.log(this.state.partnerImage);
    let errors = {};
    let formIsValid = true;

    if (!this.state.partnerImage) {
      formIsValid = false;
      errors["badge_error"] = "Please upload valid partner image";
    }
    if (
      this.state.partnerImage &&
      this.state.partnerImage.name &&
      !this.state.partnerImage.name.match(/.(jpg|jpeg|png)$/i)
    ) {
      formIsValid = false;
      errors["badge_error"] =
        "Please select valid partner image (Ex: jpg | jpeg | png )";
    }

    this.setState({errors: errors});
    return formIsValid;
  }

  handleSubmit = (event) => {
    event.preventDefault();
    //alert('form submitted');
    if (this.handleValidation()) {
      this.setState({loading: 1});
      //alert("form submitted");
      let formData = new FormData();
      formData.append("partnerImage", this.state.partnerImage);
      formData.append("status", this.state.status);

      axios
        .post(AppConstants.API + "/certifications/addPartner", formData)
        .then((res) => {
          console.log(res);
          if (res.data.Status == "Success") {
            setTimeout((e) => {
              this.props.history.push(AppConstants.STAGADMIN + "/partners");
            }, 2000);

            this.setState({
              //errMsg: res.data.msg
              errMsg: (
                <div className="alert alert-success fade show mt-3">
                  {res.data.msg}
                </div>
              ),
            });
          } else {
            this.setState({loading: ""});
            setTimeout((e) => {
              this.setState({
                errMsg: "",
              });
            }, 2000);
            this.setState({
              //errMsg: res.data.msg
              errMsg: (
                <div className="alert alert-danger fade show mt-3">
                  {res.data.msg}
                </div>
              ),
            });
          }
        });
    }
  };
  /* CancelSubmit = e => {
    e.preventDefault();
    window.location.href = '/certifications';
  } */

  render() {
    const {user} = this.props.auth;
    return (
      <Page title="Add Partner">
        <ReactTitle title="Add Partner" />
        <Link to={AppConstants.STAGADMIN + "/dashboard"}>Home</Link>
        &nbsp; | &nbsp;&nbsp;
        <Link to={AppConstants.STAGADMIN + "/partners"}>Partners</Link>
        <Form
          className="form-block"
          name="changeadminpassword"
          onSubmit={this.handleSubmit}
        >
          <Row>
            <Col xl={12} lg={12} md={12}>
              <Card>
                <CardBody>
                  <Row>
                    <Col xl={6} lg={6} md={6}>
                      <FormGroup>
                        <Label for="exampleFile" style={{display: "block"}}>
                          Image{" "}
                          <span className="error" style={{color: "red"}}>
                            *
                          </span>
                        </Label>
                        <div class="file-field input-field fileupload">
                          <div class="filebtn">
                            <span>
                              <i class="fa fa-upload" aria-hidden="true"></i>
                            </span>
                            <input
                              type="file"
                              name="partnerImage"
                              onChange={this.handleInputFileChange}
                            />
                          </div>
                          <div class="file-path-wrapper">
                            <input
                              class="file-path validate"
                              type="text"
                              placeholder=" Please upload partner image."
                            />
                          </div>
                        </div>
                        <span className="error" style={{color: "red"}}>
                          {this.state.errors["badge_error"]}
                        </span>
                        <br />
                        <span style={{color: "red"}}>Note: &nbsp; </span>
                        Width must be 225px and Height must be 143px.
                      </FormGroup>
                    </Col>
                  </Row>

                  <FormGroup>
                    <Label for="exampleSelect">Status</Label>

                    <Input
                      type="select"
                      name="status"
                      onChange={this.handleInputChange}
                      className="col-md-6"
                    >
                      <option value="Active">Active</option>
                      <option value="In-Active">In Active</option>
                    </Input>
                  </FormGroup>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md={12} className="form-btn">
              <Button
                className="btn btn-primary addbtn"
                onClick={this.handleSubmit}
                title="Add Partner"
              >
                Add
                <div
                  style={this.state.loading ? {} : {display: "none"}}
                  class="image-fill w-25 loader-login-div "
                >
                  <img src={Loading} alt="No Image" className="can-click " />
                </div>
              </Button>{" "}
              &nbsp;&nbsp;
              {/* <Button class="btn btn-primary" color="danger" onClick={ this.CancelSubmit } title="Cancel Certificate">Cancel</Button> */}
              <Link
                to={AppConstants.STAGADMIN + "/partners"}
                class="btn btn-primary redbtn"
                title="Cancel Partner"
              >
                Cancel
              </Link>
            </Col>
          </Row>
          {this.state.errMsg}
        </Form>
      </Page>
    );
  }
}

AddPartner.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

//export default ChangepasswordPage;
export default connect(mapStateToProps, {})(AddPartner);
