import Page from '../../../src/components/Page';
import React from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import { BootstrapTable, TableHeaderColumn, InsertButton } from 'react-bootstrap-table';
//import { AddCompany, DataFormat } from 'components/NewUsers';
import { Card, Button, CardBody, CardHeader, Col, Row, Table, Modal,
  ModalBody,
  ModalFooter,
  ModalHeader } from 'reactstrap';
import {
    MdCreate,
    MdDelete,
    MdRemoveRedEye,MdAdd
  } from 'react-icons/md';
import AppConstants from '../../AppConstants';
import {ReactTitle} from 'react-meta-tags';

class Rfprfiprofiles extends React.Component {
  constructor(props) {
    super(props);
    this.state = {      
      rfprfiprofilesdata: [],
      errors: {},
      errMsg:false
  };
  this.format = this.format.bind(this);
  this.activeformat = this.activeformat.bind(this);
}
DeleteRfprfi = params => e => {  
  e.preventDefault();
  if(window.confirm("Are you sure you want to delete this Rfp/Rfi?"))
  {
    console.log('the item to delete is: ', params);
    const rfprfideletedata = {
      id:params
    }
    axios.post(AppConstants.API+'/rfprfiprofiles/deletrfprfiprofiles',rfprfideletedata)
      .then(res => {   
        setTimeout(() => {
          this.setState({
            errMsg: false,
          });
        }, 1000);
       if(res.data.Status == "Success"){                           
        this.setState({
          errMsg: res.data.msg
        });
        fetch(AppConstants.API+'/rfprfiprofiles/getalladminrfprfiprofiles').then(response => response.json())
          .then(data => {
        //console.log(data);
          if(data.Status == "Success")
          {
            this.setState({ rfprfiprofilesdata: data.rfprfiprofilesdata });
          }
          else
          {
            this.setState({ rfprfiprofilesdata: [] });
          }    
        });
      }
      else{
        this.setState({
          errMsg: res.data.msg
        });
      }
    });
  }
};

format(cell, row){
  return <div className="ca-controls"><Link to={AppConstants.STAGADMIN+"/editrfprfi/"+row._id} class="btn btn-secondary editbtn" title="Edit Opportunity" style={{fontSize:"15px"}}>Edit</Link>
  {/* <span onClick={ this.DeleteRfprfi(row._id) } class="text-muted" title="Delete Rfp/Rfi"><MdDelete /></span><a href="#" className="text-muted"><MdRemoveRedEye /></a> */}
  <Link to={AppConstants.STAGADMIN+"/viewrfprfi/"+row._id} className="btn btn-success viewbtn" title="View Opportunity Details" style={{fontSize:"15px"}}>View</Link> 
  </div>;
}

activeformat(cell, row){
  if(row.status == 'In-Active'){
    return <div>In Active</div>;
  }
  else
  {
    return <div>Active</div>;
  }

}

  createCustomInsertButton = (onClick) => {
    return (
        <span> &nbsp; <Link to={AppConstants.STAGADMIN+"/addrfprfi"} class="btn btn-warning addbtn" title="Add Opportunity">Add</Link>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        {/* <Link to={AppConstants.STAGADMIN+"/importopportunities"} class="btn btn-secondary editbtn" title="Import Opportunities">Import Opportunities</Link> */}
        </span>
    );
  }
  componentDidMount() {
    fetch(AppConstants.API+'/rfprfiprofiles/getalladminrfprfiprofiles').then(response => response.json())
      .then(data => {
     //console.log(data);
      if(data.Status == "Success")
      {
        this.setState({ rfprfiprofilesdata: data.rfprfiprofilesdata });
      }
      else
      {
        this.setState({ rfprfiprofilesdata: []});
      }    
    });

    var element = document.getElementById("navItem-Companies-1");
    element.classList.remove("active");
    
    var element = document.getElementById("navItem-Certifications and Agencies-5");
    element.classList.remove("active");    
    var element = document.getElementById("navItem-NAICS/NIGP Codes-4");
    element.classList.remove("active");
    var element = document.getElementById("navItem-Opportunities-3");
    element.classList.add("active");
    var element = document.getElementById("navItem-Vendors-2");
    element.classList.remove("active");
    var element = document.getElementById("navItem-Company Types-0");
    element.classList.remove("active");
    var element = document.getElementById("navItem-Industry Types-1");
    element.classList.remove("active");
    var element = document.getElementById("navItem-Reports-6");
    element.classList.remove("active"); 
  }
   render() {
    if(localStorage.jwtTokenAdmin != undefined)
    {
    const options = {
      exportCSVText: 'Export CSV',    
      insertText: "New",    
      deleteText: 'Delete',    
      saveText: 'Save',    
      closeText: 'Close',
      insertBtn: this.createCustomInsertButton,
      page: 1,  // which page you want to show as default
      sizePerPageList: [ {
        text: '5', value: 5
      }, {
        text: '10', value: 10
      }, {
        text: '20', value: 20
      },
      {
        text: '50', value: 50
      }
    ], // you can change the dropdown list for size per page
      sizePerPage: 10,  // which size per page you want to locate as default
      pageStartIndex: 1, // where to start counting the pages
      paginationSize: 3,  // the pagination bar size.
     // prePage: 'Prev', // Previous page button text
      //nextPage: 'Next', // Next page button text
      //firstPage: 'First', // First page button text
      //lastPage: 'Last', // Last page button text
      paginationShowsTotal: this.state.rfprfiprofilesdata.length,  // Accept bool or function
      paginationPosition: 'bottom',  // default is bottom, top and both is all available
      // hideSizePerPage: true > You can hide the dropdown for sizePerPage
      // alwaysShowAllBtns: true // Always show next and previous button
      // withFirstAndLast: false > Hide the going to First and Last page button
      insertModal: this.createCustomModal    
    };
    return (
        <Page title="Opportunities">
        <ReactTitle title="Opportunities"/>
        <Link to={AppConstants.STAGADMIN+'/dashboard'}>
          Home
          </Link>
          &nbsp; | &nbsp;&nbsp;
          <Link to={AppConstants.STAGADMIN+'/rfprfiprofiles'}>
          Opportunities
          </Link>
            <row>
              <Card className="data-table">
                <CardBody>
                  <div>
                  <span className="error" style={{color: 'red'}}>{this.state.errMsg}</span>
                  <BootstrapTable data={ this.state.rfprfiprofilesdata } search={ true } pagination={ true } multiColumnSearch={ true } options={ options }
                  insertRow
                  >
                    <TableHeaderColumn dataField='_id' isKey={ true } searchable={ false } dataSort={ true } hidden={true}>ID</TableHeaderColumn>
                    <TableHeaderColumn dataField='company_name' dataSort={ true }>Company Name</TableHeaderColumn>
                    <TableHeaderColumn dataField='name_ofrfp' dataSort={ true }>Name Of Opportunity</TableHeaderColumn>
                    <TableHeaderColumn dataField='status'  dataFormat={this.activeformat.bind(this)} width={'15%'} dataSort={ true }>Status</TableHeaderColumn>
                    <TableHeaderColumn dataField='actions' width={'25%'} dataFormat={this.format.bind(this)} export={ false }>Actions</TableHeaderColumn>
                  </BootstrapTable>
                </div>
                </CardBody>
              </Card>
            </row>
          </Page>
      );
    }
    else 
    {
        window.location.href = AppConstants.STAGADMIN+'/'
    }
  }

};

export default Rfprfiprofiles;
