const AppConstants = {
    //Prod
    API: 'https://bizlinkbuyingdiverse.com/api',
    STAGADMIN: '/admin'

    //Stag
    //API: 'https://www.bidlinkmemphis.com/stagapi',
    //STAGADMIN: '/stagadmin',

    //Local
    //API: 'http://localhost:2222'
    //STAGADMIN: '/stagadmin',

   
}
export default AppConstants;
